
import { mapActions, mapGetters, mapState } from 'vuex'
import BaseBadge from '@/components/base/BaseBadge.vue'
import BaseTable from '@/components/base/BaseTable.vue'
import BasePagination from '@/components/base/BasePagination.vue'
import BaseDatepicker from '@/components/base/BaseDatepicker.vue'

export default {
  name: 'Archive',
  components: {
    BaseDatepicker,
    BasePagination,
    BaseTable,
    BaseBadge
  },
  created() {
    this.processed_at = [
      this.offersFilters.processed_at_after ? new Date(this.offersFilters.processed_at_after) : null,
      this.offersFilters.processed_at_before ? new Date(this.offersFilters.processed_at_before) : null
    ]
    this.due_date = [
      this.tradesFilters.due_date_after ? new Date(this.tradesFilters.due_date_after) : null,
      this.tradesFilters.due_date_before ? new Date(this.tradesFilters.due_date_before) : null
    ]
  },
  data() {
    return {
      requestColumns: [
        {
          name: 'amount',
          localization: 'borrower.request.amount'
        },
        {
          name: 'period_days_mod',
          localization: 'borrower.request.period_days'
        },
        {
          name: 'interest_rate',
          localization: 'borrower.request.percent_year'
        },
        {
          name: 'processed_at',
          localization: 'borrower.request.processed_at'
        },
        {
          name: 'total_depth',
          localization: 'borrower.request.outcome'
        },
        {
          name: 'status',
          localization: 'borrower.request.status'
        },
        { name: 'action' }
      ],
      tradeColumns: [
        {
          name: 'signed_at',
          localization: 'message.profile.borrower.activeOffers.items.issued'
        },
        {
          name: 'amount',
          localization: 'message.profile.borrower.activeOffers.items.amount'
        },
        {
          name: 'period_days',
          localization: 'message.profile.borrower.activeOffers.items.term'
        },
        {
          name: 'interest_rate',
          localization: 'message.profile.borrower.activeOffers.items.percent'
        },
        {
          name: 'initial_debt',
          localization: 'message.profile.borrower.activeOffers.items.income'
        },
        {
          name: 'due_date',
          localization: 'message.profile.borrower.activeOffers.items.issuedBy'
        },
        {
          name: 'total_penalty',
          localization: 'message.profile.borrower.activeOffers.items.peni'
        },
        {
          name: 'remaining_debt',
          localization: 'message.profile.borrower.activeOffers.items.totalAmount'
        },
        {
          name: 'status',
          localization: 'borrower.request.status'
        },
        { name: 'action' }
      ],
      processed_at: [],
      due_date: []
    }
  },
  computed: {
    ...mapState(['archiveOffer', 'archiveTrade']),
    ...mapGetters('archiveOffer', {
      offersList: 'offerList',
      offersFilters: 'filters'
    }),
    ...mapGetters('archiveTrade', {
      tradesList: 'tradeList',
      tradesFilters: 'filters'
    }),
    offerPagination() {
      return {
        next: this.archiveOffer.has_next,
        previous: this.archiveOffer.page > 1,
        count: this.archiveOffer.count,
        page_size: this.archiveOffer.page_size
      }
    },
    tradePagination() {
      return {
        next: this.archiveTrade.has_next,
        previous: this.archiveTrade.page > 1,
        count: this.archiveTrade.count,
        page_size: this.archiveTrade.page_size
      }
    }
  },
  methods: {
    ...mapActions('archiveOffer', {
      changeOffersOrder: 'changeOrder',
      applyOffersFilters: 'applyFilters'
    }),
    ...mapActions('archiveTrade', {
      changeTradesOrder: 'changeOrder',
      applyTradesFilters: 'applyFilters'
    }),
    ...mapActions(['archiveOffer/resetFilters', 'archiveTrade/resetFilters']),
    async setOffersFilters() {
      const [processed_at_after, processed_at_before] = this.processed_at
      await this.applyOffersFilters({
        processed_at_after,
        processed_at_before
      })
    },
    async setTradesFilters() {
      const [due_date_after, due_date_before] = this.due_date
      await this.applyTradesFilters({
        due_date_after,
        due_date_before
      })
    },
    async resetTradesFilters() {
      await this['archiveTrade/resetFilters']()
      this.due_date = []
    },
    async resetOffersFilters() {
      await this['archiveOffer/resetFilters']()
      this.processed_at = []
    },
    openTransactions({ id }) {
      this.$router.push({
        name: 'loan',
        params: { id }
      })
    },
    changeTradePage(page: number) {
      this.$store.dispatch('archiveTrade/setPage', page)
    },
    changeRequestPage(page: number) {
      this.$store.dispatch('archiveOffer/setPage', page)
    }
  }
}
