

import ProfileSettings from '@/services/ProfileSettings'
import { OtpAction } from '@/models'

const timeoutValue = 30

export default {
  component: 'OtpModal',
  props: {
    phone: {
      type: String,
      required: true
    },
    onSubmit: {
      type: Function,
      required: true
    },
    sendOtp: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      otp_code: '',
      codeRequested: false,
      interval: null,
      isSubmitting: false,
      timer: timeoutValue,
      error: null
    }
  },
  created() {
    if (!this.sendOtp || !this.onSubmit || !this.phone) {
      throw new Error('sendOtp, onSubmit and phone props is required for OtpModal component')
    }
    this.requestOtpCode()
  },
  methods: {
    async requestOtpCode(): Promise<void> {
      try {
        this.codeRequested = true
        this.timer = timeoutValue
        await this.sendOtp()
        this.initTimer()
      } catch (e) {
        this.timer = timeoutValue
        this.error = 'error.too_many_attempts_to_update_personal_info'
      }
    },
    initTimer() {
      this.interval = setInterval(() => {
        this.timer--
        if (this.timer <= 0) {
          clearInterval(this.interval)
          this.codeRequested = false
        }
      }, 1000)
    },
    async submit() {
      try {
        this.isSubmitting = true
        await this.onSubmit(this.otp_code)
        this.$store.dispatch('modal/closeModal')
      } catch (e) {
        this.$refs.otp.applyValidation(this.$t('Wrong code'))
      } finally {
        this.isSubmitting = false
      }
    }
  }
}
