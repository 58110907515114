
import BasePasswordInput from '../../components/base/BasePasswordInput.vue'
import BaseButton from '../../components/base/BaseButton.vue'
import BaseOtpInput from '../../components/base/BaseOtpInput.vue'
import { mapGetters } from 'vuex'
import ProfileSettings from '@/services/ProfileSettings'
import { ChangePasswordRequest, OtpAction } from '@/models'

export default {
  name: 'ChangePassword',
  components: {
    BasePasswordInput,
    BaseButton
  },
  data() {
    return {
      request: new ChangePasswordRequest(),
      isSaveBtnLoading: false
    }
  },
  methods: {
    async getOtpCode() {
      try {
        await ProfileSettings.requestOtp(this.profile.phone, OtpAction.CHANGE_PASSWORD)
        this.$toast.success(this.$t('message.signIn.otpToastMsg'))
      } catch (error) {
        this.$router.push({ name: 'settings' })
      }
    },
    async changePassword() {
      this.isSaveBtnLoading = true

      try {
        await ProfileSettings.changePassword(this.request)
        this.$toast(this.$t('message.changePasswordPage.toastMsg'))
        this.isSaveBtnLoading = false
        this.$router.push({ name: 'settings' })
      } catch (error) {
        this.isSaveBtnLoading = false
      }
    }
  },
  computed: {
    ...mapGetters(['profile']),
    isSaveBtnDisabled() {
      return this.isFieldsEmpty || this.isHasErrors
    },
    isFieldsEmpty() {
      return !this.request.old_password || !this.request.new_password || !this.request.confirm_password || !this.request.otp_code
    },
    isHasErrors() {
      return this.errors.items.length > 0
    }
  }
}
