import LoanService from '@/services/Loan'

const state = {
  loansList: [],
  loansCount: 0,
  isHasMoreLoans: true
}

const actions = {
  async getLoansList(context, params) {
    const response = await LoanService.getLoansList(params)

    context.commit('setLoansList', response?.data?.rows)
    context.commit('setLoansCount', response?.data?.count)

    if (response.data?.rows.length < 10) {
      context.commit('setHasMoreLoans', false)
    } else {
      context.commit('setHasMoreLoans', true)
    }
  }
}

const mutations = {
  setLoansList(state, data) {
    state.loansList = data
  },
  setLoansCount(state, data) {
    state.loansCount = data
  },
  setHasMoreLoans(state, data) {
    state.isHasMoreLoans = data
  }
}

const getters = {
  loansList(state) {
    return state.loansList
  },
  loansCount(state) {
    return state.loansCount
  },
  isHasMoreLoans(state) {
    return state.isHasMoreLoans
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
