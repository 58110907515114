
import { mapGetters } from 'vuex'
import { settings } from '@/services/Settings'
export default {
  name: 'Header',
  data() {
    return {
      routesList: settings.availableHeaderRotes,
      mobileMenuRoutesList: settings.sideMenuRoutes,
      isMobileMenuOpen: false
    }
  },
  created() {
    this.$emit('mobileMenuToggle', this.isMobileMenuOpen)
  },
  methods: {
    async signOut() {
      await this.$store.dispatch('purgeAuth')
    },
    mobileMenuToggle() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen

      this.$emit('mobileMenuToggle', this.isMobileMenuOpen)
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false

      this.$emit('mobileMenuToggle', this.isMobileMenuOpen)
    },
    async navigate(route) {
      await this.$router.push({ name: route })
      this.closeMobileMenu()
    }
  },
  computed: {
    ...mapGetters(['profile']),
    parsedUserName() {
      return this.profile.first_name?.slice(0, 1) + this.profile.last_name?.slice(0, 1)
    }
  }
}
