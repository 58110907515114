import Axios from 'axios'
import { settings, api } from '@/services/Settings'
import qs from 'qs'

const http = Axios.create({
  baseURL: `${api}${settings.apiVersion}`,
  headers: {
    Pragma: 'no-cache',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  paramsSerializer: p => qs.stringify(p, {
    arrayFormat: 'repeat',
    skipNulls: true,
    serializeDate: (d: Date) => d.toISOString().split('T')[0]
  })
})

export default http
