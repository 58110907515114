import { Roles } from '@/models/roles.enum'

export default {
  computed: {
    isJobDataIncorrect() {
      return this.userData.role !== Roles.LENDER ? (!this.work_info.employment_type || !this.work_info.employment_area ||
        !this.work_info.company_name || !this.work_info.experience || (this.isOtherJobPositionType ? !this.work_info.position : false))
        : (!this.work_info.employment_type || !this.work_info.employment_area ||
          !this.work_info.company_name || (this.isOtherJobPositionType ? !this.work_info.position : false))
    }
  }
}
