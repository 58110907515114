<template>
    <div class="input-wrapper">
        <div class="input-label">
            <div class="label-text" :class="{ required: required }">{{ label }}</div>
        </div>
        <div class="input-block" :class="{'invalid': errors.has(name), 'disabled': disabled}">
            <input class="input" type="text"
                v-model="inputValue"
                :maxlength="maxLength"
                :name="name"
                @input="$emit('input', inputValue)"
                @paste="$emit('input', inputValue)"
                v-validate="validateRules"
                :class="{'input-visible': isPasswordVisible, 'input-disabled': disabled}"
                ref="password"
                :disabled="disabled"
            >
            <img class="eye-img" :src="require(`../../../public/custom/images/eye-${eyePictureState}.svg`)"
                alt="Eye image"
                @click="passwordVisibleToggle()"
            >
        </div>
        <div class="error-block">{{ errorMessage }}</div>
    </div>
</template>

<script>
import VueI18n from 'vue-i18n'

export default {
  name: 'BasePasswordInput',
  props: {
    label: [String, VueI18n.TranslateResult],
    maxLength: Number,
    name: String,
    validateRules: [String, Object],
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: '',
      isPasswordVisible: false
    }
  },
  computed: {
    errorMessage() {
      return this.errors.first(this.name)
    },
    eyePictureState() {
      return this.isPasswordVisible ? 'on' : 'off'
    }
  },
  methods: {
    passwordVisibleToggle() {
      if (!this.disabled) {
        this.isPasswordVisible = !this.isPasswordVisible
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
    @include base-input-wrapper;
}

.input-label {
    @include base-input-label;
}

.input-block {
    @include base-input-block;
    display: flex;
    padding-right: 9px;
    align-items: center
}

.input {
    @include base-input;
    font-family: 'Bullet', sans-serif;
}

.input-visible {
    font-family: 'Open Sans', sans-serif;
}

.eye-img {
    height: 32px;
    width: 32px;
    cursor: pointer;
}

.invalid {
    border-color: var(--base-input-invalid-border-bgc);
}

.error-block {
    @include base-input-error-block;
}

.disabled {
    background-color: #FAFAFA;
}

.input-disabled {
    opacity: 0;
}
</style>
