export enum OtpAction {
  UPDATE_PERSONAL_INFO = 'update_personal_info',
  ADD_BANK_CARD = 'add_bank_card',
  UPDATE_ADDITIONAL_INFO = 'update_additional_info',
  SIGN_IN = 'sign_in',
  RESET_PASSWORD = 'reset_password',
  CONFIRM_CONSENT = 'confirm_consent',
  REVOKE_CONSENT = 'revoke_consent',
  TRADE_AGREEMENT_SIGN = 'trade_agreement_sign',
  CLAIM_SIGN = 'claim_sign',
  LEGAL_ACTION_SIGN = 'legal_action_sign',
  CHANGE_PASSWORD = 'change_password',
  VERIFY_MSI_INFO = 'verify_msi_info'
}
