import axios from '@/services/axios'
import { PagedResult } from '@/models'
import { DocumentCategory, DocumentTypeEnum, ServiceDocument } from '@/models/api/service-document'

export class ServiceDocuments {
  static getServiceDocuments(params: {
    category?: string
    doc_type?: DocumentTypeEnum
    page?: number,
    page_size?: number
  }): Promise<PagedResult<ServiceDocument>> {
    return axios.get('/service-document/', { params })
  }

  static getServiceDocumentById(id: number): Promise<ServiceDocument> {
    return axios.get(`/service-document/${id}/`)
  }

  static getCategories(): Promise<PagedResult<DocumentCategory>> {
    return axios.get('/service-document/category/')
  }

  static downloadServiceDocument(id: number): Promise<Blob> {
    return axios.get(`service-document/${id}/download/`, { responseType: 'blob' })
  }
}
