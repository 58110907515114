import axios from './axios'
import { Card, PagedResult } from '@/models'
import { PaymentProvider, PaymentProviderEnum } from '@/models/paymentProvider'

export default class CardsService {
  static addNewCard(otp: string): Promise<string | { provider: string, token: string }> {
    return axios.post('/bank-card/', { otp })
  }

  static getCardsList(): Promise<PagedResult<Card>> {
    return axios.get('/bank-card/')
  }

  static setDefaultCard(card_id) {
    return axios.put(`/bank-card/${card_id}/set-default/`)
  }

  static deleteCard(card_id) {
    return axios.delete(`/bank-card/${card_id}/`)
  }

  static refillBalance(card_id) {
    return axios.put(`/bank-card/${card_id}/refill-balance/`)
  }

  static getPaymentProvider(): Promise<Exclude<PaymentProvider, PaymentProviderEnum.BEPAID_AVAILABLE>> {
    return axios.get('/bank-card/payment-provider/')
  }
}
