
import BaseButton from '../../components/base/BaseButton.vue'
import LoanService from '@/services/Loan'
import { LoanRequest } from '@/models'
import { Roles } from '@/models/roles.enum'
import BaseModal from '@/components/base/BaseModal.vue'

export default {
  name: 'offerItem',
  props: {
    item: LoanRequest,
    profile: Object,
    buttonText: String,
    hideButton: Boolean,
    disableButton: Boolean
  },
  components: {
    BaseModal,
    BaseButton
  },
  data() {
    return {
      isOfferBtnLoading: false,
      acceptModal: false,
      handleConfirm: null,
      balanceError: false,
      balanceErrorText: ''
    }
  },
  methods: {
    handleOfferConfirm(item) {
      this.handleConfirm = this.acceptOfferConfirm(item)
    },
    acceptOfferConfirm(item) {
      return function() {
        this.getOffer(item)
        this.handleConfirm = null
      }
    },
    getOffer(item) {
      if (!this.isOfferBtnLoading) {
        this.profile?.role === Roles.LENDER
          ? this.acceptBorrowRequest(item)
          : this.goToContract(item)
      }
    },
    goToProfile() {
      this.$router.push({ name: 'profile' })
    },
    goToContract(item) {
      if (this.isAuthorized) {
        this.profile?.has_bank_card
          ? this.viewContact(item)
          : this.$router.push({ name: 'cards' })
      } else {
        this.$router.push({ name: 'SignIn' })
      }
    },
    goToCardsPage() {
      this.$router.push('cards')
    },
    accept() {
      this.acceptModal = false
      this.goToProfile()
    },
    async acceptBorrowRequest(item) {
      try {
        await LoanService.acceptBorrowLoan(item)
        this.acceptModal = true
      } catch (error) {
        if (error === 'insufficient_funds_on_the_card' || error === 'insufficient_funds_on_the_card_while_accepting') {
          this.balanceError = true
          this.balanceErrorText = this.$t(`error.${error}`)
        }
      }
    },
    async viewContact(request: LoanRequest) {
      this.isOfferBtnLoading = true
      this.$store.dispatch('loading', true)
      try {
        const trade = await LoanService.acceptLendLoan(request)

        this.$router.push({
          name: 'contract',
          query: { trade_id: trade.trade_id }
        })
        this.isOfferBtnLoading = false
        this.$store.dispatch('loading', false)
      } catch (error) {
        this.isOfferBtnLoading = false
        this.$store.dispatch('loading', false)
      }
    }
  },
  computed: {
    isAuthorized() {
      return !!this.profile?.role
    },
    expectedOutcome(): string {
      const {
        amount,
        percent_amount
      } = this.item
      return (+amount + percent_amount).toFixed(2)
    },
    isDisabled() {
      if (!this.profile.user) {
        return false
      }
      return this.disableButton
    }
  }
}
