import OffersService from '@/services/Offers'
import { LoanRequest } from '@/models'

const state = {
  offersList: [],
  offersCount: 0,
  hasMore: null,
  isHasMoreOffers: true,
  page: 1
}

const actions = {
  async getLenderOffersList1(context) {
    try {
      const list = await OffersService.getLenderOffersList1()
      context.commit('setOffersList', list?.data?.rows || [])
      if (list.data?.rows.length < 10) {
        context.commit('setHasMoreOffers', false)
      } else {
        context.commit('setHasMoreOffers', true)
      }
    } catch (error) {
      console.log(error)
    }
  },
  async getBorrowOffersList(context) {
    try {
      const list = await OffersService.getBorrowOffersList()
      context.commit('setOffersList', list?.data?.rows || [])
      if (list.data?.rows.length < 10) {
        context.commit('setHasMoreOffers', false)
      } else {
        context.commit('setHasMoreOffers', true)
      }
    } catch (error) {
      console.log(error)
    }
  },
  async updateOffersList(context, params) {
    const list = await OffersService.updateOffersList(params)
    context.commit('updateOffersList', list.data?.rows)

    if (list.data?.rows.length < 10) {
      context.commit('setHasMoreOffers', false)
    } else {
      context.commit('setHasMoreOffers', true)
    }
  },

  async changeBorrowOfferStatus(context, data) {
    await OffersService.changeBorrowRequestStatus(data.request, data.status)
  },
  async getLenderOffersList(context, params) {
    context.commit('setPage', params.page)
    const { results, pagination: { count, next } } = await OffersService.getLenderOffersList(params)
    context.commit('setOffersList', results)
    context.commit('setOffersCount', count)
    context.commit('setHasMoreOffers', next)
  }
}

const mutations = {
  setOffersList(state, data: LoanRequest) {
    state.offersList = data
  },
  setOffersCount(state, data: number) {
    state.offersCount = data
  },
  setHasMoreOffers(state, data) {
    state.isHasMoreOffers = data
  },
  updateOffersList(state, data) {
    state.offersList = data
  },
  setPage(state, data) {
    state.page = data
  }
}

const getters = {
  offersList(state) {
    return state.offersList
  },
  page(state) {
    return state.page
  },
  isHasMoreOffers(state) {
    return state.isHasMoreOffers
  },
  isHasMoreLenderOffers(state) {
    return state.hasMore
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
