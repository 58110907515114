export async function applyFilters<T extends { filters: any }>(context: any, filters: T['filters']) {
  context.commit('setFilters', filters)
  context.commit('setPage', 1)
  await context.dispatch('get')
}

export async function resetFilters(context: any) {
  context.commit('resetFilters')
  context.commit('setPage', 1)
  await context.dispatch('get')
}
