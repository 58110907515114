
import ExtendableTable from '@/components/base/ExtendableTable.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { DocumentCategory, DocumentTypeEnum, ServiceDocument } from '@/models/api/service-document'
import { ServiceDocuments } from '@/services/ServiceDocuments'
import DownloadDocument from '@/views/ServiceDocuments/components/DownloadDocument.vue'

export default {
  name: 'ArchiveDocuments',
  components: {
    DownloadDocument,
    ExtendableTable
  },
  data() {
    return {
      columns: [
        {
          name: 'category',
          localization: 'Наименование категории'
        }
      ],
      documentColumns: [
        {
          name: 'category',
          localization: 'Наименование документа'
        },
        {
          name: 'relevance_from',
          localization: 'Дата окончания действия документа'
        }
      ],
      expandedRows: [],
      filesData: {},
      DocumentTypeEnum
    }
  },
  async created() {
    this.setDocType(DocumentTypeEnum.ARCHIVE)
    try {
      await this.getCategories()
    } catch (e) {
    }
  },
  methods: {
    ...mapActions('documents', ['getCategories']),
    ...mapMutations('documents', ['setDocType']),
    async onRowClick(item: DocumentCategory) {
      if (this.expandedRows.includes(item.code_name)) {
        this.expandedRows = this.expandedRows.filter(id => id !== item.code_name)
      } else {
        if (item.code_name in this.filesData) {
          this.expandedRows.push(item.code_name)
        } else {
          const { results } = await ServiceDocuments.getServiceDocuments({
            category: item.code_name,
            doc_type: DocumentTypeEnum.ARCHIVE
          })
          this.filesData[item.code_name] = results
          this.expandedRows.push(item.code_name)
        }
      }
    },
    isActive(id) {
      return this.expandedRows.includes(id)
    },
    async getDocumentByCategory(document: ServiceDocument) {
      try {
        const newDocumentsForCategory = (await ServiceDocuments.getServiceDocuments({
          category: document.category
        })).results
        this.filesData = {
          ...this.filesData,
          [document.category]: newDocumentsForCategory
        }
      } catch {

      }
    }
  },
  computed: {
    ...mapGetters('documents', ['categories', 'isCategoriesLoading'])
  }
}
