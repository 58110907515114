
import ProfileSettings from '@/services/ProfileSettings'
import { OtpAction, UserConsent } from '@/models'
import { mapGetters } from 'vuex'
import AgreementService from '@/services/Agreement'
import { Utils } from '@/helpers/utils'

interface Data {
  consent: UserConsent,
  otp_code: string,
  code_requested: boolean
}

export default {
  name: 'ConsentManagement',
  data(): Data {
    return {
      consent: new UserConsent(),
      otp_code: null,
      code_requested: false
    }
  },
  created() {
    this.getConsent()
  },
  computed: {
    ...mapGetters(['profile'])
  },
  methods: {
    async getConsent(): Promise<void> {
      this.consent = await ProfileSettings.getPersonalDataConsent()
    },
    async requestOtpCode(): Promise<void> {
      await ProfileSettings.requestOtp(this.profile.phone, OtpAction.CONFIRM_CONSENT)
      this.$toast.success(this.$t('message.signIn.otpToastMsg'))
      this.code_requested = true
    },
    async handleConsent(): Promise<void> {
      const request = this.consent.revoked
        ? ProfileSettings.acceptPersonalDataConsent(this.otp_code)
        : !this.consent.revoked && !this.consent.accepted ? ProfileSettings.acceptPersonalDataConsent(this.otp_code) : ProfileSettings.revokePersonalDataConsent(this.otp_code)
      try {
        await request
        this.code_requested = false
        this.otp_code = null
        await this.getConsent()
      } catch (e) {
        this.code_requested = false
        this.otp_code = null
      }
    },
    async loadConsent() {
      const blob = await AgreementService.loadConsent(this.consent.id)
      Utils.downloadBlob(blob, 'personal-data-consent.pdf')
    }
  }
}
