import { AppSettings, EnvMode } from '@/models'
import { environment } from '@/environments/env'
import { environmentProd } from '@/environments/env.prod'
import { environmentStaging } from '@/environments/env.staging'
import { environmentDev } from '@/environments/env.dev'

let settings: AppSettings = environment

const api = settings.api

function initSettingsMode(mode: EnvMode | null): void {
  if (!mode) {
    return
  }
  if (mode === EnvMode.PROD) {
    settings = environmentProd
    return
  }
  if (mode === EnvMode.STAGING) {
    settings = environmentStaging
    return
  }
  if (mode === EnvMode.DEVELOP) {
    settings = environmentDev
  }
}

export {
  settings,
  api,
  initSettingsMode
}
