import { ClaimStatus } from './claim-status.enum'
import { ClaimLoanerStatus } from '@/models/claim-loaner-status.enum'

export class Claim {
  created_at: string
  due_interest_depth: string
  due_main_depth: string
  from_user_id: number
  id: number
  penalty_amount: string
  status: ClaimStatus
  status_court: ClaimLoanerStatus
  status_loaner: ClaimLoanerStatus
  to_user_id: number
  trade_id: ClaimLoanerStatus
}
