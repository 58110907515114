
import BaseTextareaInput from '../components/base/BaseTextareaInput.vue'
import BaseButton from '../components/base/BaseButton.vue'
import DocsUploader from '../components/common/DocsUploader.vue'
import { MessagesService } from '@/services/Messages'
import TradeService from '@/services/Trade'
import BaseBadge from '@/components/base/BaseBadge.vue'
import { mapGetters } from 'vuex'
import { Roles } from '@/models/roles.enum'
import { Utils } from '@/helpers/utils'
import BaseSelectNew from '@/components/base/BaseSelectNew.vue'

const messageMaxLength = 300

export default {
  name: 'Messages',
  components: {
    BaseSelectNew,
    BaseBadge,
    BaseTextareaInput,
    BaseButton,
    DocsUploader
  },
  data() {
    return {
      message: '',
      file: undefined,
      cleanFile: false,
      categories: [],
      category: null,
      agreement_id: '',
      trade: null,
      messageMaxLength
    }
  },
  async created() {
    try {
      this.categories = await MessagesService.getCategory()
    } catch (e) {}
  },
  methods: {
    changeTrade(): void {
      this.agreement_id = null
      this.trade = null
    },
    addFile(file) {
      this.file = file
    },
    async sendMessage() {
      const payload = new FormData()

      payload.append('message', this.message)
      payload.append('category', this.category)

      if (this.trade) {
        payload.append('agreement_id', this.trade.agreement_id)
      }

      if (this.file) {
        payload.append('files', this.file)
      }

      try {
        await MessagesService.sendMessage(payload)
        this.$toast.success(this.$t('message.messagesPage.tostMsg'))
        this.message = ''
        this.changeTrade()
        this.category = undefined
        this.file = undefined
        this.cleanFile = !this.cleanFile
      } catch (error) {
        console.log(error)
      }
    },
    async getTrade(): Promise<void> {
      try {
        this.trade = await TradeService.getTradeByAgreement(this.agreement_id)
      } catch (e) {
        this.$refs.tradeInput.applyValidation('Не найден займ с указанным номером договора')
      }
    }
  },
  computed: {
    Utils() {
      return Utils
    },
    Roles() {
      return Roles
    },
    ...mapGetters(['profile']),
    isHasEmptyFields() {
      return !this.message || !this.category
    },
    isHasErrors() {
      return this.errors.items.length > 0
    },
    isBtnDisabled() {
      return this.isHasEmptyFields || this.isHasErrors || (this.isTradeRequired && !this.trade)
    },
    isTradeRequired() {
      return this.categories.find(category => category.code_name === this.category)?.agreement_required
    }
  },
  watch: {
    agreement_id() {
      if (this.agreement_id?.length === 6 && !isNaN(Number(this.agreement_id))) {
        this.getTrade()
      }
    },
    category() {
      if (!this.isTradeRequired) {
        this.trade = null
        this.agreement_id = ''
      }
    }
  }
}
