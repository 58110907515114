import { PagedState } from '@/models/paged-state.inteface'
import { LoanRequest, LoanRequestStatus } from '@/models'
import LoanService from '@/services/Loan'

type HomeBorrewerState = PagedState<{
  requests: LoanRequest[];
  filters: { [key: string]: unknown };
}>;

const state: HomeBorrewerState = {
  filters: {},
  requests: null,
  page: 1,
  page_size: 10,
  has_next: false
}

const actions = {
  async get(context) {
    const { page, page_size, filters } = context.state
    const data = await LoanService.getPublicBorrowRequest({
      page,
      page_size,
      status: LoanRequestStatus.ACTIVE,
      ...filters
    })
    context.commit(
      'setTrades',
      page > 1 ? [...context.state.requests, ...data.results] : data.results
    )
    context.commit('setHasNext', Boolean(data.pagination.next))
  },
  nextPage(context) {
    const { page } = context.state
    context.dispatch('setPage', page + 1)
  },
  setPage(context, page) {
    context.commit('setPage', page)
    context.dispatch('get')
  },
  resetPage(context) {
    context.commit('setPage', 1)
    context.dispatch('get')
  },
  setFilters(context, filters) {
    context.commit('setFilters', filters)
    context.commit('setPage', 1)
    context.dispatch('get')
  }
}

const mutations = {
  setTrades(state: HomeBorrewerState, payload: LoanRequest[]) {
    state.requests = payload
  },
  setHasNext(state: HomeBorrewerState, payload: boolean) {
    state.has_next = payload
  },
  setPage(state: HomeBorrewerState, page) {
    state.page = page
  },
  setFilters(state: HomeBorrewerState, filters: { [key: string]: unknown }) {
    state.filters = filters
  }
}

const getters = {
  hasResultsWithFiltersBorrewer(state: HomeBorrewerState) {
    return (
      state.requests.length === 0 &&
      Object.values(state.filters).some((item) => !!item)
    )
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
