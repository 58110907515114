
import { ValidationObserver } from 'vee-validate'
import BaseTextInput from '@/components/base/BaseTextInput.vue'
import { Utils } from '@/helpers/utils'
import { CalculatorService } from '@/services/Calculator.service'
import { mapGetters } from 'vuex'

export default {
  name: 'Calculator',
  components: {
    ValidationObserver,
    BaseTextInput
  },
  data() {
    return {
      outcome: '???',
      request: {
        amount: null,
        interest_rate: null,
        period_days: null
      },
      isCalculating: false
    }
  },
  watch: {
    request: {
      handler: async function() {
        const {
          amount,
          interest_rate,
          period_days
        } = this.request
        this.outcome = '???'
        this.debounceCalculateResult(amount, interest_rate, period_days)
      },
      deep: true
    }
  },
  methods: {
    debounceCalculateResult: Utils.debounce(async function(amount, interest_rate, period_days) {
      const isValid = await this.$refs.observer.validate()
      const isFieldsFulfilled = this.request.amount && this.request.interest_rate && this.request.period_days
      if (isValid && isFieldsFulfilled) {
        this.isCalculating = true
        const result = await CalculatorService.calculate({
          amount,
          period_days,
          interest_rate
        })
        this.isCalculating = false
        this.outcome = result.total_amount
      }
    }, 500)
  },
  computed: {
    ...mapGetters(['validationRules']),
    requestAmountRules() {
      return 'isBetween:' + this.validationRules.MIN_LOAN_REQUEST_AMOUNT + ',' + this.validationRules.MAX_LOAN_REQUEST_AMOUNT + ',' + 'BYN'
    },
    requestPeriodRules() {
      return 'isBetween:' + this.validationRules.MIN_LOAN_REQUEST_PERIOD_DAYS + ',' + this.validationRules.MAX_LOAN_REQUEST_PERIOD_DAYS + ',' + 'дней'
    },
    interestRateRules() {
      return 'isBetween:' + this.validationRules.MIN_LOAN_REQUEST_INTEREST_RATE + ',' + this.validationRules.MAX_LOAN_REQUEST_INTEREST_RATE + ',' + '%'
    },
    requestAmountPlaceholder() {
      const amountFrom = this.validationRules.MIN_LOAN_REQUEST_AMOUNT
      const amountTo = this.validationRules.MAX_LOAN_REQUEST_AMOUNT
      return `${this.$t('message.profile.lender.offer.amount.placeholderStart')} ${amountFrom} ${this.$t('message.profile.lender.offer.amount.placeholderEnd')} ${amountTo}`
    },
    requestPeriodPlaceholder() {
      return `${this.$t('message.profile.lender.offer.term.placeholderStart')} ${this.validationRules.MIN_LOAN_REQUEST_PERIOD_DAYS} ${this.$t('message.profile.lender.offer.term.placeholderEnd')} ${this.validationRules.MAX_LOAN_REQUEST_PERIOD_DAYS} ${this.$t('message.profile.lender.offer.term.days')}`
    },
    requestPercentPlaceholder() {
      const percentFrom = this.validationRules.MIN_LOAN_REQUEST_INTEREST_RATE
      const percentTo = this.validationRules.MAX_LOAN_REQUEST_INTEREST_RATE
      return `${this.$t('message.profile.lender.offer.percent.placeholderStart')} ${percentFrom} ${this.$t('message.profile.lender.offer.percent.placeholderEnd')} ${percentTo}`
    }
  }
}
