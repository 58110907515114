<template>
    <div class="error-wrapper" @click="purgeError()">
        <div class="error-modal">
            <img class="error-image" src="../../../public/custom/images/error-info-circle.svg" alt="Error image">
            <h1 class="error-title">{{ $t('message.errorModal.title') }}</h1>
            <h2 class="error-message">{{ errorMessage }}</h2>
            <div class="btn-block">
                <base-button
                    :text="$t('message.errorModal.btn')"
                    @click="purgeError()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from './BaseButton.vue'
export default {
  components: { BaseButton },
  name: 'BaseErrorModal',
  props: {
    errorMessage: {
      type: String,
      default() {
        return ''
      }
    }
  },
  methods: {
    purgeError() {
      this.$store.dispatch('purgeError')
    }
  }
}
</script>

<style lang="scss" scoped>
    .error-wrapper {
        position: fixed;
        display: flex;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        background-color: transparent;
        z-index: 999999;
        align-items: center;
        justify-content: center;
    }

    .error-modal {
        padding: 20px;
        width: 340px;
        max-width: 95%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 8px;
        background-color: var(--error-modal-bgc);
        -webkit-box-shadow: var(--error-modal-box-shadow);
        -moz-box-shadow: var(--error-modal-box-shadow);
        box-shadow: var(--error-modal-box-shadow);
    }

    .error-image {
        margin-bottom: 5px;
        width: 72px;
    }

    .error-title {
        margin: 0 0 15px 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: var(--error-modal-title-color);
    }

    .error-message {
        margin: 0 0 15px 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 135%;
        color: var(--error-modal-message-color);
        text-align: center;
    }

    .btn-block {
        width: 110px;
        height: 36px;
    }
</style>
