<template>
  <div class="modal-wrapper" @click.stop="$emit('close')">
    <div class="modal" :style="styles" @click.stop>
      <slot name="header"/>
      <slot name="main"/>
      <slot name="footer"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModal',
  props: {
    styles: Object,
    default: {}
  }
}
</script>

<style lang="scss" scoped>
  .modal-wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 99999;
    background: rgba(10, 10, 20, 0.8);
  }

  .modal {
    max-width: 95%;
    max-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    background: #FFFFFF;
    border-radius: 20px;
    overflow: auto;
  }
</style>
