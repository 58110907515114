<template>
  <div class="profile-wrapper">
    <div class="spinner-container" v-if="isLoading">
      <base-spinner :is-loading="true" :is-local="true" class="spinner" />
    </div>
    <Lender
        v-if="!isLoading && profile.role === 'lender'"
        @createOffer="getLenderOffersList()"
    />
    <Borrower
        v-if="!isLoading && profile.role === 'borrower'"
        @successPayment="updateLoansList()"
    />

    <div class="announcement-modal" v-if="currentAnnouncement">
      <base-modal
      >
        <template #header>
          <div class="modal-header">
            <img class="attention-icon" src="../../public/custom/images/attention-circle.svg" alt="Attention">
          </div>
        </template>
        <template #main>
          <div class="modal-content" v-html="currentAnnouncement.body">
          </div>
        </template>
        <template #footer>
          <div class="flex flex-col justify-center items-end grow gap-2">
            <base-button
                :style="{height: '48px', fontSize: '16px'}"
                :text="'Ознакомлен(а)'"
                :is-loading="isAnnouncementViewing"
                @click="viewAnnouncement"
            />
            <base-button
                :color="'secondary'"
                :style="{height: '48px', fontSize: '16px'}"
                :text="'Ознакомиться позже'"
                @click="viewAnnouncementLater"
            />
          </div>
        </template>
      </base-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BaseSpinner from '../components/base/BaseSpinner.vue'
import Lender from './Profile/Lender.vue'
import Borrower from './Profile/Borrower.vue'
import { Roles } from '@/models/roles.enum'
import BaseModal from '@/components/base/BaseModal.vue'
import AnnouncementService from '@/services/Announcement'
import { StorageKey } from '@/models'

export default {
  name: 'Profile',
  components: {
    BaseModal,
    BaseSpinner,
    Lender,
    Borrower
  },
  async created() {
    await this.$store.dispatch('updateProfile')

    const isAnnouncementShowRecently = localStorage.getItem(StorageKey.IS_ANNOUNCEMENT_SHOWED)
    if (!Number(isAnnouncementShowRecently)) {
      try {
        this.announcements = await AnnouncementService.get()
        if (this.announcements.length > 0) {
          this.currentAnnouncementIndex = 0
          this.currentAnnouncement = this.announcements[this.currentAnnouncementIndex]
        }
      } catch (e) {
      }
      localStorage.setItem(StorageKey.IS_ANNOUNCEMENT_SHOWED, '1')
    }

    if (this.profile.role === Roles.LENDER) {
      await this.$store.dispatch('lenderTrade/get')
      await this.$store.dispatch('lenderOffer/get')
    }

    if (this.profile.role === Roles.BORROWER) {
      await this.$store.dispatch('borrowTrade/get')
      await this.$store.dispatch('borrowOffer/get')
    }

    this.isLoading = false
  },
  data() {
    return {
      isLoading: true,
      params: {
        page: 1
      },
      currentAnnouncement: null,
      currentAnnouncementIndex: null,
      isAnnouncementViewing: false,
      announcements: null
    }
  },
  methods: {
    async getLenderOffersList() {
      this.isLoading = true
      this.$store.dispatch('lenderOffer/setPage', 1)
      this.isLoading = false
    },
    async updateLoansList() {
      this.isLoading = true
      this.$store.dispatch('borrowTrade/setPage', 1)
      await this.$store.dispatch('borrowTrade/get')
      this.isLoading = false
    },
    async viewAnnouncement() {
      try {
        this.isAnnouncementViewing = true
        await AnnouncementService.viewAnnouncement(this.currentAnnouncement.id)
        this.isAnnouncementViewing = false
        this.currentAnnouncement = this.announcements[++this.currentAnnouncementIndex]
      } finally {
        this.isAnnouncementViewing = false
      }
    },
    viewAnnouncementLater() {
      this.currentAnnouncement = this.announcements[++this.currentAnnouncementIndex]
    }
  },
  computed: {
    ...mapGetters(['profile']),
    ...mapState(['lenderTrade']),
    ...mapState(['lenderOffer']),
    ...mapState(['borrowOffer']),
    ...mapState(['borrowTrade'])
  }
}
</script>

<style lang="scss" scoped>
.profile-wrapper {
  @include page-wrapper-with-header-and-footer;

  padding-top: 20px;
  box-sizing: border-box;
  background-color: var(--profile-page-bgc);
}

.profile-container {
  @include base-page-container;

  color: #121212;
}

.spinner-container {
  @include base-page-container;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  color: var(--profile-text-color);
}

.company-info {
  display: flex;
}

.company-name {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #000000;
}

.rate-block {
  margin-left: 25px;
  display: flex;
  align-items: center;

  span {
    margin-top: 3px;
    margin-left: 4px;
    margin-right: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: var(--profile-rate-text-color);
  }
}

.rate {
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  color: #41BF7B;
}

.spinner ::v-deep {
  width: 100%;
}

.announcement-modal {
  .modal-header {
    //@include modal-close-block;
    position: relative;
    padding-top: 0;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;

    .attention-icon {
      width: 96px;
      height: 96px;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    margin-bottom: 44px;
    max-width: 440px;

    .title, :deep(h2) {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 0;
    }

    :deep(a) {
      color: #1A8D48;
      text-decoration: underline;
      font-weight: 600;
    }

    .description, :deep(p) {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      white-space: pre-line;

      .link {
        font-size: 0;
        font-weight: 600;
        color: #1A8D48;
        text-decoration: underline;

        & > span {
          font-size: 14px;
        }
      }

      .bold {
        font-weight: bold;
      }
    }
  }
}
</style>
