import axios from './axios'
import { LoanRequest, PagedResult } from '@/models'

export default class LoanService {
  static createLoan(offer_public_id) {
    return axios.post('/loan/create', { offer_public_id })
  }

  static acceptLendLoan(item: LoanRequest): Promise<LoanRequest> {
    return axios.put(`/loans/lend_request/${item.id}/accept/`, item)
  }

  static acceptBorrowLoan(item: LoanRequest): Promise<LoanRequest> {
    return axios.put(`/loans/borrow_request/${item.id}/accept/`, item)
  }

  static getLoanData(id: number): Promise<Blob> {
    return axios.get(`/trade/${id}/agreement-document/`, { responseType: 'blob' })
  }

  static signAgreement(offer_public_id, agreement_id, otp) {
    return axios.post('/agreement/loan/sign', { offer_public_id, agreement_id, otp })
  }

  static getOtpCode() {
    return axios.post('/notification/sms/otp/')
  }

  static getLoansList(params) {
    return axios.post('/loan/list', params)
  }

  static getLoanById(id) {
    return axios.get(`/loan/${id}`)
  }

  static loanPayment(payload) {
    return axios.post('/loan/pay', payload)
  }

  static createBorrowRequest(body: Pick<LoanRequest, 'amount' | 'interest_rate' | 'period_days'>): Promise<LoanRequest> {
    return axios.post('/loans/borrow_request/', body)
  }

  static getBorrowRequest(params: Record<string, string | number | string[]>): Promise<PagedResult<LoanRequest>> {
    return axios.get('/loans/borrow_request/', { params })
  }

  static getLenderRequest(params: Record<string, string | number | string[]>): Promise<PagedResult<LoanRequest>> {
    return axios.get('/loans/lend_request/', { params })
  }

  static getPublicBorrowRequest(params: Record<string, string | number>): Promise<PagedResult<LoanRequest>> {
    return axios.get('/public/loans/borrow_request/', { params })
  }

  static getPublicLenderRequest(params: Record<string, string | number>): Promise<PagedResult<LoanRequest>> {
    return axios.get('/public/loans/lend_request/', { params })
  }

  static pinLenderLoan(id: number): Promise<void> {
    return axios.put(`/loans/lend_request/${id}/pin-to-top/`, {})
  }

  static unpinLenderLoan(id: number): Promise<void> {
    return axios.put(`/loans/lend_request/${id}/unpin-from-top/`, {})
  }
}
