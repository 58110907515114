export interface AppSettings {
  api: string;
  apiVersion: string;
  apiVersionV2: string;
  refreshTokenTime: number;
  msiUrl: string;
  msiUpdateUrl: string;
  bePaidUrl: string;
  availableHeaderRotes: string[], // ['Profile', 'Bid', 'Settings', 'Statistics', 'Archive', 'Notifications', 'Messages']
  defaultLanguage: string,
  sideMenuRoutes: string[], // Доступные роуты в боковом меню. Возможные значения:['profile', 'main', 'cards', 'settings', 'archive']
  offer: {
    minAmount: number; // Минимальная сумма займа в рублях
    maxAmount: number; // Максимальная сумма займа в рублях
    minTerm: number; // Минимальный срок займа в днях
    maxTerm: number; // Максимальный срок займа в днях
    minPercent: number; // Минимальный процент по займу
    maxPercent: number; // Максимальный процент по займу
  },
  longOffer: {
    minAmount: number; // Минимальная сумма займа в рублях
    maxAmount: number; // Максимальная сумма займа в рублях
    minTermMonths: number; // Минимальный срок займа в месяцах
    maxTermMonths: number; // Максимальный срок займа в месяцах
    minPercent: number; // Минимальный процент по займу
    maxPercent: number; // Максимальный процент по займу
  }
  bid: {
    availableCurrencies: string[], // Доступные валюты при создании заявки. Возможные значения: ['BYN', 'USD']
    availablePaymentProcedures: string[], // Доступные периоды выплаты при создании заявки. Возможные значения: ['monthly', 'quarterly']
    availableProvisionList: string[], // Доступные гарантии при создании заявки. Возможные значения: ['bank_guarantee']
  }
  local?: boolean
}

export enum EnvMode {
  PROD = 'production',
  STAGING = 'staging',
  DEVELOP = 'development'
}
