<template>
  <div class="about-page-wrapper">
    <static-pages-header @mobileMenuToggle="mobileMenuToggle" />
    <div
      class="about-title-container"
      :class="{ 'heading-container-mobile-menu-open': isMobileMenuOpen }"
    >
      <div class="title">
        {{ $t("message.aboutPage.title") }}
      </div>
    </div>
    <div class="about-container">
      <div class="desc-block">
        <div class="block-title">
          {{ $t("message.aboutPage.desc.title") }}
        </div>
        <div class="desc-data">
          <div class="desc-data-item">
            {{ $t("message.aboutPage.desc.items.first") }}
          </div>
          <div class="desc-data-item">
            {{ $t("message.aboutPage.desc.items.second.begin") }}
            <span class="about-important">{{
              $t("message.aboutPage.desc.items.second.important")
            }}</span>
            {{ $t("message.aboutPage.desc.items.second.end") }}
          </div>
          <div class="desc-data-item">
            {{ $t("message.aboutPage.desc.items.third.begin") }}
            <b>{{ $t("message.aboutPage.desc.items.third.bold") }}</b>
            {{ $t("message.aboutPage.desc.items.third.middle") }}
            <document-link category="service_rules" class="about-link">
              {{ $t("message.aboutPage.desc.items.third.link") }}
            </document-link>
            {{ $t("message.aboutPage.desc.items.third.end") }}
          </div>
          <div class="desc-data-item">
            {{ $t("message.aboutPage.desc.items.fourth") }}
          </div>
          <div class="desc-data-item">
            {{ $t("message.aboutPage.desc.items.fifth") }}
          </div>
          <div class="desc-data-item">
            {{ $t("message.aboutPage.desc.items.sixth") }}
          </div>
        </div>
      </div>
      <div class="value-block">
        <div class="block-title">
          {{ $t("message.aboutPage.values.title") }}
        </div>
        <div class="desc-data">
          <div class="desc-data-item">
            {{ $t("message.aboutPage.values.data") }}
          </div>
        </div>
      </div>
      <div class="difference-block">
        <div class="block-title">
          {{ $t("message.aboutPage.different.title") }}
        </div>
        <div class="desc-data">
          <div
            class="difference-item"
            v-for="item of $t('message.aboutPage.different.items')"
            :key="item.title"
          >
            <div class="difference-item-title">{{ item.title }}</div>
            <div class="difference-item-data">{{ item.data }}</div>
          </div>
        </div>
      </div>
      <div class="contact-info-block">
        <div class="contact-info-title">
          {{ $t("message.aboutPage.contact.title") }}
        </div>
        <div class="contact-info-container">
          <div class="info-container-data">
            <div class="info-container-item">
              <div class="info-container-item-title">
                {{ $t("message.aboutPage.contact.items.first.title") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.first.data") }}
              </div>
            </div>

            <div class="info-container-item">
              <div class="info-container-item-title">
                {{ $t("message.aboutPage.contact.items.second.title") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.second.data") }}
              </div>
              <a
                href="/static/gosreg.pdf"
                target="_blank"
                class="info-container-item-document-block mb20"
              >
                <img
                  src="../../../public/custom/images/document-img.svg"
                  alt="Document image"
                />
                <div>
                  {{
                    $t("message.aboutPage.contact.items.second.documentFirst")
                  }}
                </div>
              </a>
              <div class="info-container-item-data">
                {{
                  $t("message.aboutPage.contact.items.second.middleDataBegin")
                }}
                <a
                  class="info-container-item-data-link"
                  href="https://www.nbrb.by/finsector/onlineborrowingservices/rating-onlineborrowingservices.pdf"
                  target="_blank"
                  >{{ $t("message.aboutPage.contact.items.second.link") }}</a
                >
                {{ $t("message.aboutPage.contact.items.second.middleDataEnd") }}
              </div>
              <a
                href="/static/registryInclude.pdf"
                target="_blank"
                class="info-container-item-document-block"
              >
                <img
                  src="../../../public/custom/images/document-img.svg"
                  alt="Document image"
                />
                <div>
                  {{
                    $t("message.aboutPage.contact.items.second.documentSecond")
                  }}
                </div>
              </a>
            </div>

            <div class="info-container-item">
              <div class="info-container-item-title">
                {{ $t("message.aboutPage.contact.items.third.title") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.third.items.first") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.third.items.second") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.third.items.third") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.third.items.fourth") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.third.items.five") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.third.items.six") }}
              </div>
              <a
                href="/static/charter.pdf"
                target="_blank"
                class="info-container-item-document-block"
              >
                <img
                  src="../../../public/custom/images/document-img.svg"
                  alt="Document image"
                />
                <div>
                  {{
                    $t("message.aboutPage.contact.items.third.documentFirst")
                  }}
                </div>
              </a>
              <a
                href="/static/location.pdf"
                target="_blank"
                class="info-container-item-document-block"
              >
                <img
                  src="../../../public/custom/images/document-img.svg"
                  alt="Document image"
                />
                <div>
                  {{
                    $t("message.aboutPage.contact.items.third.documentSecond")
                  }}
                </div>
              </a>
              <a
                href="/static/report_2023.pdf"
                target="_blank"
                class="info-container-item-document-block"
              >
                <img
                  src="../../../public/custom/images/document-img.svg"
                  alt="Document image"
                />
                <div>
                  {{
                    $t("message.aboutPage.contact.items.third.documentThird", {
                      year: 2023,
                    })
                  }}
                </div>
              </a>
              <a
                href="/static/report_2022.pdf"
                target="_blank"
                class="info-container-item-document-block"
              >
                <img
                  src="../../../public/custom/images/document-img.svg"
                  alt="Document image"
                />
                <div>
                  {{
                    $t("message.aboutPage.contact.items.third.documentThird", {
                      year: 2022,
                    })
                  }}
                </div>
              </a>
            </div>

            <div class="font-semibold text-lg my-5">
              Архив (ред. до 11.04.2024)
            </div>

            <a
              href="/static/location_before_11.04.2024.pdf"
              target="_blank"
              class="info-container-item-document-block"
            >
              <img
                src="../../../public/custom/images/document-img.svg"
                alt="Document image"
              />
              <div>
                {{ $t("message.aboutPage.contact.items.third.documentSecond") }}
              </div>
            </a>
          </div>

          <div class="info-container-data">
            <div class="info-container-item">
              <div class="info-container-item-title">
                {{ $t("message.aboutPage.contact.items.fourth.title") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.fourth.items.first") }}
              </div>
              <div class="info-container-item-data">
                {{
                  $t("message.aboutPage.contact.items.fourth.items.second.text")
                }}
                <a
                  style="color: #333333"
                  :href="`mailto:${$t(
                    'message.aboutPage.contact.items.fourth.items.second.link'
                  )}`"
                  >{{
                    $t(
                      "message.aboutPage.contact.items.fourth.items.second.link"
                    )
                  }}</a
                >
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.fourth.items.third") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.fourth.items.fourth") }}
              </div>
            </div>

            <div class="info-container-item">
              <div class="info-container-item-title">
                {{ $t("message.aboutPage.contact.items.fifth.title") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.fifth.items.first") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.fifth.items.second") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.fifth.items.third") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.fifth.items.fourth") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.fifth.items.fifth") }}
              </div>
              <div class="info-container-item-data">
                {{ $t("message.aboutPage.contact.items.fifth.items.sixth") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StaticPagesHeader from '../../components/common/StaticPagesHeader.vue'
import DocumentLink from '@/components/common/DocumentLink.vue'

export default {
  name: 'About',
  components: {
    DocumentLink,
    StaticPagesHeader
  },
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  methods: {
    mobileMenuToggle(value) {
      this.isMobileMenuOpen = value
    },
    signUp() {
      this.$router.push({ name: 'msi' })
    },
    saveDocument(name) {
      const link = document.createElement('a')

      link.href = require(`../../../public/static/${name}.pdf`)
      link.setAttribute('download', 'gosreg.pdf')
      link.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.about-page-wrapper {
  @include base-page-wrapper;
  @include static-heading-container;
}

.about-title-container {
  margin-bottom: 100px;
  width: 100%;
  height: 300px;
  background-image: url("../../../public/custom/images/about-wrapper.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  padding-left: 140px;
  box-sizing: border-box;
}

.title {
  font-weight: 700;
  font-size: 50px;
  line-height: 61px;
  color: #121212;
}

.about-container {
  @include base-page-container;

  padding: 70px 215px 140px 50px;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-direction: column;
}

.desc-block,
.value-block,
.difference-block {
  margin-bottom: 140px;
  display: flex;
  justify-content: space-between;
}

.block-title {
  width: calc(40% - 20px);
  font-weight: 700;
  font-size: 40px;
  line-height: 125%;
  color: #121212;
}

.desc-data {
  width: 60%;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  color: #333333;
}

b {
  color: #121212;
  font-weight: 700;
}

.about-link {
  color: #2f80ed;
}

.desc-data-item {
  width: 100%;
  margin-bottom: 10px;
}

.difference-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #cdcdcd;
  padding: 31px 0 30px 0;
  box-sizing: border-box;
}

.difference-item:last-of-type {
  border-bottom: 1px solid #cdcdcd;
}

.difference-item-title {
  width: 35%;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  color: #101010;
}

.difference-item-data {
  width: 60%;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  color: #333333;
}

.contact-info-block {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contact-info-title {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 40px;
  line-height: 125%;
  color: #121212;
}

.contact-info-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.info-container-data {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.info-container-item {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
}

.info-container-item-title {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  color: #101010;
}

.info-container-item-data {
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  color: #333333;
}

.info-container-item-data-link {
  color: #2f80ed;
}

.info-container-item-document-block {
  margin-top: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;

  img {
    height: 34px;
    width: 34px;
    margin-right: 5px;
  }

  div {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #41bf7b;
    text-decoration: none;
  }
}

.mb20 {
  margin-bottom: 20px;
}

@media screen and (max-width: 1400px) {
  .about-container {
    padding: 70px 50px 140px 50px;
  }
}

@media screen and (max-width: 1160px) {
  .about-container {
    padding: 70px 0 140px 0;
  }

  .about-title-container {
    padding-left: 2%;
  }

  .title {
    font-size: calc(40px + 10 * (100vw / 1920));
  }

  .block-title {
    font-size: calc(30px + 10 * (100vw / 1920));
  }

  .info-container-data {
    width: 45%;
  }

  .desc-data,
  .info-container-item-data,
  .info-container-item-document-block div,
  .difference-item-data {
    font-size: calc(14px + 4 * (100vw / 1920));
  }
}

@media screen and (max-width: 800px) {
  .about-title-container {
    height: 200px;
  }

  .about-container {
    padding-top: 0;
  }

  .desc-block,
  .value-block,
  .difference-block {
    flex-direction: column;
    margin-bottom: 50px;
  }

  .block-title {
    width: 100%;
    margin-bottom: 40px;
  }

  .desc-data {
    width: 100%;
  }

  .difference-item {
    flex-direction: column;
  }

  .difference-item-title {
    width: 100%;
    margin-bottom: 20px;
  }

  .difference-item-data {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .contact-info-container {
    flex-direction: column;
  }

  .info-container-data {
    width: 100%;
  }
}
</style>
