

import StaticPagesHeader from '@/components/common/StaticPagesHeader.vue'

export default {
  name: 'ServiceDocuments.vue',
  components: { StaticPagesHeader },
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  methods: {
    mobileMenuToggle(value) {
      this.isMobileMenuOpen = value
    }
  }
}
