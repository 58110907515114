import { Trade } from '@/models/trade.class'
import { Utils } from '@/helpers/utils'
import TradeService from '@/services/Trade'
import mapTradesFn = Utils.mapTradesFn;
import { borrowTradeRequestParams } from '@/constants/constants'
import { PagedState } from '@/models/paged-state.inteface'
import { PaginationState, TradeStatus } from '@/models'
import { applyFilters, resetFilters } from '@/store/shared/actions'

type BorrowTradeFilters = {
  status: TradeStatus
}

type BorrowTradeState = PagedState<{ trades: Trade[], filters: BorrowTradeFilters, pagination: PaginationState }>

const state: BorrowTradeState = {
  trades: null,
  page: 1,
  page_size: 10,
  pagination: {} as PaginationState,
  filters: {
    status: null
  }
}

const actions = {
  async get(context) {
    const { page, page_size, filters } = context.state
    const requestParams = filters.status
      ? { status: filters.status === TradeStatus.OVERDUE ? [TradeStatus.OVERDUE, TradeStatus.MAX_OVERDUE] : filters.status }
      : borrowTradeRequestParams
    const { results, pagination } = await TradeService.getTrades({ page, page_size, only_my: 1, ...requestParams })
    context.commit('setTrades', results)
    context.commit('setPagination', { ...pagination, page, page_size })
  },
  async setPage(context, page) {
    context.commit('setPage', page || context.state.page)
    await context.dispatch('get')
  },
  resetPage(context) {
    context.commit('setPage', 1)
    context.commit('resetFilters')
  },
  applyFilters,
  resetFilters
}

const mutations = {
  setTrades(state: BorrowTradeState, payload: Trade[]) {
    state.trades = payload
  },
  setPage(state: BorrowTradeState, page) {
    state.page = page
  },
  setPagination(state: BorrowTradeState, pagination: PaginationState): void {
    state.pagination = pagination
  },
  setFilters(state: BorrowTradeState, filters: BorrowTradeFilters) {
    state.filters = { ...state.filters, ...filters }
  },
  resetFilters(state: BorrowTradeState) {
    state.filters = {
      status: null
    }
  }
}

const getters = {
  tradeList(state: BorrowTradeState): Trade[] {
    return state.trades.map(mapTradesFn)
  },
  tradeFilters(state: BorrowTradeState): BorrowTradeFilters {
    return state.filters
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
