<template>
    <div class="uploader-wrapper">
        <input class="download-input" type="file"
            :name="name"
            :id="name"
            :accept="acceptedFileTypes"
            multiple="false"
            :ref="name"
            @change="addFile"
        >
        <label :for="name" class="download-btn">
            <img src="../../../public/custom/images/clip.svg" alt="Clip image">
            <span>{{ $t('message.docksUploader.downloadBtn') }}</span>
        </label>
        <div class="info-block">
            <span v-if="isHasError && isHasInvalidError" class="error-msg">{{ errorMsg }}</span>
            <span class="file-name" v-if="!isHasError">{{ fileName }}</span>
            <span class="notice" v-if="isHasError && !isHasInvalidError">(формат .jpeg, .png, .pdf) </span>
        </div>
    </div>
</template>

<script>
export default {
  name: 'DocsUploader',
  props: {
    title: String,
    name: String,
    required: {
      type: Boolean,
      default: false
    },
    cleanFile: Boolean
  },
  data() {
    return {
      isHasError: true,
      fileName: '',
      acceptedFileTypes: '.jpeg, .jpg, .png, .pdf',
      fileMaxSizeInKb: 5242880, // 5Mb
      isHasInvalidError: false
    }
  },
  methods: {
    addFile() {
      const file = this.$refs[this.name].files[0]

      if (file) {
        this.isFileInvalid(file) ? this.purgeFile() : this.emitFile(file)
      } else {
        this.fileName = ''
        this.isHasInvalidError = false
        this.isHasError = true
        this.$emit('file', undefined, this.name)
      }
    },
    isFileInvalid(file) {
      const fileSize = file.size
      this.fileName = file.name

      return fileSize > this.fileMaxSizeInKb || !(/\.(jpeg|jpg|png|pdf)$/i).test(file.name)
    },
    emitFile(file) {
      this.isHasError = false
      return this.$emit('file', file, this.name)
    },
    purgeFile() {
      this.isHasError = true
      this.isHasInvalidError = true
      return this.$emit('file', undefined, this.name)
    }
  },
  computed: {
    errorMsg() {
      return this.isHasInvalidError ? this.$t('message.docksUploader.validationErrorMsg') : this.$t('message.docksUploader.errorMsg')
    }
  },
  watch: {
    cleanFile() {
      this.fileName = ''
      this.isHasInvalidError = false
      this.isHasError = true
      this.$emit('file', undefined, this.name)
    }
  }
}
</script>

<style lang="scss" scoped>
    .uploader-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .uploader-title {
        width: 100%;
        margin-bottom: 10px;
        color: var(--docs-uploader-title-color);
        white-space: pre-line;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
    }

    .info-block {
        margin-bottom: 25px;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;

        span {
            white-space: pre-line;
        }
    }

    .notice,
    .file-name {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #878787;
    }

    .file-name {
        color: black;
    }

    .error-msg {
        color: var(--docs-uploader-error-color);
    }

    .download-input {
        display: none;
    }

    .download-btn {
        color: #41BF7B;
        cursor: pointer;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        text-decoration-line: underline;
        display: flex;
        align-items: flex-end;

        img {
            margin-right: 5px;
        }
    }

    .hidden {
        opacity: 0;
    }
</style>
