import axios from './axios'
import axiosV2 from './axiosV2'
import { MsiInfoResponse, StorageKey } from '@/models'
import { settings } from '@/services/Settings'

export default class MsiService {
  static getMsiProfile(code): Promise<MsiInfoResponse> {
    return axios.post('/msi/individual/', { code, fake: settings?.local })
  }

  static getMsiProfileV2(code): Promise<MsiInfoResponse> {
    return axiosV2.post('/msi/individual/', { code, fake: settings?.local })
  }

  static signUp(payload): Promise<{ access: string, refresh: string }> {
    return axios.post('/user/sign-up/individual/', payload)
  }

  static sendRecoveryPasswordOtp(phone: string): Promise<{ uuid: string }> {
    return axios.post('/user/reset-password/', { phone })
  }

  static verifyRecoveryPasswordOtp(payload: { code: number; uuid: string }) {
    return axios.post('/user/reset-password-verify/', payload)
  }

  static setNewPassword(payload: { uuid: string; new_password2: string; new_password1: string }) {
    return axios.post('/user/reset-password-confirm/', payload)
  }

  static loadPersonalConsentPreview(msi_personal_info_uuid: string): Promise<Blob> {
    return axios.get('/user/sign-up/personal-data-consent-example-preview/', { params: { msi_personal_info_uuid }, responseType: 'blob' })
  }

  static requestMsiUpdate(): Promise<{state: string}> {
    return axios.post('/user/setup-msi-verification/')
  }

  static verifyMsiInfo(payload: {otp_code: string, verification_id: string, params: {phone: string}}): Promise<void> {
    return axios.post('/user/verify-msi-info/', payload)
  }

  static sendFiles(payload: { msi_personal_info_id: string, type: 'car' | 'real_estate', files: File[]}) {
    return axios.postForm('/user/attachments/', {
      msi_personal_info_id: payload.msi_personal_info_id,
      type: payload.type,
      files: payload.files
    }, {
      formSerializer: {
        indexes: null
      }
    })
  }

  static getMsiSignUpLink(): Promise<{ url: string }> {
    return axios.get('/msi/sign-up/config/')
  }

  static getMsiBiometryLink(): Promise<{ url: string }> {
    const key = this.generateNonce()
    return axios.post('/msi/biometry/config/', {
      nonce: key
    })
  }

  static confirmMsiBiometry(track: string): Promise<void> {
    const nonce = localStorage.getItem(StorageKey.NONCE)
    return axios.post('/msi/biometry/confirmation/', {
      nonce,
      track
    })
  }

  private static generateNonce() {
    const nonce = crypto.randomUUID()
    localStorage.setItem(StorageKey.NONCE, nonce)
    return nonce
  }
}
