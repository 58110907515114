import { MsiResponse } from '@/models'

export default {
  computed: {
    isMainDataIncorrect() {
      return !(this.userData as MsiResponse).first_name ||
        !(this.userData as MsiResponse).last_name ||
        !(this.userData as MsiResponse).date_of_birth ||
        !(this.userData as MsiResponse).birthplace ||
        !(this.userData as MsiResponse).citizenship
    }
  }
}
