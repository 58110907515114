<template>
  <div class="card" :class="{ disabled: isDisabled }">
    <div class="content">
      <img class="icon" src="../../../public/custom/images/green-mark.svg" alt="icon"/>
      <h2 class="title">
        <slot name="title">Title text</slot>
      </h2>
      <p class="description">
        <slot name="description">Description text</slot>
      </p>
    </div>
    <div class="btn-block" v-if="actionText">
      <base-button :disabled="isDisabled" @click="$emit('click')" :is-loading="isLoading"
                   :text="actionText"></base-button>
    </div>
    <div class="overlay">
      <img class="lock" src="../../../public/custom/images/lock.svg" alt="lock"/>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue'
import VueI18n from 'vue-i18n'

export default {
  components: { BaseButton },
  name: 'ActionCard',
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    actionText: {
      type: [String, VueI18n.TranslateResult]
    }
  }
}
</script>

<style lang="scss">
.card {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: start;
  justify-content: space-between;
  text-align: left;
  max-width: 470px;
  padding: 24px;
  position: relative;
  border-radius: 12px;
  background-color: #F8F8F8;

  .content {

    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .icon {
      width: 24px;
      height: 24px;
    }

    .title {
      font-weight: 600;
      line-height: 24px;
      font-size: 16px
    }

    .description {
      font-size: 14px;
      line-height: 22px;
    }

  }

  .btn-block {
    width: auto;
  }

  .overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: grayscale(1);
    background-color: rgb(248, 248, 248, 44%);

    .lock {
      position: absolute;
      top: calc(50% - 24px);
      left: calc(50% - 24px);
    }
  }

  &.disabled {
    color: #7A7A7A;

    .overlay {
      display: block;
    }

    .action-btn {
      background-color: #BFBFBF;
    }
  }
}
</style>
