
import StaticPagesHeader from '../../components/common/StaticPagesHeader.vue'
import BaseButton from '@/components/base/BaseButton.vue'

enum ConfirmationEmailStatusEnum {
  SUCCESS = 'success',
  FAILED = 'failed'
}

export default {
  name: 'ConfirmationEmailResult',
  components: {
    StaticPagesHeader,
    BaseButton
  },
  data() {
    return {
      status: null
    }
  },
  created() {
    if (this.$route.query?.status === ConfirmationEmailStatusEnum.SUCCESS) {
      this.status = ConfirmationEmailStatusEnum.SUCCESS
    } else if (this.$route.query?.status === ConfirmationEmailStatusEnum.FAILED) {
      this.status = ConfirmationEmailStatusEnum.FAILED
    } else {
      this.$router.push({ name: 'profile' })
    }
  },
  methods: {
    async goToProfile() {
      try {
        await this.$router.push({ name: 'profile' })
      } catch {
      }
    }
  },
  computed: {
    EmailStatus(): typeof ConfirmationEmailStatusEnum {
      return ConfirmationEmailStatusEnum
    }
  }
}
