<!--<template>-->
<!--  <div class="input-wrapper">-->
<!--    <div class="input-label"-->
<!--      :class="{'with-notice': isHaveNotice || isHaveLinkNotice}"-->
<!--      v-if="label">-->
<!--      <div class="label-text" :class="{required: required}">{{ label }}</div>-->
<!--      <div v-if="isHaveNotice"-->
<!--        class="notice" :title="noticeMessage">-->
<!--        ?-->
<!--      </div>-->

<!--      <div v-if="isHaveLinkNotice"-->
<!--        class="link-notice"-->
<!--        @click="goToNoticeLink()">-->
<!--        ?-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="input-block"-->
<!--      :class="{ invalid: errors.has(name) }">-->
<!--      <input-->
<!--        class="input"-->
<!--        :type="type"-->
<!--        v-model="model"-->
<!--        :placeholder="placeholder"-->
<!--        :maxlength="maxLength"-->
<!--        :name="name"-->
<!--        v-validate="validateRules"-->
<!--        v-maska="maskPattern"-->
<!--        :disabled="disabled"-->
<!--      />-->
<!--    </div>-->
<!--    <div class="error-block">{{ errorMessage }}</div>-->
<!--  </div>-->
<!--</template>-->

<template>
  <ValidationProvider tag="div" ref='input' :rules="validateRules" class="input-wrapper" v-slot="{ errors }">
    <div class="input-label"
         :class="{'with-notice': isHaveNotice || isHaveLinkNotice}"
         v-if="label">
      <div class="label-text" :class="{required: required}">{{ label }}</div>
      <div v-if="isHaveNotice"
           class="notice" :title="noticeMessage">
        ?
      </div>

      <div v-if="isHaveLinkNotice"
           class="link-notice"
           @click="goToNoticeLink()">
        ?
      </div>
    </div>
    <div class="input-block"
         :class="{ invalid: !!errors[0] }">
      <input
          class="input"
          :type="type"
          v-model="model"
          :placeholder="placeholder"
          :maxlength="maxLength"
          :name="name"
          v-maska="maskPattern"
          :disabled="disabled"
      />
    </div>
    <div class="error-block" v-if="errors[0]">{{ errors[0] }}</div>
  </ValidationProvider>
</template>

<script>
import VueI18n from 'vue-i18n'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'BaseTextInput',
  components: {
    ValidationProvider
  },
  props: {
    label: [String, VueI18n.TranslateResult],
    isHaveNotice: {
      type: Boolean,
      default: false
    },
    isHaveLinkNotice: {
      type: Boolean,
      default: false
    },
    linkNoticePath: String,
    noticeMessage: [String, VueI18n.TranslateResult],
    defaultValue: [String, Number],
    placeholder: [String, VueI18n.TranslateResult],
    maxLength: [Number, String],
    name: String,
    validateRules: [String, Object],
    maskPattern: String,
    disabled: Boolean,
    value: [String, Number],
    required: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default() {
        return 'text'
      }
    }
  },
  methods: {
    goToNoticeLink() {
      if (this.linkNoticePath) {
        this.$router.push(this.linkNoticePath)
      }
    },
    applyValidation(message) {
      this.$refs.input.applyResult({
        errors: [message], // array of string errors
        valid: false, // boolean state
        failedRules: {} // should be empty since this is a manual error.
      })
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss"
       scoped>
@import '@/assets/mixins';

.input-wrapper {
  @include base-input-wrapper;
}

.input-label {
  @include base-input-label;
}

.with-notice {
  display: flex;
  margin-bottom: 8px;
}

.mt1 {
  margin-top: 1px;
}

.notice {
  @include base-input-notice;
  margin-left: 5px;

  &:hover {
    .notice-msg {
      display: block;
    }
  }
}

.link-notice {
  @include base-input-notice;
  margin-left: 5px;

  color: #41BF7B;
  border-color: #41BF7B;
}

.input-block {
  @include base-input-block;
}

.input {
  @include base-input;
}

.invalid {
  color: #AD141A;
  border-color: var(--base-input-invalid-border-bgc);
}

.error-block {
  margin-top: 4px;
  @include base-input-error-block;
}

.notice-msg {
  @include base-notice-msg;
}

.notice-arrow {
  position: absolute;
  bottom: -3px;
  left: 11px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}
</style>
