import { TradeStatus } from './trade-status.enum'
import { LoanRequest } from './loans/loan-request.class'

export class Trade {
  agreement_id: string
  borrower_id: number
  created_at: string
  signed_at: string
  id: number
  lender_id: number
  loan_request_id: number
  status: TradeStatus
  total_penalty: string
  remaining_debt: number
  initial_depth: number
  loan_request: LoanRequest
  borrower_rating: number
  due_date: string
  last_legal_action?: {
    legal_action_claim_signed_at?: string
    legal_action_signed_at?: string
  }

  borrower: {
    full_name: string
    address: {
      country_code: string
      region: string
      locality_type: string
      locality_name: string
      street_type: string
      street_name: string
      house_number: string
      house_unit: string
      apartment_number: string
      soato: string
    }
  }
}
