
import BaseTextInput from '../../components/base/BaseTextInput.vue'
import BaseButton from '../../components/base/BaseButton.vue'
import MsiService from '@/services/Msi'
export default {
  name: 'FirstStep',
  components: {
    BaseTextInput,
    BaseButton
  },
  data() {
    return {
      login: '',
      isBtnLoading: false
    }
  },
  computed: {
    isHasFildsErrors() {
      return this.errors.items.length > 0
    },
    isHasEmptyFields() {
      return !this.login
    },
    isBtnDisabled() {
      return this.isHasFildsErrors || this.isHasEmptyFields
    }
  },
  methods: {
    async nextStep() {
      this.isBtnLoading = true
      try {
        const parsedLogin = this.login
        const { uuid } = await MsiService.sendRecoveryPasswordOtp(parsedLogin)

        if (uuid) {
          this.$toast.success(this.$t('message.signIn.otpToastMsg'))
          this.$emit('secondStep', uuid)
        }
      } catch (error) {
        console.log(error)
      }

      this.isBtnLoading = false
    }
  }
}
