import { LoanRequest } from '@/models'
import LoanService from '@/services/Loan'
import TradeService from '@/services/Trade'
import { Trade } from '@/models/trade.class'
import { Utils } from '@/helpers/utils'
import { i18n } from '@/main'

interface LoansBorrowState {
  requestBorrowList: LoanRequest[]
  requestBorrowCount: number
  requestBorrowNext: boolean
}

interface TradeBorrowState {
  tradeBorrowList: Trade[]
  tradeBorrowCount: number
  tradeBorrowNext: boolean
}

const state: LoansBorrowState & TradeBorrowState = {
  requestBorrowList: [],
  requestBorrowCount: 0,
  requestBorrowNext: false,
  tradeBorrowList: [],
  tradeBorrowCount: 0,
  tradeBorrowNext: false
}

const actions = {
  async getBorrowRequestList(context, params) {
    const data = await LoanService.getBorrowRequest(params)
    context.commit('setBorrowRequestList', params.page > 1 ? [...context.state.requestBorrowList, ...data.results] : data.results)
    context.commit('setBorrowRequestCount', data.pagination.next)
    context.commit('setBorrowRequestNext', Boolean(data.pagination?.next))
  },
  async getBorrowTradeList(context, params) {
    const data = await TradeService.getTrades(params)
    context.commit('setBorrowTradeList', params.page > 1 ? [...context.state.tradeBorrowList, ...data.results] : data.results)
    context.commit('setBorrowTradeCount', data.pagination.next)
    context.commit('setBorrowTradeNext', Boolean(data.pagination?.next))
  }
}

const mutations = {
  setBorrowRequestList(state: LoansBorrowState, data: LoanRequest[]): void {
    state.requestBorrowList = data
  },
  setBorrowRequestCount(state: LoansBorrowState, data: number): void {
    state.requestBorrowCount = data
  },
  setBorrowRequestNext(state: LoansBorrowState, data: boolean): void {
    state.requestBorrowNext = data
  },
  setBorrowTradeList(state: TradeBorrowState, data: Trade[]): void {
    state.tradeBorrowList = data
  },
  setBorrowTradeCount(state: TradeBorrowState, data: number): void {
    state.tradeBorrowCount = data
  },
  setBorrowTradeNext(state: TradeBorrowState, data: boolean): void {
    state.tradeBorrowNext = data
  }
}

const getters = {
  borrowRequestList(state: LoansBorrowState): LoanRequest[] {
    return state.requestBorrowList.map(i => ({
      ...i,
      period_days_mod: i.period_days + ' д.',
      outcome: Utils.calculateShortOutcome(+i.amount, i.interest_rate, i.period_days),
      status_mod: i18n.t(`borrower.request.statusValue.${i.status}`)
    }))
  },
  borrowRequestCount(state: LoansBorrowState): number {
    return state.requestBorrowCount
  },
  borrowRequestNext(state: LoansBorrowState): boolean {
    return state.requestBorrowNext
  },
  borrowTradeList(state: TradeBorrowState): Trade[] {
    return state.tradeBorrowList.map(i => ({
      ...i,
      created_at_mod: new Date(i.signed_at).toLocaleDateString(),
      amount: i.loan_request?.amount,
      period_days: i.loan_request?.period_days + ' д.',
      interest_rate: i.loan_request?.interest_rate,
      income: +i.loan_request.amount + Number(Utils.calculateShortIncome(i.loan_request.amount, i.loan_request.interest_rate, i.loan_request.period_days)),
      due_date: new Date(i.due_date).toLocaleDateString(),
      status_mod: i18n.t(`message.profile.borrower.activeOffers.status.${i.status}`)
    }))
  },
  borrowTradeCount(state: TradeBorrowState): number {
    return state.tradeBorrowCount
  },
  borrowTradeNext(state: TradeBorrowState): boolean {
    return state.tradeBorrowNext
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
