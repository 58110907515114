import { render, staticRenderFns } from "./Copy.vue?vue&type=template&id=7a8c7b2e&scoped=true&"
import script from "./Copy.vue?vue&type=script&lang=ts&"
export * from "./Copy.vue?vue&type=script&lang=ts&"
import style0 from "./Copy.vue?vue&type=style&index=0&id=7a8c7b2e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a8c7b2e",
  null
  
)

export default component.exports