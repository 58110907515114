import { getSignUpDisabledFields } from '@/helpers/signUp'
import MsiService from '@/services/Msi'
import AuthService from '@/services/Auth'
import router from '@/router'
import { StorageKey, UserProfile } from '@/models'
import MSI from '@/views/MSI/index.vue'
import { environment } from '@/environments/env'
import { settings } from '@/services/Settings'
import { Roles } from '@/models/roles.enum'

interface AuthState {
  msiData: any
  signUpDisabledFields: any
  refreshToken: any
  profile: UserProfile,
}

const state: AuthState = {
  msiData: {},
  signUpDisabledFields: {},
  profile: {} as UserProfile,
  refreshToken: ''
}

const actions = {
  async getMsiProfileV2(context, code) {
    context.dispatch('loading', true)

    try {
      const data = await MsiService.getMsiProfileV2(code)
      const [phone] = data?.phones
      const [email] = data?.emails
      const address_str = data?.reg_address?.address_str
      const extraFields = {
        codeword: '',
        otp: '',
        password: '',
        living_address_str: '',
        role: Roles.BORROWER,
        msi_personal_info_uuid: data.uuid
      }
      const payload = {
        ...data,
        phone,
        email,
        address_str,
        ...extraFields
      }

      const signUpDisabledFields = getSignUpDisabledFields(payload)
      context.commit('setSignUpDisabledFields', signUpDisabledFields)
      context.commit('setMsiData', payload)

      context.dispatch('loading', false)
    } catch (error) {
      context.dispatch('loading', false)
      router.push({ name: 'SignIn' })
    }
  },
  async signUp(context, data) {
    const payload = new FormData()

    payload.append('login', data.signUpData.login)
    payload.append('password', data.signUpData.password)

    for (const fieldName in data.userData) {
      if (data.userData[fieldName]) {
        payload.append(`${fieldName}`, JSON.stringify(data.userData[fieldName]))
      }
    }

    for (const fileName in data.files) {
      if (data.files[fileName]) {
        payload.append(`${fileName}`, data.files[fileName])
      }
    }

    payload.append('eSign', ' ')

    try {
      const {
        access,
        refresh
      } = await MsiService.signUp(payload)
      await localStorage.setItem(StorageKey.ACCESS, access)
      await localStorage.setItem(StorageKey.REFRESH, refresh)

      router.push({ name: 'profile' })
    } catch (error) {
      router.push({ name: 'SignIn' })
    }
  },
  async signIn(context, payload) {
    try {
      const data = await AuthService.signIn(payload)
      const {
        access,
        refresh
      } = data
      localStorage.setItem(StorageKey.ACCESS, access)
      localStorage.setItem(StorageKey.REFRESH, refresh)

      if (context.rootState.currentRoute.query.redirect) {
        await context.dispatch('getProfile')
        await router.push(context.rootState.currentRoute.query.redirect)
      } else {
        await router.push({ name: 'profile' })
      }
    } catch (error) {
      throw new Error(error.message)
    }
  },
  async purgeAuth(context) {
    localStorage.removeItem(StorageKey.ACCESS)
    localStorage.removeItem(StorageKey.IS_ANNOUNCEMENT_SHOWED)
    await context.commit('setProfile', {})
    router.push({ name: 'main' }).catch(() => {
      console.log('Log out')
    })
  },
  async purgeProfile(context) {
    localStorage.removeItem(StorageKey.IS_ANNOUNCEMENT_SHOWED)
    await context.commit('setProfile', {})
  },
  async getProfile(context) {
    await context.dispatch('loading', true)

    try {
      const data = await AuthService.getProfile()
      await context.commit('setProfile', data)
      await context.dispatch('getValidations')
      await context.dispatch('loading', false)
      await context.dispatch('archiveOffer/resetPage')
      await context.dispatch('archiveTrade/resetPage')
    } catch (error) {
      await context.dispatch('purgeAuth')
      await context.dispatch('loading', false)
    }
  },
  async refreshToken(context) {
    try {
      const response = await AuthService.refreshToken()
      response.json().then(data => {
        if (data?.token) {
          localStorage.setItem(StorageKey.ACCESS, data.token)
        }
      })
    } catch (error) {
      context.dispatch('purgeAuth')
    }
  },
  async updateProfile(context) {
    try {
      const data = await AuthService.getProfile()
      await context.commit('setProfile', data)
      await context.dispatch('getValidations')
      await context.dispatch('archiveOffer/resetPage')
      await context.dispatch('archiveTrade/resetPage')
    } catch (error) {
      if (error.message !== '403') {
        await context.dispatch('purgeAuth')
      }
    }
  },
  async checkAuth(context) {
    const isAuthorized = !!localStorage.getItem(StorageKey.ACCESS)

    if (isAuthorized) {
      await context.dispatch('getProfile')
    }
  },
  async requestMsiUpdate() {
    try {
      const { state } = await MsiService.requestMsiUpdate()
      window.location.href = settings.msiUpdateUrl + '&state=' + state
    } catch (e) {

    }
  }
}

const mutations = {
  setMsiData(state, data) {
    state.msiData = data
  },
  setSignUpDisabledFields(state, data) {
    state.signUpDisabledFields = data
  },
  setProfile(state, data: UserProfile) {
    state.profile = data
  }
}

const getters = {
  msiData(state) {
    return state.msiData
  },
  signUpDisabledFields(state) {
    return state.signUpDisabledFields
  },
  profile(state) {
    return state.profile
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
