import { ConsentType } from '@/models/user-profile.class'

export class UserConsent {
  id: number
  type: ConsentType
  accepted: boolean
  revoked: boolean
  revoked_at: string
  accepted_at: string
  created_at: string
}
