
import { defineComponent } from 'vue'
import TradeService from '@/services/Trade'
import { ClaimService } from '@/services/Claim.service'
import { Claim, LegalAction } from '@/models'

export default defineComponent({
  name: 'CreateClaim',
  props: {
    isClaim: Boolean,
    id: Number
  },
  data() {
    return {
      confirmClaimRequest: false,
      code: null,
      uuid: null,
      claim_id: null,
      legal_action_id: null,
      showInfo: false,
      unsigned: Claim,
      unsignedLegalAction: LegalAction
    }
  },
  methods: {
    async createClaim() {
      try {
        const data = await TradeService.postClaim(this.id)
        this.$router.push({ name: 'sign-claim', params: { id: data.id } })
      } catch (e) {
        this.$emit('closeClaimModal')
      }
    },
    async createLegalAction() {
      try {
        const data = await TradeService.postPostLegalAction(this.id)
        this.$router.push({ name: 'sign-legal-action', params: { id: data.id } })
      } catch (e) {
        this.$emit('closeClaimModal')
      }
    }
  }
})
