<template>
  <div class="side-menu-wrapper">
    <img @click="$router.push({ name: 'main' })" class="logo" src="../../../public/custom/images/logo.svg" alt="Logo image">
    <router-link class="menu-item" v-for="route of routesList" :key="route" :to="`/${route}`">
      <img class="menu-item-image" :src="require(`../../../public/custom/images/nav/${route}.svg`)" alt="Menu item image">
      <img class="menu-item-image-checked" :src="require(`../../../public/custom/images/nav/${route}-checked.svg`)" alt="Menu checked item image">
      <span class="route-name">{{ $t(`message.sideMenu.${route}`) }}</span>
    </router-link>
  </div>
</template>

<script>
import { settings } from '@/services/Settings'

export default {
  name: 'SideMenu',
  data() {
    return {
      routesList: settings.sideMenuRoutes
    }
  }
}
</script>

<style lang="scss" scoped>
  .side-menu-wrapper {
    width: 327px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    padding: 40px 0 0 95px;
    box-sizing: border-box;
  }

  .logo {
    width: 150px;
    height: 31px;
    margin-bottom: 51px;
    cursor: pointer;
  }

  .menu-item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.02);
    }
    &:hover .menu-item-image {
      display: none;
    }

    &:hover .menu-item-image-checked {
      display: block;
    }
  }

  .router-link-active {
    & .menu-item-image {
      display: none;
    }

    & .menu-item-image-checked {
      display: block;
    }
  }

  .menu-item-image,
  .menu-item-image-checked {
    height: 28px;
    width: 28px;
    margin-right: 10px;
    display: block;
  }

  .menu-item-image-checked {
    display: none;
  }

  .route-name {
    font-size: 16px;
    line-height: 22px;
    color: #878787;
    text-decoration: none;
  }

  @media screen and (max-width: 1450px) {
    .side-menu-wrapper {
      width: 270px;
      padding: 40px 0 0 55px;
    }
  }

  @media screen and (max-width: 1390px) {
    .side-menu-wrapper {
      width: 240px;
      padding: 40px 0 0 25px;
    }
  }

  @media screen and (max-width: 1155px) {
    .side-menu-wrapper {
      display: none;
    }
  }
</style>
