<template>
  <div class="recovery-wrapper">
    <div class="back-btn-container"
        @click="previousStep()">
      <img src="../../../public/custom/images/back-arrow.svg"
          alt="Back arrow"
          class="back-img">
      <span class="back-btn-text">{{ $t('message.passwordRecovery.backBtn') }}</span>
    </div>
    <div class="recovery-container">
      <img class="logo"
          src="../../../public/custom/images/logo.svg"
          alt="Logo image">
      <component
          :is="stepBlocks[stepCounter]"
          @nextStep="nextStep()"
          @secondStep="secondStep"
          @thirdStep="thirdStep"
          @setPassword="setPassword"
      >
      </component>
    </div>
  </div>
</template>

<script>
import FirstStep from './FirstStep.vue'
import SecondStep from './SecondStep.vue'
import ThirdStep from './ThirdStep.vue'
import MsiService from '@/services/Msi'

export default {
  name: 'PasswordRecovery',
  components: {
    FirstStep,
    SecondStep,
    ThirdStep
  },
  data() {
    return {
      stepBlocks: [FirstStep, SecondStep, ThirdStep],
      stepCounter: 0
    }
  },
  methods: {
    nextStep() {
      this.stepCounter++
    },
    secondStep(uuid) {
      this.stepCounter = 1
      this.uuid = uuid
    },
    async thirdStep(code) {
      try {
        const payload = {
          uuid: this.uuid,
          code
        }
        await MsiService.verifyRecoveryPasswordOtp(payload)
        this.stepCounter = 2
      } catch (error) {
        console.log(error)
      }
    },
    async setPassword(new_password1) {
      const payload = {
        uuid: this.uuid,
        new_password1,
        new_password2: new_password1
      }

      await MsiService.setNewPassword(payload)
      this.$router.push({ name: 'SignIn' })
    },
    previousStep() {
      const firstStepCounter = 0
      this.stepCounter === firstStepCounter ? this.$router.push({ name: 'SignIn' }) : this.stepCounter--
    }
  }
}
</script>

<style lang="scss"
    scoped>
.recovery-wrapper {
  @include base-page-wrapper;
  justify-content: center;
  position: relative;

  background-image: url('../../../public/custom/images/passsword-recovery-bgi.png');
  background-position: center;
  background-size: cover;
  object-fit: cover;
}

.back-btn-container {
  position: absolute;
  top: 10%;
  left: 5%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back-img {
  margin-right: 4px;
  height: 18px;
  width: 18px;
}

.back-btn-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  color: var(--password-recovery-back-btn-color);
}

.recovery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.logo {
  width: 280px;
  height: 58px;
  margin-bottom: 102px;
}

@media screen and (max-width: 1000px) {
  .logo {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .back-btn-container {
    top: 5%;
  }
}
</style>
