import { Roles } from '@/models/roles.enum'
import {
  EmploymentArea,
  EmploymentPosition,
  EmploymentType,
  UserEducation,
  UserFamilyStatus
} from '@/constants/constants'
import { BiometryStatus } from '@/models/biometry-status.enum'
import { PaymentProvider } from '@/models/paymentProvider'

export enum ConsentType {
  PERSONAL_DATA = 'personal_data',
  BANK_REPORT = 'bank_report'
}

export enum UserStatus {
  NEW = 'new',
  PENDING = 'pending',
  ACTIVE = 'active',
  DECLINED = 'declined',
  BLOCKED = 'blocked'
}

export class Consent {
  type: ConsentType
  status: boolean
}

export class UserProfile {
  email: string
  phone: string
  first_name: string
  last_name: string
  middle_name: string
  role: Roles
  consents: Consent[]
  rating: number
  id_number: string
  status: UserStatus
  codeword: string
  has_bank_card: boolean
  additional_info_verified: boolean
  msi_info_verified: boolean
  msi_biometry: BiometryStatus
  for_block: boolean
  premium_loans: boolean
  payment_provider: PaymentProvider
  bepaid_checkout_notification: {
    show: boolean
    datetime: string
  }
}
export class UserWorkInfo {
  employment_type: EmploymentType
  employment_area: EmploymentArea
  company_name: null
  position: null
  experience: null
}

export class UserExtraInfo {
  education: UserEducation
  family_status: UserFamilyStatus
  driving_license: boolean
  car_owned: boolean
  real_estate_owned: boolean
  number_of_dependents: number
  need_to_refund_expenses: boolean
  monthly_income: number
}

export class UserAddress {
  address_str: string
  apartment_number: string
  country_code: string
  created_at: string
  house_number: string
  house_unit: string
  locality_name: string
  locality_type: string
  region: string
  street_name: string
  street_type: string
  type: string
  updated_at: string
}
