

const __default__ = {
  name: 'Copy',
  props: {
    copyValue: {
      type: [String, Number],
      required: true
    },
    size: {
      type: Number,
      default: 16
    },
    stroke: {
      type: String,
      default: '#A7A7A7'
    },
    hoverColor: {
      type: String,
      default: 'var(--primary)'
    },
    copyMessage: {
      type: [String, Object],
      required: true
    }
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(String(this.copyValue))
      this.$toast.success(this.copyMessage)
    }
  }
}


import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "ee94eba0": (_vm.hoverColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__