<template>
  <div class="contract-wrapper">
    <base-spinner
      :is-loading="isLoading"
    />
    <div class="contract-heading" v-if="!isLoading">
      <div class="back-btn-container" @click="$router.push({name: 'main'})">
        <img src="../../../public/custom/images/back-arrow.svg" alt="Back arrow" class="back-img">
        <span class="back-btn-text">{{ $t('message.passwordRecovery.backBtn') }}</span>
      </div>
    </div>
    <div class="contract-container" v-if="!isLoading">
      <iframe class="iframe" v-if="pdfSource" :src="pdfSource + '#view=Fit&toolbar=0'" height="1000" />
      <div class="load-agreement">
        <base-button
          @click="loadAgreement()"
          :text="$t('Load agreement')"
        />
      </div>
      <div class="block-title">ПОДПИСЬ ЗАЕМЩИКА</div>
      <div class="sign-offer-block">
        <div class="sign-offer-item">
          <div
            class="check-box-btn"
            :class="{ 'check-box-checked': isAgreeWithServiceAgreement }"
            @click.stop="checkboxHandler()"
          >
            <img
              v-if="isAgreeWithServiceAgreement"
              class="check-box-image"
              src="../../../public/custom/images/check.svg"
              alt="Checket image"
            />
          </div>
          <div
            class="check-box-label"
            @click.stop="checkboxHandler()"
          >
          Текст Договора прочитан мной полностью, с условиями Договора ознакомлен(а) и согласен(а)
          </div>
        </div>
        <div class="otp-container">
          <div class="otp-block">
            <base-text-input
              :name="'otp'"
              :validate-rules="'required'"
              :placeholder="$t('message.contract.otp.placeholder')"
              v-model="otp"
              :disabled="isSignBtnLoading"
            />
          </div>
          <div class="send-otp-block" @click="sign()">Получить код</div>
        </div>
      </div>
      <div class="borrower-sign-block">
        <div>Заемщик:</div>
        <div class="full-name">{{ userLastName }} {{ userName }} {{ userMiddleName }}</div>
      </div>
      <div class="btns-block">
        <div class="btn-container">
          <base-button
            :text="$t('message.contract.btn')"
            :disabled="isSignBtnDisabled"
            @click="confirm()"
            :is-loading="isSignBtnLoading"
          />
        </div>
      </div>
    </div>
    <base-modal v-if="isShowSuccessModal"
      @close="closeSuccessModal()">
      <template #header>
        <div class="modal-header">
          <img @click.stop="closeSuccessModal()" class="close-image" src="../../../public/custom/images/cross.svg" alt="Cross image">
        </div>
      </template>
      <template #main>
        <div class="modal-title">
          {{ $t('message.contract.successModal.title') }}
        </div>
        <div class="max-w-xl mb-6">
          {{ $t('message.contract.successModal.desc.text') }}
          <router-link class="modal-desc-link" to="/profile">
            {{ $t('message.contract.successModal.desc.link') }}
          </router-link>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-center">
          <base-button class="max-w-xs"
            :text="$t('message.contract.successModal.btn')"
            @click="closeSuccessModal()"
          />
        </div>
      </template>
    </base-modal>

    <base-modal v-if="isShowErrorModal" @close="closeErrorModal()">
      <template #header>
        <div class="modal-header">
          <img @click.stop="closeErrorModal()" class="close-image" src="../../../public/custom/images/cross.svg" alt="Cross image">
        </div>
      </template>
      <template #main>
        <div class="modal-title modal-error-title">
          <span>{{ $t('message.contract.errorModal.title') }}</span>
        </div>
        <div class="max-w-xl mb-6">
          <span>{{ $t('message.contract.errorModal.desc') }}</span>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-center">
          <base-button class="max-w-xs"
            :text="$t('message.contract.errorModal.btn')"
            @click="closeErrorModal()"
          />
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseTextInput from '../../components/base/BaseTextInput.vue'
import BaseButton from '../../components/base/BaseButton.vue'
import BaseSpinner from '../../components/base/BaseSpinner.vue'
import BaseModal from '../../components/base/BaseModal.vue'
import LoanService from '@/services/Loan'
import TradeService from '@/services/Trade'
import { Utils } from '@/helpers/utils'

export default {
  name: 'Contract',
  components: {
    BaseTextInput,
    BaseButton,
    BaseSpinner,
    BaseModal
  },
  async created() {
    this.trade_id = this.$route.query?.trade_id

    if (this.trade_id) {
      try {
        const blob = await LoanService.getLoanData(this.trade_id)
        this.agreementBlob = blob
        this.pdfSource = URL.createObjectURL(blob)
        this.isLoading = false
      } catch (e) {

      }
    }
  },
  data() {
    return {
      isShowSuccessModal: false,
      isShowErrorModal: false,
      isAgreeWithServiceAgreement: false,
      message_id: '',
      otp: '',
      isLoading: true,
      offerData: {

      },
      agreementId: '',
      loanData: {},
      lender: {},
      isSignBtnLoading: false,
      pdfSource: null,
      trade_id: null,
      uuid: '',
      agreementBlob: null
    }
  },
  computed: {
    ...mapGetters(['profile']),
    date() {
      return new Date().toLocaleDateString()
    },
    userName() {
      return this.profile?.first_name
    },
    userLastName() {
      return this.profile?.last_name
    },
    userMiddleName() {
      return this.profile?.middle_name
    },
    offerAmount() {
      return this.offerData?.loan_amount / 100
    },
    offerTerm() {
      return this.offerData?.loan_term_days
    },
    offerPercent() {
      return this.offerData?.annual_percent
    },
    offerIssueDate() {
      // const dayInMs = 86400000; //День в миллисекундах
      // const offerTermInMs = this.offerTerm * dayInMs;
      // const currentDate = Date.now();
      // const targetDate = offerTermInMs + currentDate;

      return new Date(this.loanData?.due_date).toLocaleDateString()
    },
    isHasFieldsErrors() {
      return this.errors.items.length > 0
    },
    isHasEmptyFields() {
      return !this.otp
    },
    isSignBtnDisabled() {
      return this.isHasFieldsErrors || this.isHasEmptyFields || !this.isAgreeWithServiceAgreement || this.isSignBtnLoading || !this.uuid
    }
  },
  methods: {
    loadAgreement() {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = this.pdfSource
      a.target = '_blank'
      a.download = this.trade_id + '.pdf'
      document.body.appendChild(a)
      a.click()
    },
    closeSuccessModal() {
      this.isShowSuccessModal = false
      this.$router.push({ name: 'main' })
    },
    openSuccessModal() {
      this.isShowSuccessModal = true
    },
    closeErrorModal() {
      this.isShowErrorModal = false
      this.$router.push({ name: 'main' })
    },
    openErrorModal() {
      this.isShowErrorModal = true
    },
    checkboxHandler() {
      if (!this.isSignBtnLoading) {
        this.isAgreeWithServiceAgreement = !this.isAgreeWithServiceAgreement
      }
    },
    async sign() {
      try {
        const { uuid } = await TradeService.requestSign(this.trade_id)
        this.uuid = uuid
        this.$toast.success(this.$t('message.signIn.otpToastMsg'))
      } catch (e) {
      }
    },
    async confirm() {
      try {
        await TradeService.signRequestCheckCode(this.trade_id, this.otp, this.uuid)
        this.uuid = null
        this.otp = null
        this.openSuccessModal()
        this.isSignBtnLoading = false
      } catch (e) {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .load-agreement {
    display: none;
  }
  .contract-wrapper {
    @include base-page-wrapper;
  }

  .contract-heading,
  .contract-container {
    @include base-page-container;
  }

  .contract-container {
    width: 1550px;
    max-width: 90%;
    flex-direction: column;
  }

  .back-btn-container {
    margin-top: 30px;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.back-img {
    margin-right: 4px;
    height: 18px;
    width: 18px;
}

.back-btn-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 135%;
    color: var(--password-recovery-back-btn-color);
}

.contract-title,
.contract-date,
.block-title,
.offer-title {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: calc(20px + 20 * (100vw / 1920));
  line-height: 125%;
  color: #121212;
  text-align: center;
}

.block-title,
.offer-title {
  font-size: calc(20px + 15 * (100vw / 1920));
  margin-top: 50px;
  margin-bottom: 50px;
  font-weight: 400;
}

.offer-title {
  font-size: calc(15px + 10 * (100vw / 1920));
  font-weight: 500;
  text-decoration: underline;
}

.contract-date {
  margin-bottom: 100px;
}

.indented-paragraph {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  text-indent: calc(20px + 40 * (100vw / 1920));
  font-size: calc(20px + 10 * (100vw / 1920));
  line-height: 125%;

  a {
    color: blue;
  }
}

.not-indented-paragraph {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  text-indent: 0;
  font-size: calc(20px + 10 * (100vw / 1920));
  line-height: 125%;

  a {
    color: blue;
  }
}

.lender-container,
.borrower-container {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  margin-bottom: 100px;
  margin-top: 100px;
}

.offer-data-container,
.payment-data-container {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.payment-data-container {
  margin-bottom: 50px;
}

.borrower-container {
  .name-container {
    margin-bottom: 50px;
  }
}

.name-container,
.email-container,
.id-document-container,
.id-document-authority,
.offer-container,
.payment-container,
.sign-offer-block {
  width: 100%;
  display: flex;
}

.sign-offer-block {
  flex-direction: column;
}

.id-document-container {
  margin-bottom: 50px;
}

.name-item,
.email-item,
.email-item-disabled,
.id-item,
.authority-item,
.authority-email-item,
.offer-item,
.payment-item {
  min-width: 400px;
  min-height: 69.5px;
  width: calc(100% / 3);
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 15 * (100vw / 1920));
  line-height: 125%;
  font-weight: 500;
  text-align: center;
}

.offer-item,
.payment-item {
  min-height: 100px;
  text-align: start;
  justify-content: flex-start;
}

.payment-item {
  width: calc(100% / 5);
}

.offer-item {
  width: calc(100% / 4);
}

.authority-item {
  min-width: 720px;
  width: 60%;
}

.authority-email-item {
  width: 40%;
  min-width: 480px;
}

.id-item,
.offer-item {
  min-width: 300px;
}

.payment-item {
  min-width: 240px;
}

.email-item-disabled {
  border: 1px solid lightgrey;
}

.email-item:first-of-type {
  border-top: none;
}

.name-item:nth-of-type(3),
.name-item:nth-of-type(2) {
  border-left: none;
}

.sign-offer-item {
  margin-bottom: 50px;
  display: flex;
  align-items: flex-start;
}

.check-box-btn {
  @include base-check-box;
  width: 34px;
  height: 34px;
}

.check-box-label {
  @include base-check-box-label;

  font-size: calc(15px + 10 * (100vw / 1920));
  line-height: 125%;
  font-weight: 500;
}

.otp-container {
  display: flex;
  align-items: center;
}

.otp-block {
  width: 197px;
  margin-right: 20px;
}

.send-otp-block {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  color: #41BF7B;
  cursor: pointer;
}

.btns-block {
  margin-top: 100px;
  margin-bottom: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn-container {
  width: 300px;
  height: 65px;
}

.borrower-sign-block {
  margin: 50px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: calc(10px + 15 * (100vw / 1920));
  line-height: 125%;
}

.full-name {
  text-decoration: underline;
}

.modal-header {
  @include modal-close-block;
}

.modal-title {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 22px;
  line-height: 125%;
  text-align: center;
  color: #41BF7B;
}

.modal-error-title {
  color: #EB5757;
}

.modal-desc-link {
  color: #41BF7B;
}

.modal-btn-block {
  margin-bottom: 27px;
  width: 65px;
  height: 40px;
}

@media screen and (max-width: 750px) {
  .modal-desc {
    max-width: 100%;
  }
  .iframe {
    display: none;
  }
  .load-agreement {
    display: block;
  }
}
</style>
