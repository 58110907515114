
import { mapGetters } from 'vuex'
import BaseTextInput from '@/components/base/BaseTextInput.vue'
import { OtpAction, UserExtraInfo, UserWorkInfo } from '@/models'
import BaseSelect from '@/components/base/BaseSelect.vue'
import CONSTANTS from '../../constants/constants'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import ProfileSettings from '@/services/ProfileSettings'
import { Roles } from '@/models/roles.enum'
import { Utils } from '@/helpers/utils'
import formatFileName = Utils.formatFileName;
import formatFileSize = Utils.formatFileSize;
import validateFile = Utils.validateFile;

interface Data {
  work_info: UserWorkInfo
  extra_info: UserExtraInfo
  loading: boolean
  otp_code: string
  code_requested: boolean
  confirm: boolean
  isWorkInfoBlockDisabled: boolean
  car_owned_files: Array<{ id: string, name: string, type: 'car' | 'real_estate', size: number } | File>
  real_estate_owned_files: Array<{ id: string, name: string, type: 'car' | 'real_estate', size: number } | File>
  carFileError: string
  realEstateFileError: string
  unpin_files_car: string[],
  unpin_files_real_estate: string[],
  error: string
}

export default {
  name: 'ChangeProfile',
  components: {
    BaseTextInput,
    BaseSelect,
    BaseCheckbox
  },
  async created() {
    const {
      work_info,
      extra_info
    } = await ProfileSettings.getAdditionalInfo()
    this.work_info = work_info || new UserWorkInfo()
    this.extra_info = extra_info || new UserExtraInfo()
    const files = await ProfileSettings.getAttachments()
    files.forEach(file => {
      switch (file.type) {
        case 'car':
          this.car_owned_files = [...this.car_owned_files ?? [], file]
          break
        case 'real_estate':
          this.real_estate_owned_files = [...this.real_estate_owned_files ?? [], file]
          break
      }
    })
    this.loading = false
    this.onWorkInfoInitiated(this.work_info.employment_type)
  },
  data(): Data {
    return {
      work_info: new UserWorkInfo(),
      extra_info: new UserExtraInfo(),
      otp_code: null,
      loading: true,
      code_requested: false,
      confirm: false,
      isWorkInfoBlockDisabled: false,
      car_owned_files: null,
      real_estate_owned_files: null,
      carFileError: null,
      realEstateFileError: null,
      unpin_files_car: [],
      unpin_files_real_estate: [],
      error: ''
    }
  },
  computed: {
    Roles() {
      return Roles
    },
    ...mapGetters(['profile']),
    CONSTANTS() {
      return CONSTANTS
    },
    isInvalid() {
      return this.errors.items?.length > 0
    },
    isBankReportAvailable() {
      return this.profile.consents?.find(consent => consent.type === 'bank_report')?.status && this.profile.role === Roles.BORROWER
    },
    isAddCarFilesDisabled() {
      return this.car_owned_files?.length === 4
    },
    isAddRealEstateFilesDisabled() {
      return this.real_estate_owned_files?.length === 4
    },
    fileValidationRules() {
      return 'required|ext:pdf,jpeg,jpg,png,jip,tiff|size:10240'
    }
  },
  methods: {
    async requestOtpCode(): Promise<void> {
      await ProfileSettings.requestOtp(this.profile.phone, OtpAction.UPDATE_PERSONAL_INFO)
      this.$toast.success(this.$t('message.signIn.otpToastMsg'))
      this.code_requested = true
    },
    async update(): Promise<void> {
      this.loading = true
      this.error = this.validate()
      if (this.error) {
        this.loading = false
        return
      }
      try {
        if (this.extra_info.car_owned) {
          await ProfileSettings.updateAttachments({
            type: 'car',
            files: this.car_owned_files.filter(f => !f.id),
            unpin_files_ids: this.unpin_files_car
          })
        }
        if (this.extra_info.real_estate_owned) {
          await ProfileSettings.updateAttachments({
            type: 'real_estate',
            files: this.real_estate_owned_files.filter(f => !f.id),
            unpin_files_ids: this.unpin_files_real_estate
          })
        }
        await ProfileSettings.updateAdditionalInfo({
          otp_code: this.otp_code,
          extra_info: this.extra_info,
          work_info: {
            ...this.work_info,
            position: this.work_info.position ? this.work_info.position === '-' ? null : this.work_info.position : null
          }
        })
        const {
          work_info,
          extra_info
        } = await ProfileSettings.getAdditionalInfo()
        this.work_info = work_info || new UserWorkInfo()
        this.extra_info = extra_info || new UserExtraInfo()
        this.loading = false
        this.otp_code = null
        this.code_requested = false
        this.confirm = false
        if (this.profile.role === Roles.BORROWER) {
          this.$router.push({ name: 'scoringAgreement' })
        }
      } catch (e) {
        this.loading = false
      }
    },
    onWorkInfoInitiated(value) {
      if (['tmp_unemployed', 'pensioner', 'student'].includes(value)) {
        this.blockWorkInfoFields()
      }
    },
    onTypeOfEmploymentChanged(value) {
      if (['tmp_unemployed', 'pensioner', 'student'].includes(value)) {
        this.blockWorkInfoFields()
        return
      }
      this.work_info.company_name = this.work_info.employment_area = this.work_info.position = this.work_info.experience = null
      this.isWorkInfoBlockDisabled = false
    },
    blockWorkInfoFields() {
      this.work_info.company_name = this.work_info.position = '-'
      this.work_info.employment_area = 'not_indicated'
      this.isWorkInfoBlockDisabled = true
      this.work_info.experience = 0
    },
    removeEstateFile(name) {
      if (this.isBankReportAvailable) return
      this.real_estate_owned_files = this.real_estate_owned_files.filter(file => {
        if (file.name === name) {
          if (file.id) {
            this.unpin_files_real_estate = [...this.unpin_files_real_estate, file.id]
          }
          return false
        } else {
          return true
        }
      })
    },
    removeCarFile(name) {
      if (this.isBankReportAvailable) return
      this.car_owned_files = this.car_owned_files.filter(file => {
        if (file.name === name) {
          if (file.id) {
            this.unpin_files_car = [...this.unpin_files_car, file.id]
          }
          return false
        } else {
          return true
        }
      })
    },
    validate() {
      if (this.extra_info.car_owned) {
        if (!this.car_owned_files || this.car_owned_files?.length < 2) {
          return 'car_owned_files'
        }
      }
      if (this.extra_info.real_estate_owned) {
        if (!this.real_estate_owned_files || this.real_estate_owned_files?.length === 0) {
          return 'real_estate_owned_files'
        }
      }
      return this.errors.items.length > 0 ? 'other' : ''
    },
    onCarFilesChange(event) {
      this.carFileError = null
      this.car_owned_files = this.car_owned_files ?? []
      const currentFiles: File[] = this.car_owned_files
      const newFiles: File[] = [...event.target.files]
      newFiles.forEach(file => {
        if (currentFiles.findIndex(f => file.name === f.name) !== -1) {
          this.carFileError = 'Один из файлов уже добавлен'
          return
        }
        if (!validateFile(file)) {
          this.carFileError = 'Не все файлы из выбранных добавлены. Проверьте размер и расширение'
          return
        }
        if (currentFiles.length === 4) {
          this.carFileError = 'Не все файлы из выбранных добавлены. Максимальное количество файлов - 4'
          return
        }
        currentFiles.push(file)
      })
    },
    onEstateFilesChange(event) {
      this.realEstateFileError = null
      this.real_estate_owned_files = this.real_estate_owned_files ?? []
      const currentFiles: File[] = this.real_estate_owned_files ?? []
      const newFiles: File[] = [...event.target.files]
      newFiles.forEach(file => {
        if (currentFiles.findIndex(f => file.name === f.name) !== -1) {
          this.realEstateFileError = 'Один из файлов уже добавлен'
          return
        }
        if (!validateFile(file)) {
          this.realEstateFileError = 'Не все файлы из выбранных добавлены. Проверьте размер и расширение'
          return
        }
        if (currentFiles.length === 4) {
          this.realEstateFileError = 'Не все файлы из выбранных добавлены. Максимальное количество файлов - 4'
          return
        }
        currentFiles.push(file)
      })
    },
    formatFileSize,
    formatFileName
  }
}
