import { LoanRequestStatus, LoanRequestType } from '@/models'

export class LoanRequest {
  display_name?: string;
  user_id: number
  amount: number
  period_days: number
  interest_rate: number
  status: LoanRequestStatus = LoanRequestStatus.ACTIVE
  id: number
  loans_count?: number = 1
  rating?: number = null
  period_type: LoanRequestType = LoanRequestType.SHORT
  trade_id?: number
  total_depth?: number
  percent_amount?: number
  pin_to_top?: boolean
}
