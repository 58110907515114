<template>
  <div class="filters-row">
    <div class="filter-item">
      <div class="item-label">
        <label>{{ $t("Lend amount") }}</label>
        <input
          :placeholder="`${$t('from')} 200`"
          type="number"
          v-model="filters.amount_min"
        />
      </div>
      <input
        :placeholder="`${$t('to')} 1000`"
        type="number"
        v-model="filters.amount_max"
      />
    </div>
    <div class="filter-item">
      <div class="item-label">
        <label>{{ $t("Lend period") }}</label>
        <input
          :placeholder="`${$t('from')} 10`"
          type="number"
          v-model="filters.period_days_min"
        />
      </div>
      <input
        :placeholder="`${$t('to')} 60 ${$t('days')}`"
        type="number"
        v-model="filters.period_days_max"
      />
    </div>
    <div class="filter-item">
      <div class="item-label">
        <label
          >{{ $t("Rating") }}
          <div
            v-if="showTooltip"
            class="flex items-center"
            v-tooltip="`${$t('Rate tooltip')}`"
          >
            <span class="tooltip">
              <img
                src="../../../public/custom/images/error-rounded.svg"
                alt="tooltip"
              />
            </span></div
        ></label>
        <input
          :placeholder="`${$t('from')} 0`"
          type="number"
          v-model="filters.rating_min"
        />
      </div>
      <input
        :placeholder="`${$t('to')} 100`"
        type="number"
        v-model="filters.rating_max"
      />
    </div>
    <div class="filter-actions">
      <button class="outlined" @click="handleClearFilters">
        {{ $t("Clear") }}
      </button>
      <button @click="applyFilters">{{ $t("Apply") }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clearFilters: {
      type: Function,
      required: true
    },
    showTooltip: {
      type: Boolean,
      defaultValue: false
    }
  },
  data() {
    return {
      filters: {
        amount_min: undefined,
        amount_max: undefined,
        period_days_min: undefined,
        period_days_max: undefined,
        rating_min: undefined,
        rating_max: undefined
      }
    }
  },

  methods: {
    applyFilters() {
      this.$emit('apply-filters', this.filters)
    },
    handleClearFilters() {
      this.clearFilters()
      this.filters = {
        amount_min: undefined,
        amount_max: undefined,
        period_days_min: undefined,
        period_days_max: undefined,
        rating_min: undefined,
        rating_max: undefined
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-row {
  margin-bottom: 1.25rem;
  border-radius: 0.5rem;
  background-color: #f8f8f8;
  max-width: 95%;
  width: 1730px;
  display: flex;
  gap: 2rem;
  padding: 0.75rem;
  .filter-item {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    row-gap: 0.25rem;

    input {
      width: 100%;
      padding: 0.75rem;
      line-height: 1.375rem;
      border-radius: 0.625rem;
      &::placeholder {
        color: #878787;
        font-weight: 400;
      }
      border: 1px solid #cdcdcd;
      &:focus,
      &:active {
        outline: none;
        border-color: #41bf7b;
      }
    }
    .item-label {
      display: flex;
      flex-direction: column;
      label {
        margin-bottom: 0.75rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .tooltip {
          cursor: pointer;
        }
      }
    }
  }
  .filter-actions {
    display: grid;
    gap: 1rem;
    flex: auto;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    button {
      font-size: 1rem;
      border-radius: 0.625rem;
      line-height: 1.375rem;
      border: 2px solid #41bf7b;
      background-color: #41bf7b;
      padding: 0.75rem 1.5rem;
      color: #fff;
      font-weight: 600;
      transition: 0.4s ease;
      &:hover {
        background-color: #178148;
        border-color: #178148;
      }
      &.outlined {
        background-color: transparent;
        color: #178148;
        &:hover {
          border-color: #41bf7b;
          background-color: #41bf7b;
          color: #fff;
        }
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  .filters-row {
    display: grid;
    row-gap: 0.75rem;
    column-gap: 60px;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .filters-row {
    display: flex;
    flex-direction: column;
  }
}
</style>
