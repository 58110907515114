import axios from './axios'
import { ChangePasswordRequest, OtpAction, UserAddress, UserConsent, UserExtraInfo, UserWorkInfo } from '@/models'

export default class ProfileSettings {
  static requestOtp(phone: string, action: OtpAction): Promise<void> {
    return axios.post('/user/request-otp/', { phone, action })
  }

  static changePassword(data: ChangePasswordRequest): Promise<void> {
    return axios.post('/user/change-password/', data)
  }

  static getAdditionalInfo(): Promise<{ work_info: UserWorkInfo, extra_info: UserExtraInfo }> {
    return axios.get('/user/additional-info/')
  }

  static updateAdditionalInfo(payload: {
    otp_code: string,
    work_info: UserWorkInfo,
    extra_info: UserExtraInfo
  }): Promise<void> {
    return axios.post('/user/update-additional-info/', payload)
  }

  static getContactInfo(): Promise<{ living_address: UserAddress, living_addr_eq_reg: boolean }> {
    return axios.get('/user/living-address/')
  }

  static updateContactInfo(payload): Promise<void> {
    return axios.post('/user/update-personal-info/', payload)
  }

  static getPersonalDataConsent(): Promise<UserConsent> {
    return axios.get('/user/personal-data-consent/')
  }

  static revokePersonalDataConsent(otp_code: string): Promise<void> {
    return axios.post('/user/revoke-personal-data-consent/', { otp_code })
  }

  static acceptPersonalDataConsent(otp_code: string): Promise<void> {
    return axios.post('/user/accept-personal-data-consent/', { otp_code })
  }

  static updateAttachments(payload: { files: File[], type: 'car' | 'real_estate', unpin_files_ids: string[] }) {
    return axios.postForm('/user/profile/attachments/', {
      type: payload.type,
      files: payload.files,
      unpin_files: payload.unpin_files_ids
    }, {
      formSerializer: {
        indexes: null
      }
    })
  }

  static getAttachments(): Promise<Array<{ id: string, type: 'car' | 'real_estate', name: string, size: number }>> {
    return axios.get('/user/profile/attachments')
  }

  static confirmEmail(email: string): Promise<void> {
    return axios.post('/user/update-email/', {
      new_email: email
    })
  }
}
