<template>
  <div class="sign-in-wrapper">
    <div class="sing-in-container">
      <div class="sign-in-block form-block" @keyup.enter="signIn()">
        <img @click="goToMain()" class="logo" src="../../public/custom/images/logo.svg" alt="Logo image">
        <div class="title">{{ $t('message.signIn.title') }}</div>
        <div class="login-wrapper" :class="{'invalid': errors.has('msisdn')}">
          <input type="text"
                 class="login-input"
                 :placeholder="$t('message.signIn.loginPlaceholder')"
                 @maska="login = '375' + $event.target.dataset.maskRawValue"
                 name="msisdn"
                 v-validate="'required|msisdn'"
                 v-maska="'+375 (##) ###-##-##'"
          >
        </div>
        <div class="login-error-block">{{ errors.first('msisdn') }}</div>
        <div class="password-wrapper" :class="{'invalid': errors.has('password')}">
          <input class="password-input"
                 :type="isShowPassword ? 'text' : 'password'"
                 :placeholder="$t('message.signIn.passwordPlaceholder')"
                 v-model="password"
                 v-validate="'required'"
                 name="password"
          >
          <img
              @click.stop="showPasswordToggle()"
              class="eye" :src="require(`../../public/custom/images/eye-${isShowPassword ? 'on' : 'off'}.svg`)"
              alt="Eye image"
          >
        </div>
        <div class="password-error-block">{{ errors.first('password') }}</div>
        <div class="otp-wrapper">
          <input type="text"
                 :class="{'invalid': errors.has('otp')}"
                 class="otp-input"
                 :placeholder="$t('message.signIn.otpPlaceholder')"
                 v-model="otp"
                 name="otp"
                 v-validate="'min:4'"
          >
          <div class="send-otp-block" @click="getOtpCode()" :class="{'send-otp-disabled': isSendOtpCodeDisabled}">
            {{ $t('message.signIn.sendCode') }}
          </div>
        </div>
        <div class="otp-error-block">
          <div class="otp-error-text-block">{{ errors.first('otp') }}</div>
        </div>
        <div class="btn-block">
          <base-button
              :text="$t('message.signIn.signInBtn')"
              :disabled="isSignInBtnDisabled"
              :is-loading="isSignInBtnLoading"
              @click="signIn()"
          />
        </div>
        <div class="sign-up-block">
          <span @click="goToPasswordRecovery()" class="sign-up-link">{{ $t('message.signIn.forgotPassword') }}</span>
          <div class="border-block"></div>
          <span @click="$router.push({ name: 'msi' })" class="sign-up-link">{{ $t('message.signIn.msi') }}</span>
        </div>
        <div class="sign-up-block-mobile">
          <span @click="$router.push({ name: 'msi' })" class="sign-up-link-mobile">{{ $t('message.signIn.msi') }}</span>
          <span @click="goToPasswordRecovery()" class="sign-up-link-mobile">{{
              $t('message.signIn.forgotPassword')
            }}</span>
        </div>
      </div>
      <div class="sign-in-block img-block"></div>
    </div>
    <base-modal
        v-if="isBlockByBiometry"
    >
      <template #header>
        <div class="modal-header">
          <img class="attention-icon" src="../../public/custom/images/error-info-circle.svg" alt="Attention">
        </div>
      </template>
      <template #main>
        <div class="modal-content">
          <h3 class="title">{{ $t('message.signIn.blockModal.blocked.title') }}</h3>
          <i18n path="message.signIn.blockModal.blocked.text" tag="p" class="description">
            <router-link to="/documents" class="link">{{ $t('message.signIn.blockModal.blocked.link_1') }}</router-link>
            <router-link to="/documents" class="link">{{ $t('message.signIn.blockModal.blocked.link_2') }}</router-link>
            <span class="bold">{{ $t('message.signIn.blockModal.blocked.main') }}</span>
          </i18n>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-center items-end grow">
          <base-button
              :style="{height: '48px', fontSize: '16px'}"
              :text="'Закрыть'"
              @click="isBlockByBiometry = false"
          />
        </div>
      </template>
    </base-modal>
    <base-modal
        v-if="isSimpleBlock"
    >
      <template #header>
        <div class="modal-header">
          <img class="attention-icon" src="../../public/custom/images/error-info-circle.svg" alt="Attention">
        </div>
      </template>
      <template #main>
        <div class="modal-content">
          <p>{{ $t('error.user_does_not_allow_to_signin') }}</p>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-center items-end grow">
          <base-button
              :style="{height: '48px', fontSize: '16px'}"
              :text="'Закрыть'"
              @click="isSimpleBlock = false"
          />
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import BaseButton from '../components/base/BaseButton.vue'
import AuthService from '@/services/Auth'
import { settings } from '@/services/Settings'
import BaseModal from '@/components/base/BaseModal.vue'
import { StorageKey } from '@/models'

export default {
  name: 'SignIn',
  components: {
    BaseButton,
    BaseModal
  },
  data() {
    return {
      login: '',
      password: '',
      otp: '',
      isShowPassword: false,
      isSignInBtnLoading: false,
      isBlockByBiometry: false,
      isSimpleBlock: false
    }
  },
  created() {
    const { isBlocked } = this.$route.params
    const { isSimpleBlocked } = this.$route.params

    if (isSimpleBlocked) {
      this.isSimpleBlock = true
    }

    if (isBlocked) {
      this.isBlockByBiometry = true
    }
    const isAuthorized = !!localStorage.getItem(StorageKey.ACCESS)

    if (isAuthorized) {
      this.$router.push({ name: 'profile' })
    }
  },
  methods: {
    showPasswordToggle() {
      this.isShowPassword = !this.isShowPassword
    },
    async getOtpCode() {
      if (!this.isSendOtpCodeDisabled) {
        try {
          await AuthService.getSignInOtp(this.login, this.password)
          this.$toast.success(this.$t('message.signIn.otpToastMsg'))
        } catch (e) {
          if (e.message === 'user_blocked_by_biometry') {
            this.isBlockByBiometry = true
          } else if (e.message === 'user_blocked') {
            this.isSimpleBlock = true
          }
        }
      }
    },
    goToPasswordRecovery() {
      this.$router.push({ name: 'PasswordRecovery' })
    },
    async signIn() {
      try {
        if (!this.isSignInBtnDisabled) {
          await this.$store.dispatch('signIn', {
            phone: '+' + this.login,
            password: this.password,
            otp: this.otp
          })
        }
      } catch (e) {
        if (e.message === 'user_blocked_by_biometry') {
          this.isBlockByBiometry = true
        } else if (e.message === 'user_blocked') {
          this.isSimpleBlock = true
        }
      }
    },
    goToMain() {
      this.$router.push({ name: 'main' })
    }
  },
  computed: {
    isSignInBtnDisabled() {
      return this.isEmptyFields || this.isHasFieldsErrors
    },
    isHasFieldsErrors() {
      return this.errors.items.length > 0
    },
    isEmptyFields() {
      return !this.login || !this.password || !this.otp
    },
    isSendOtpCodeDisabled() {
      return this.login === '375' || this.errors.has('password') || this.errors.has('msisdn') || !this.password
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/mixins';

.sign-in-wrapper {
  @include base-page-wrapper;
  height: auto;
  padding: 69px 0;
  min-height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  position: relative;
  background: var(--sign-in-wrapper-bgc);
}

.back-btn-container {
  position: absolute;
  top: 3%;
  left: 5%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back-img {
  margin-right: 4px;
  height: 18px;
  width: 18px;
}

.back-btn-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  color: white;
}

.sing-in-container {
  @include base-sign-in-container;

  background: var(--sign-in-container-bgc);
  box-shadow: var(--sign-in-container-box-shadow);
}

.sign-in-block {
  width: 50%;
  height: 100%;
}

.logo {
  width: 150px;
  height: 31px;
  margin-bottom: 10%;
  cursor: pointer;
}

.title {
  margin-bottom: 5%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: var(--sign-in-title-color);
}

.img-block {
  background-image: url('../../public/custom/images/Frame.png');
  background-position: center;
  background-size: cover;
  border-radius: 0 20px 20px 0;
}

.form-block {
  padding: 80px 0 80px 80px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.login-wrapper,
.password-wrapper,
.otp-wrapper {
  height: 41px;
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-wrapper,
.password-wrapper {
  border-bottom: 2px solid var(--sign-in-input-wrapper-underline);
}

.login-error-block,
.password-error-block,
.otp-error-block {
  height: 40px;
  width: 70%;
  white-space: pre-line;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--error-message-color);
}

.otp-error-block {
  margin-bottom: 20px;
}

.login-input,
.password-input,
.otp-input {
  height: calc(100% - 2px);
  width: 100%;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: #333333;

  &:focus {
    border: none;
    outline: none;
  }
}

.otp-input {
  width: 45%;
  border-bottom: 2px solid var(--sign-in-input-wrapper-underline);

  &:focus {
    border-bottom: 2px solid var(--sign-in-input-wrapper-underline);
  }
}

.send-otp-block {
  height: calc(100% - 2px);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 45%;
  text-align: right;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  cursor: pointer;
  color: #7D7D7D;

  &:not(.send-otp-disabled):hover {
    text-decoration: underline;
  }
}

.send-otp-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.otp-error-text-block {
  width: 45%;
}

.password-input {
  width: 80%;
}

.password-input::placeholder {
  font-family: 'Open Sans', sans-serif;
}

.password-visible {
  font-family: 'Open Sans', sans-serif;
}

.eye {
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.invalid {
  border-bottom: 2px solid var(--sign-in-input-wrapper-error-underline);

  &:focus {
    border-bottom: 2px solid var(--sign-in-input-wrapper-error-underline);
  }
}

.btn-block {
  height: 65px;
  width: 70%;
  margin-bottom: 30px;
}

.sign-up-block {
  width: 70%;
  height: 26px;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  text-decoration-line: underline;
  color: var(--sign-up-block-color);
}

.sign-up-block-mobile {
  display: none;
  flex-direction: column;
  align-items: center;
}

.border-block {
  margin: 0 15px;
  border-right: 1px solid var(--sign-up-block-color);
}

.sign-up-link {
  cursor: pointer;
  color: var(--sign-up-block-color);
}

.sign-up-link-mobile {
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  color: #878787;
  cursor: pointer;
  text-decoration: underline;
}

.sign-up-link-mobile:first-of-type {
  margin-bottom: 20px;
}

.modal-header {
  //@include modal-close-block;
  position: relative;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;

  .attention-icon {
    width: 96px;
    height: 96px;
  }
}

.modal-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  margin-bottom: 44px;
  max-width: 440px;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 0;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    white-space: pre-line;

    .link {
      font-weight: 600;
      color: #1A8D48;
      text-decoration: underline;
    }

    .bold {
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 1550px) {
  .sign-up-block {
    font-size: 17px;
  }

  .title {
    font-size: 27px;
  }
}

@media screen and (max-width: 1290px) {
  .login-wrapper,
  .password-wrapper,
  .otp-wrapper,
  .btn-block,
  .sign-up-block {
    width: 90%;
  }

  .form-block {
    padding: 50px 0 50px 50px;
  }

  .sing-in-container {
    height: 670px;
  }
}

@media screen and (max-width: 1020px) {
  .img-block {
    display: none;
  }

  .login-wrapper,
  .password-wrapper,
  .otp-wrapper,
  .btn-block,
  .sign-up-block {
    width: 100%;
  }

  .sign-in-block {
    width: 100%;
  }

  .form-block {
    padding: 5%;
  }
}

@media screen and (max-width: 600px) {
  .title,
  .logo {
    margin-bottom: 40px;
  }

  .form-block {
    padding-top: 10%;
  }

  .login-wrapper,
  .password-wrapper,
  .otp-input {
    border: none;
    -webkit-box-shadow: 0 2px 0 0 #CDCDCD;
    -moz-box-shadow: 0 2px 0 0 #CDCDCD;
    box-shadow: 0 2px 0 0 #CDCDCD;
  }
}

@media screen and (max-width: 550px) {
  .sign-up-block {
    display: none;
  }

  .sign-up-block-mobile {
    display: flex;
  }
}

@media screen and (max-width: 450px) {
  .sign-up-block {
    justify-content: space-between;
  }

  .sign-up-link {
    font-size: 16px;
  }

  .btn-block {
    height: 60px;
  }

  .send-otp-block {
    font-size: 18px;
  }
}

@media screen and (max-width: 400px) {
  .sign-up-link {
    font-size: 14px;
  }

  .send-otp-block {
    font-size: 16px;
  }
}
</style>
