
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import TradeService from '@/services/Trade'
import DocsUploader from '@/components/common/DocsUploader.vue'
import BaseModal from '@/components/base/BaseModal.vue'

export default defineComponent({
  name: 'LegalAction',
  computed: {
    ...mapGetters(['profile'])
  },
  components: { BaseModal, DocsUploader, BaseCheckbox },
  data() {
    return {
      confirmLegalActionRequest: false,
      code: null,
      uuid: null,
      legal_action_id: null,
      pdfSource: null,
      loading: false,
      bill_file: null,
      borrower_response_file: null,
      showInfo: false,
      legal_action: null
    }
  },
  async created() {
    const { id } = this.$route.params
    if (id) {
      try {
        const blob = await TradeService.getLegalActionDocument(id)
        this.pdfSource = URL.createObjectURL(blob)
        this.isLoading = false
      } catch (e) {

      }

      try {
        this.legal_action = await TradeService.getLegalAction(id)
        this.showInfo = this.legal_action.is_claim_repaid
      } catch (e) {

      }
    }
  },
  methods: {
    async request() {
      try {
        const { id } = this.$route.params
        const { uuid } = await TradeService.legalActionSignRequest(id)
        this.$toast.success(this.$t('legal_action.request.code_was_sent'))
        this.uuid = uuid
        this.legal_action_id = id
      } catch (e) {
        const code = e.message
        this.loading = false
        if (code === 'legal_action_is_not_actual') {
          this.$router.push({ name: 'profile' })
        }
      }
    },
    async confirm() {
      const payload = new FormData()
      payload.append('uuid', this.uuid)
      payload.append('code', this.code)
      if (this.bill_file) {
        payload.append('bill_file', this.bill_file)
      }
      if (this.borrower_response_file) {
        payload.append('borrower_response_file', this.borrower_response_file)
      }
      this.loading = true
      try {
        await TradeService.legalActionSignCheckCode(this.legal_action_id, payload)
        this.confirmLegalActionRequest = false
        this.$toast.success(this.$t('legal_action.request.legal_action_created'))
        this.clear()
        this.loading = false
        this.$router.push({ name: 'profile' })
      } catch (e) {
      }
    },
    clear() {
      this.legal_action_id = null
      this.uuid = null
      this.code = null
    },
    addBillFile(file: File) {
      this.bill_file = file
    },
    addBorrowerResponseFile(file: File) {
      this.borrower_response_file = file
    }
  }
})
