import axios from './axios'
import { LoanRequest, PagedResult } from '@/models'

export default class OffersService {
  static createOffer(payload: Pick<LoanRequest, 'amount' | 'period_days' | 'interest_rate' | 'rating' | 'loans_count'>) {
    return axios.post('/loans/lend_request/', payload)
  }

  static getLenderOffersList1() {
    return axios.get('/loans/lend_request/')
  }

  static getBorrowOffersList() {
    return axios.get('/loans/borrow_request/')
  }

  static updateOffersList(params) {
    return axios.get(`/offer/unauthorized-list/?status=${params.status}&page=${params.page}`)
  }

  static getLenderOffersList(payload): Promise<PagedResult<LoanRequest>> {
    const params = { page_size: 10, ...payload }
    return axios.get('/loans/lend_request/', { params })
  }

  static changeLenderRequestStatus(loanRequest: LoanRequest, status: 'draft' | 'active'): Promise<void> {
    return axios.put(`/loans/lend_request/${loanRequest.id}/set_${status}/`)
  }

  static changeBorrowRequestStatus(loanRequest: LoanRequest, status: 'draft' | 'active'): Promise<void> {
    return axios.put(`/loans/borrow_request/${loanRequest.id}/set_${status}/`)
  }

  static removeBorrowRequestStatus(id: number): Promise<void> {
    return axios.put(`/loans/borrow_request/${id}/cancel/`)
  }
}
