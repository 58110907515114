import { Trade } from '@/models/trade.class'
import { Utils } from '@/helpers/utils'
import TradeService from '@/services/Trade'
import mapTradesFn = Utils.mapTradesFn
import { TradeStatus } from '@/models'

type TradesFilters = {
  ordering: number,
  due_date_after: string,
  due_date_before: string
}

interface ArchiveTradeState {
  trades: Trade[]
  filters: TradesFilters
  page: number
  page_size: number
  has_next: boolean
  count: number
}

const state: ArchiveTradeState = {
  trades: null,
  filters: {
    ordering: -1,
    due_date_after: undefined,
    due_date_before: undefined
  },
  page: 1,
  page_size: 10,
  has_next: false,
  count: null
}

const actions = {
  async get(context) {
    const {
      page,
      page_size,
      filters
    } = context.state
    const offset = new Date().getTimezoneOffset() / 60
    const requestFilters = { ...filters }
    if (filters.due_date_after) {
      requestFilters.due_date_after = new Date((filters.due_date_after as Date).getTime());
      (requestFilters.due_date_after as Date).setHours(0 - offset)
    }
    if (filters.due_date_before) {
      requestFilters.due_date_before = new Date((filters.due_date_before as Date).getTime());
      (requestFilters.due_date_before as Date).setHours(23 - offset)
    }
    requestFilters.ordering = filters.ordering > 0 ? 'due_date' : '-due_date'
    const data = await TradeService.getTrades({
      page,
      page_size,
      only_my: 1,
      status: [TradeStatus.PROCESSED],
      ...requestFilters
    })
    context.commit('setTrades', data.results)
    context.commit('setHasNext', Boolean(data.pagination.next))
    context.commit('setCount', data.pagination.count)
  },
  setPage(context, page) {
    context.commit('setPage', page)
    context.dispatch('get')
  },
  resetPage(context) {
    context.commit('setPage', 1)
    context.dispatch('get')
  },
  async changeOrder(context) {
    context.commit('changeOrder')
    await context.dispatch('get')
  },
  async applyFilters(context, payload) {
    context.commit('setPage', 1)
    context.commit('setFilters', payload)
    await context.dispatch('get')
  },
  async resetFilters(context) {
    context.commit('resetFilters')
    await context.dispatch('get')
  }
}

const mutations = {
  setTrades(state: ArchiveTradeState, payload: Trade[]) {
    state.trades = payload
  },
  setHasNext(state: ArchiveTradeState, payload: boolean) {
    state.has_next = payload
  },
  setPage(state: ArchiveTradeState, page) {
    state.page = page
  },
  setCount(state: ArchiveTradeState, count) {
    state.count = count
  },
  changeOrder(state: ArchiveTradeState, order: number) {
    state.filters.ordering = state.filters.ordering > 0 ? -1 : 1
  },
  setFilters(state: ArchiveTradeState, filters: Omit<TradesFilters, 'ordering'>) {
    state.filters = {
      ...filters,
      ordering: state.filters.ordering
    }
  },
  resetFilters(state: ArchiveTradeState) {
    state.filters = {
      ordering: -1,
      due_date_after: undefined,
      due_date_before: undefined
    }
  }
}

const getters = {
  tradeList(state: ArchiveTradeState): Trade[] {
    return (state.trades || []).map(mapTradesFn)
  },
  filters(state: ArchiveTradeState): TradesFilters {
    return state.filters
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
