
import BaseButton from '../../components/base/BaseButton.vue'
import BaseSpinner from '../../components/base/BaseSpinner.vue'
import Card from './Card.vue'
import { Card as CardType, CardCheckStatusEnum, OtpAction } from '@/models'
import { mapGetters } from 'vuex'
import BaseModal from '../../components/base/BaseModal.vue'
import CardsService from '@/services/Cards'
import AddSecondaryButton from '@/components/common/AddSecondaryButton.vue'
import { settings } from '@/services/Settings'
import { PaymentProviderEnum } from '@/models/paymentProvider'
import OtpModal from '@/views/Settings/components/OtpModal.vue'
import AuthService from '@/services/Auth'

declare class BeGateway {
  constructor(params: any);

  createWidget(): void
}

enum NoticeModalEnum {
  NONE = 'none',
  SET_DEFAULT = 'set_default',
  VERIFY = 'verify',
  REMOVE = 'remove'
}

export default {
  name: 'Cards',
  components: {
    AddSecondaryButton,
    BaseButton,
    BaseSpinner,
    Card,
    BaseModal
  },
  async created() {
    this.checkStatus()
    try {
      this.paymentProvider = await CardsService.getPaymentProvider()
    } catch {
    }
    try {
      await this.$store.dispatch('getCardsList')
      this.isLoading = false
    } catch (error) {
      this.isLoading = false
    }
  },
  data() {
    return {
      isLoading: true,
      isAddBtnLoading: false,
      isShowDeleteCardModal: false,
      cardToSetDefault: CardType,
      cardToRemove: CardType,
      cardToVerifyBalance: CardType,
      currentNoticeModal: NoticeModalEnum.NONE,
      isCardActionProcessing: false,
      paymentProvider: PaymentProviderEnum.INTERVALE,
      pollInterval: null,
      PaymentProviderEnum,
      CardCheckStatusEnum
    }
  },
  methods: {
    async addNewCard() {
      this.$store.dispatch('modal/openModal', {
        component: OtpModal,
        props: {
          sendOtp: async() => {
            await AuthService.getOtpCode(this.profile.phone, OtpAction.ADD_BANK_CARD)
          },
          onSubmit: this.requestAddNewCard.bind(this),
          phone: this.profile.phone
        },
        disableClose: true,
        onClose: async() => {
          await this.$store.dispatch('getCardsList')
        }
      })
    },
    async requestAddNewCard(otp: string) {
      try {
        this.isAddBtnLoading = true
        const res = await CardsService.addNewCard(otp)
        if (typeof res === 'object') {
          if (res.provider === 'be_paid') {
            this.payment(res.token)
          }
        } else {
          window.location.href = res
        }
      } catch (error) {
        this.isAddBtnLoading = false
        throw new Error(error)
      }
    },
    payment(token: string) {
      const handler = async(status) => {
        // возможные значения status
        // successful - операция успешна
        // failed - операция не успешна
        // pending - ожидаем результат/подтверждение операции
        // redirected - пользователь отправлен на внешнюю платежную систему
        // error - ошибка (в параметрах/сети и тд)
        // null - виджет закрыли без запуска оплаты
        try {
          switch (status) {
            case 'successful':
              await this.$store.dispatch('getCardsList')
              this.$toast.success('Карта успешно добавлена')
              break
            case 'failed':
            case null:
            case 'error':
              this.$toast.error('Не удалось добавить карту')
              break
          }
          this.isAddBtnLoading = false
        } catch {
          this.isAddBtnLoading = false
          this.$toast.error('Не удалось добавить карту')
        }
      }

      const params = {
        checkout_url: `${settings.bePaidUrl}`,
        token,
        closeWidget: handler
      }

      new BeGateway(params).createWidget()
    },
    closeNoticeModal() {
      this.currentNoticeModal = NoticeModalEnum.NONE
      this.cardToSetDefault = null
      this.cardToRemove = null
      this.cardToVerifyBalance = null
    },
    openDeleteCardModal(card: CardType) {
      this.cardToRemove = card
      this.currentNoticeModal = NoticeModalEnum.REMOVE
    },
    openSetDefaultModal(card: CardType) {
      this.cardToSetDefault = card
      this.currentNoticeModal = NoticeModalEnum.SET_DEFAULT
    },
    openVerifyBalanceModal(card: CardType) {
      this.cardToVerifyBalance = card
      this.currentNoticeModal = NoticeModalEnum.VERIFY
    },
    async confirmAction() {
      this.isCardActionProcessing = true
      switch (this.currentNoticeModal) {
        case NoticeModalEnum.SET_DEFAULT:
          await this.setDefault()
          break
        case NoticeModalEnum.VERIFY:
          await this.refillBalance()
          break
        case NoticeModalEnum.REMOVE:
          await this.deleteCard()
          break
      }
      this.isCardActionProcessing = false
      this.currentNoticeModal = NoticeModalEnum.NONE
    },
    async deleteCard() {
      await this.$store.dispatch('deleteCard', this.cardToRemove.id)
    },
    async setDefault() {
      try {
        await this.$store.dispatch('setDefaultCard', this.cardToSetDefault.id)
        this.$toast.success(this.$t('message.cardsPage.success_set_default', { cardNumber: this.$options.filters.cardPan(this.cardToSetDefault.card_number).slice(-9) }))
      } catch (e) {
        this.$toast.error(this.$t('message.cardsPage.failed_set_default'))
      }
    },
    async refillBalance() {
      await this.$store.dispatch('refillBalance', this.cardToVerifyBalance.id)
    },
    clearUrl() {
      const query = Object.assign({}, this.$route.query)
      delete query.add_card_status
      this.$router.replace({ query })
    },
    checkStatus() {
      const status = this.$route.query.add_card_status
      if (status && status !== 'OK') {
        this.$router.push('cards')
        // this.$store.dispatch('setErrorWithCallback', {
        //   message: this.$t(`error.${status}`),
        //   callback: this.clearUrl.bind(this)
        // })
      }
    },
    pollCardsList() {
      this.pollInterval = setInterval(async() => {
        try {
          await this.$store.dispatch('getCardsList')
        } catch {
          clearInterval(this.pollInterval)
        }
      }, 2000)
    }
  },
  computed: {
    ...mapGetters(['cardsList', 'profile']),
    isCardsListEmpty() {
      return this.cardsList.results.length === 0
    },
    defaultCard() {
      return this.cardsList.results.find(card => card.is_default)
    },
    otherCards() {
      return this.cardsList.results.filter(card => !card.is_default)
    },
    noticeText() {
      switch (this.currentNoticeModal) {
        case NoticeModalEnum.SET_DEFAULT:
          return this.$t('message.cardsPage.notice.text.set_default', { cardNumber: this.$options.filters.cardPan(this.cardToSetDefault.card_number) })
        case NoticeModalEnum.VERIFY:
          return this.$t('message.cardsPage.notice.text.verify_balance')
        case NoticeModalEnum.REMOVE:
          return this.$t('message.cardsPage.notice.text.delete')
        default:
          return 'Вы уверены, что хотите подтвердить операцию?'
      }
    },
    noticeTitle() {
      switch (this.currentNoticeModal) {
        case NoticeModalEnum.SET_DEFAULT:
          return this.$t('message.cardsPage.notice.title.set_default')
        case NoticeModalEnum.VERIFY:
          return this.$t('message.cardsPage.notice.title.verify_balance')
        case NoticeModalEnum.REMOVE:
          return this.$t('message.cardsPage.notice.title.delete')
        default:
          return 'Вы уверены, что хотите подтвердить операцию?'
      }
    },
    NoticeModalEnum() {
      return NoticeModalEnum
    }
  },
  watch: {
    cardsList(val) {
      const isNeedToPoll = val.results.some((card: CardType) => card.system_check?.status === this.CardCheckStatusEnum.PENDING)
      if (isNeedToPoll) {
        clearInterval(this.pollInterval)
        this.pollCardsList()
      } else {
        clearInterval(this.pollInterval)
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.pollInterval)
  }
}
