
import { defineComponent } from 'vue'
import { ServiceDocuments } from '@/services/ServiceDocuments'
import { DocumentTypeEnum, ServiceDocument } from '@/models/api/service-document'
import { documentsCategories } from '@/constants/constants'
import { Utils } from '@/helpers/utils'

type ComponentData = {
  document: ServiceDocument
}

export default defineComponent({
  name: 'DocumentLink',
  props: {
    category: {
      type: String,
      required: true,
      validator(value: string) {
        return documentsCategories.includes(value)
      }
    },
    docType: {
      type: String,
      required: false,
      default: DocumentTypeEnum.ACTUAL,
      validator(value: DocumentTypeEnum) {
        return [DocumentTypeEnum.ACTUAL, DocumentTypeEnum.PENDING, DocumentTypeEnum.ARCHIVE].includes(value)
      }
    }
  },
  data(): ComponentData {
    return {
      document: null
    }
  },
  async created() {
    try {
      const response = await ServiceDocuments.getServiceDocuments({
        category: this.category,
        doc_type: this.docType
      })
      this.document = response.results[0]
    } catch {
      console.log('No document by that query')
    }
  },
  methods: {
    async downloadDocument() {
      try {
        const content = await ServiceDocuments.downloadServiceDocument(this.document.id)
        Utils.downloadBlob(content, this.document.category_name)
      } catch {
        console.log('Can not download document')
      }
    }
  }
})
