import { render, staticRenderFns } from "./GetLoan.vue?vue&type=template&id=254a8c48&scoped=true&"
import script from "./GetLoan.vue?vue&type=script&lang=js&"
export * from "./GetLoan.vue?vue&type=script&lang=js&"
import style0 from "./GetLoan.vue?vue&type=style&index=0&id=254a8c48&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254a8c48",
  null
  
)

export default component.exports