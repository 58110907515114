<template>
  <div class="input-wrapper">
    <div class="input-label" :class="{'with-notice': isHaveNotice}">
      <div class="label-text" :class="{ required: required }">{{ label }}</div>
      <div v-if="isHaveNotice" class="notice">
        ?
        <div class="notice-msg">
          {{ noticeMessage }}
          <img
            class="notice-arrow"
            src="../../../public/custom/images/notice-arrow.svg"
            alt="Notice arrow"
          />
        </div>
      </div>
    </div>
    <div class="input-block" :class="{ invalid: errors.has(validateRules) }">
      <textarea
        class="input"
        type="text"
        v-model="inputValue"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :name="name"
        @input="$emit('input', inputValue)"
        @paste="$emit('input', inputValue)"
        @maska="$emit('input', $event.target.dataset.maskRawValue)"
        v-validate="validateRules"
        v-maska="maskPattern"
        :disabled="disabled"
      />
    </div>
    <div class="error-block"><div>{{ errorMessage }}</div><span class="counter" v-if="showCounter && maxLength">{{inputValue.length}}/{{maxLength}}</span></div>
  </div>
</template>

<script>
import VueI18n from 'vue-i18n'
export default {
  name: 'BaseTextareaInput',
  props: {
    label: [String, VueI18n.TranslateResult],
    isHaveNotice: {
      type: Boolean,
      default: false
    },
    noticeMessage: String,
    defaultValue: [String, undefined],
    placeholder: [String, VueI18n.TranslateResult],
    maxLength: Number,
    name: String,
    validateRules: String,
    maskPattern: String,
    disabled: Boolean,
    required: {
      type: Boolean,
      default: false
    },
    showCounter: Boolean
  },
  data() {
    return {
      inputValue: '' || this.defaultValue
    }
  },
  computed: {
    errorMessage() {
      return this.errors.first(this.validateRules)
    }
  },
  watch: {
    defaultValue(value) {
      this.inputValue = value
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
    @include base-input-wrapper;
}

.input-label {
    @include base-input-label;
}

.with-notice {
  display: flex;
}

.mt1 {
    margin-top: 1px;
}

.notice {
    @include base-input-notice;
    margin-left: 5px;

    &:hover {
        .notice-msg {
            display: block;
        }
    }
}

.input-block {
    @include base-input-block;
    height: 86px;
}

.input {
    @include base-input;
    resize: none;
    padding-top: 16px;
}

.invalid {
    border-color: var(--base-input-invalid-border-bgc);
}

.error-block {
  @include base-input-error-block;
  display: flex;
  justify-content: space-between;
}

.notice-msg {
    @include base-notice-msg;
}

.notice-arrow {
    position: absolute;
    bottom: -3px;
    left: 11px;
}

.counter {
  color: var(--error-modal-message-color);
}
</style>
