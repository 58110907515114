<template>
  <base-button :is-loading="isLoading" :disabled="disabled" color="secondary" :text="text"
               @mouseover="isHover = true" @mouseleave="isHover = false" @click="onClick">
    <template #icon>
      <img v-if="!isHover" src="../../../public/custom/images/plus-green.svg" alt="plus-icon">
      <img v-else src="../../../public/custom/images/plus-white.svg" alt="plus-icon">
    </template>
  </base-button>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue'
import VueI18n from 'vue-i18n'

export default {
  components: { BaseButton },
  name: 'AddSecondaryButton',
  props: {
    text: [String, VueI18n.TranslateResult],
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    spinnerColor: {
      type: String
    }
  },
  data() {
    return {
      isHover: false
    }
  },
  methods: {
    onClick() {
      if (!this.loading) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss">

</style>
