
import { mapGetters } from 'vuex'
import BaseTextInput from '../../components/base/BaseTextInput.vue'
import BaseButton from '../../components/base/BaseButton.vue'
import BaseSpinner from '../../components/base/BaseSpinner.vue'
import AgreementService from '@/services/Agreement'
import { ConsentType } from '@/models'
import BaseModal from '@/components/base/BaseModal.vue'

export default {
  name: 'ScoringAgreement',
  components: {
    BaseModal,
    BaseTextInput,
    BaseButton,
    BaseSpinner
  },
  data() {
    return {
      isAgreeWithServiceAgreement: false,
      message_id: '',
      otp: '',
      isSignBtnLoading: false,
      agreementId: '',
      isLoading: true,
      successModal: false
    }
  },
  async created() {
    try {
      const { id } = await AgreementService.createAgreement(ConsentType.BANK_REPORT)
      this.agreementId = id
      this.isLoading = false
    } catch (error) {
      this.isLoading = false
      this.$router.push({ name: 'profile' })
    }
  },
  methods: {
    checkboxHandler() {
      if (!this.isSignBtnLoading) {
        this.isAgreeWithServiceAgreement = !this.isAgreeWithServiceAgreement
      }
    },
    async getOtpCode() {
      if (!this.isSignBtnLoading) {
        try {
          await AgreementService.getAgreementOtpCode(this.agreementId)

          this.$toast.success(this.$t('message.signIn.otpToastMsg'))
        } catch (error) {
          this.$router.push({ name: 'profile' })
        }
      }
    },
    async signAgreement() {
      this.isSignBtnLoading = true
      try {
        await AgreementService.signAgreement(this.agreementId, this.otp)
        this.successModal = true
        this.isSignBtnLoading = false
      } catch (error) {
        this.isSignBtnLoading = false
      }
    },
    closeModal() {
      this.successModal = false
      this.$router.push({ name: 'profile' })
    }
  },
  computed: {
    ...mapGetters(['profile']),
    userLastName() {
      return this.profile?.last_name
    },
    userFirstName() {
      return this.profile?.first_name
    },
    userMiddleName() {
      return this.profile?.middle_name
    },
    userCitizenship() {
      return this.profile?.citizenship
    },
    userSex() {
      return this.profile?.sex === 'm' ? 'Мужской' : 'Женский'
    },
    userIdNumber() {
      return this.profile?.id_number
    },
    userBirthDate() {
      const [year, month, day] = this.profile?.date_of_birth.split('-')
      return `${day}.${month}.${year}`
    },
    isHasFieldsErrors() {
      return this.errors.items.length > 0
    },
    isHasEmptyFields() {
      return !this.otp
    },
    isSignBtnDisabled() {
      return this.isHasFieldsErrors || this.isHasEmptyFields || !this.isAgreeWithServiceAgreement || this.isSignBtnLoading
    }
  }
}
