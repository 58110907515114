<template>
  <div class="msi-wrapper">
    <div class="back-btn-container" @click="goToMain()">
      <img src="../../../public/custom/images/back-arrow.svg" alt="Back arrow" class="back-img">
      <span class="back-btn-text">{{ $t('message.passwordRecovery.backBtn') }}</span>
    </div>
    <div class="msi-container">
      <div class="msi-block">
        <div class="note-block">
          {{ $t('message.msi.note') }}
        </div>
        <div class="btns-container">
          <div class="btn-block">
            <base-button
              class="cancel-btn"
              :text="$t('message.msi.cancelBtn')"
              @click="goToMain()"
            />
          </div>
          <div class="btn-block">
            <base-button
              :is-loading="isMsiLinkLoading"
              class="reg-btn"
              :text="$t('message.msi.regBtn')"
              @click="goToMsi()"
            />
          </div>
        </div>
      </div>
      <img class="msi-img-top" src="../../../public/custom/images/msi-img-second.png" alt="Msi image">
      <img class="msi-img-bottom" src="../../../public/custom/images/msi-img-first.png" alt="Msi image">
    </div>
  </div>
</template>

<script>
import BaseButton from '../../components/base/BaseButton.vue'
import MsiService from '@/services/Msi'

export default {
  components: { BaseButton },
  name: 'SignUp',
  data() {
    return {
      isMsiLinkLoading: false
    }
  },
  methods: {
    async goToMsi() {
      try {
        this.isMsiLinkLoading = true
        const { url } = await MsiService.getMsiSignUpLink()
        window.location.href = url
      } catch (e) {
        console.log('Something went wrong')
      }
    },
    goToMain() {
      this.$router.push({ name: 'main' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/mixins';

.msi-wrapper {
  @include base-page-wrapper;

  height: auto;
  padding: 69px 0;
  min-height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  position: relative;
  background-color: var(--msi-bgc);
}

.back-btn-container {
  position: absolute;
  top: 3%;
  left: 5%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back-img {
  margin-right: 4px;
  height: 18px;
  width: 18px;
}

.back-btn-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  color: var(--password-recovery-back-btn-color);
}

.msi-container {
  @include base-sign-in-container;

  background: var(--msi-container-bgc);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.btns-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn-block {
  width: 250px;
  height: 60px;
}

.btn-block:first-of-type {
  margin-right: 20px;
}

.reg-btn {
  background-color: var(--msi-reg-btn-bgc);
  color: var(--msi-reg-btn-color);
  cursor: pointer;
}

.cancel-btn {
  background: #FFFFFF;
  border: 2px solid #F49332;
  color: #F49332;
}

.note-block {
  margin-bottom: 40px;
  width: 100%;
  text-align: center;
  text-wrap: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  color: var(--msi-note-color);
}

.msi-img-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.msi-img-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.msi-block {
  width: 700px;
  max-width: 95%;
  padding: 50px 58px;
  box-sizing: border-box;
  height: auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 20px;
}

@media screen and (max-width: 600px) {
  .msi-block {
    padding: 50px 30px;
  }
}
</style>
