<template>
  <div class="radio-btn" @click.stop="buttonHandler()" :class="{'disabled': disabled}">
    <div v-if="checked" class="checked"></div>
  </div>
</template>

<script>
export default {
  name: 'BaseRadioButton',
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    buttonHandler() {
      if (!this.disabled) return this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-btn {
  @include base-radio-btn;
}

.checked {
  @include base-radio-btn-checked;
}

.disabled {
  opacity: 0.5;
  cursor: default;
}
</style>
