import contactDataValidationMixin from './contactDataValidationMixin'
import mainDataValidationMixin from './mainDataValidationMixin'
import idDataValidationMixin from './idDataValidationMixin'
import addresDataValidationMixin from './addresDataValidationMixin'
import jobDataValidationMixin from './jobDataValidationMixin'
import offersValidationMixin from './offersValidationMixin'
import extraDataValidationMixin from './extraDataValidationMixin'

export default {
  mixins: [contactDataValidationMixin, mainDataValidationMixin, idDataValidationMixin,
    addresDataValidationMixin, jobDataValidationMixin, offersValidationMixin, extraDataValidationMixin]
}
