
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BaseTable',
  props: {
    columns: Array,
    items: Array,
    actions: Array,
    itemClickable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleRowClick(item) {
      this.$emit('itemClicked', item)
    }
  }
})
