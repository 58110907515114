import axios from './axios'
import { PagedResult, Transaction, TransactionInfo } from '@/models'

export class TransactionService {
  static getLending(params: Record<string, string | number>): Promise<PagedResult<Transaction>> {
    return axios.get('/transaction/', { params })
  }

  static getTransactions(params: Record<string, string | number>): Promise<TransactionInfo[]> {
    return axios.get(`/trade/${params.trade_id}/detailed_transactions/`, { params })
  }
}
