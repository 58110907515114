<template>
  <div class="legislation-page-wrapper">
    <static-pages-header @mobileMenuToggle="mobileMenuToggle"/>

    <section class="heading-container" :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}">
      <div class="heading-block">
        <div class="heading">
          {{ $t('message.mainPage.heading.title') }}
        </div>
        <div class="heading-desc">
          <a class="heading-link" href="/static/registryInclude.pdf" target="_blank">
            {{ $t('message.mainPage.heading.desc.link') }}
          </a>
          {{ $t('message.mainPage.heading.desc.textStart') }}
          <br>
          {{ $t('message.mainPage.heading.desc.textEnd') }}
          <br>
          {{ $t('message.mainPage.heading.desc.textNotice') }}
        </div>
        <div class="heading-btn-block">
          <base-button
            :text="$t('message.mainPage.heading.btn')"
            @click="signUp()"
            v-if="!isAuthorized"
          />
        </div>
      </div>
      <img class="heading-img" src="../../../public/custom/images/main-page/heading-img.png" alt="Heading-img">
    </section>

    <section class="legislation-container">
      <div class="legislation-title">
        {{ $t('message.legislation.title') }}
      </div>
      <div class="legislation-block">
        <div class="legislation-item">
          {{ $t('message.legislation.firstRow') }}
        </div>
        <div class="legislation-item">
          <div>{{ $t('message.legislation.secondRow.firstText') }}</div>
          <a href="https://www.nbrb.by/legislation/onlineborrowingservices?p=obs" target="_blank">{{ $t('message.legislation.secondRow.link') }}</a>
          <div>{{ $t('message.legislation.secondRow.secondText') }}</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import StaticPagesHeader from '../../components/common/StaticPagesHeader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Legislation',
  components: {
    StaticPagesHeader
  },
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  methods: {
    mobileMenuToggle(value) {
      this.isMobileMenuOpen = value
    },
    signUp() {
      this.$router.push({ name: 'msi' })
    }
  },
  computed: {
    ...mapGetters(['profile']),
    isAuthorized() {
      return this.profile?.role
    }
  }
}
</script>

<style lang="scss" scoped>
  .legislation-page-wrapper {
    @include base-page-wrapper;
    @include static-heading-container;
  }

  .legislation-container {
    @include base-page-container;

    padding: 100px 50px 140px 50px;
    box-sizing: border-box;
    flex-direction: column;
  }

  .heading-link {
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #41BF7B;
    text-decoration: none;
  }

  .legislation-title {
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 40px;
    line-height: 125%;
    color: #181A1B;
  }

  .legislation-block {
    width: 810px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  .legislation-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-size: 22px;
    line-height: 135%;
    color: #121212;

    a {
      color: #2F80ED;
    }
  }

  .legislation-item:first-of-type {
    margin-bottom: 40px;
  }

  @media screen and (max-width: 1530px) {
    .heading-desc {
      br {
        display: none;
      }
    }
  }

  @media screen and (max-width: 820px) {
    .legislation-container {
      padding: 50px 0 140px 0;
    }

    .legislation-title {
      font-size: 30px;
    }

    .legislation-item {
      font-size: 22px;
    }
  }

  @media screen and (max-width: 670px) {
    .legislation-page-wrapper .heading {
      text-align: left;
    }
  }
</style>
