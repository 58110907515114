import axios from './axios'
import { ConsentType } from '@/models'

export default class AgreementService {
  static createAgreement(type: ConsentType): Promise<{type: ConsentType, id: number}> {
    return axios.post('/consent/', { type })
  }

  static getAgreementOtpCode(id: number): Promise<{ otp: string }> {
    return axios.get(`/consent/${id}/send-otp/`)
  }

  static signAgreement(id, otp) {
    return axios.post(`/consent/${id}/sign-by-otp/`, { otp })
  }

  static loadConsent(id: number): Promise<Blob> {
    return axios.get(`/consent/${id}/download/`, { responseType: 'blob' })
  }

  static loadConsentExample(): Promise<Blob> {
    return axios.get('/user/personal-data-consent-example-preview/', { responseType: 'blob' })
  }
}
