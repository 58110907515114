export interface DocumentCategory {
  name: string
  code_name: string
  ordering: number
  is_active: boolean
}

export interface ServiceDocument {
  id: number
  relevance_from: string
  relevance_until: string
  category: string
  category_name: string
  file_size: number
  original_filename: string
}

export enum DocumentTypeEnum {
  ACTUAL = 'actual',
  PENDING = 'pending',
  ARCHIVE = 'archive'
}
