
import BaseButton from '../components/base/BaseButton.vue'
import { mapGetters, mapState } from 'vuex'
import offerItem from './Main/offerItem.vue'
import StaticPagesHeader from '../components/common/StaticPagesHeader.vue'
import { ConsentType, StorageKey, UserStatus } from '@/models'
import { Roles } from '@/models/roles.enum'
import Calculator from '@/components/common/Calculator.vue'
import Filters from './Main/filters.vue'

export default {
  name: 'Main',
  components: {
    Calculator,
    BaseButton,
    offerItem,
    StaticPagesHeader,
    Filters
  },
  async created() {
    await this.$store.dispatch('homeBorrower/get')
    await this.$store.dispatch('homeLender/get')
    if (this.profile.role === Roles.BORROWER) {
      await this.$store.dispatch('borrowTrade/get')
    }
    this.isLoading = false
  },
  beforeDestroy() {
    this.$store.dispatch('homeBorrower/resetPage')
    this.$store.dispatch('homeLender/resetPage')
  },
  data() {
    return {
      page: 0,
      fullOffersList: [],
      targetLoansList: [
        {
          title: 'first',
          numbers: {
            first: 100,
            second: 7,
            third: 125
          },
          img: 'pig'
        },
        {
          title: 'second',
          numbers: {
            first: 250,
            second: 10,
            third: 300
          },
          img: 'calendar'
        },
        {
          title: 'third',
          numbers: {
            first: 300,
            second: 30,
            third: 400
          },
          img: 'universal'
        },
        {
          title: 'fourth',
          numbers: {
            first: 700,
            second: 14,
            third: 850
          },
          img: 'bags'
        },
        {
          title: 'fifth',
          numbers: {
            first: 450,
            second: 21,
            third: 550
          },
          img: 'repair'
        },
        {
          title: 'sixth',
          numbers: {
            first: 750,
            second: 45,
            third: 1000
          },
          img: 'vocations'
        }
      ],
      isLoading: true,
      isMobileMenuOpen: false,
      isOfferBtnLoading: false
    }
  },
  methods: {
    signUp() {
      this.$router.push({ name: 'msi' })
    },
    async signOut() {
      await localStorage.removeItem(StorageKey.ACCESS)
      await this.$store.dispatch('purgeProfile')
    },
    mobileMenuToggle(value) {
      this.isMobileMenuOpen = value
    },
    async showModeLenderRequests() {
      this.$store.dispatch('homeLender/nextPage')
    },
    async showModeBorrowerRequests() {
      this.$store.dispatch('homeBorrower/nextPage')
    },
    applyFiltersLender(filters) {
      this.$store.dispatch('homeLender/setFilters', { ...filters })
    },
    clearFiltersLender() {
      this.$store.dispatch('homeLender/setFilters', {})
    },
    applyFiltersBorrower(filters) {
      this.$store.dispatch('homeBorrower/setFilters', { ...filters })
    },
    clearFiltersBorrower() {
      this.$store.dispatch('homeBorrower/setFilters', {})
    }
  },
  computed: {
    UserStatus() {
      return UserStatus
    },
    Roles() {
      return Roles
    },
    ...mapState(['homeLender']),
    ...mapState(['homeBorrower']),
    ...mapState(['borrowTrade']),
    ...mapGetters('borrowTrade', ['tradeList']),
    ...mapGetters(['profile']),
    ...mapGetters('homeLender', ['hasResultsWithFiltersLender']),
    ...mapGetters('homeBorrower', ['hasResultsWithFiltersBorrewer']),
    isAuthorized() {
      return this.profile?.role
    },
    parsedUserName() {
      return this.isAuthorized ? (this.profile?.first_name?.slice(0, 1) + this.profile?.last_name.slice(0, 1)) : ''
    },
    hasBankConsent() {
      return this.profile.consents.some((consent) => consent.type === ConsentType.BANK_REPORT && consent.status === true)
    }
  }
}
