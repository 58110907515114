export enum BiometryStatus {
  NOT_REQUIRED = 'not_required',
  PASSED = 'passed',
  REQUIRED = 'required',
  FAILED = 'failed'
}

// used for displaying biometry result modals
export enum VerificationStatus {
  NONE = 'none',
  ERROR = 'error',
  SUCCESS = 'success',
  CONFIRM = 'confirmation'
}
