
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { DocumentTypeEnum } from '@/models/api/service-document'
import DownloadDocument from '@/views/ServiceDocuments/components/DownloadDocument.vue'
import ExtendableTable from '@/components/base/ExtendableTable.vue'

export default {
  name: 'ActiveDocuments',
  components: {
    DownloadDocument,
    ExtendableTable
  },
  data() {
    return {
      columns: [
        {
          name: 'category',
          localization: 'Наименование документа'
        },
        {
          name: 'relevance_from',
          localization: 'Дата вступления в силу документа'
        }
      ],
      DocumentTypeEnum
    }
  },
  async created() {
    this.setDocType(DocumentTypeEnum.ACTUAL)
    await this.get()
  },
  methods: {
    ...mapActions('documents', ['get']),
    ...mapMutations('documents', ['setDocType'])
  },
  computed: {
    ...mapGetters('documents', ['documents', 'isDocsLoading'])
  }
}
