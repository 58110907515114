import axios from '@/services/axios'

export default class AnnouncementService {
  static get(): Promise<Array<{ id: number, body: string }>> {
    return axios.get('/user/announcements/')
  }

  static viewAnnouncement(id: number): Promise<void> {
    return axios.patch('/user/viewed-announcement/', { id })
  }
}
