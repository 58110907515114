<template>
  <div class="third-step-container">
    <div class="title">{{ $t('message.passwordRecovery.thirdStep.title') }}</div>
    <div class="break-line"></div>

    <div class="step-block">
      <div class="input-block">
        <base-password-input
          :label="$t('message.passwordRecovery.thirdStep.password.label')"
          :name="'password'"
          :validate-rules="'required|one_upper_latin|one_number|one_lower_latin|one_special_symbol|min_length'"
          v-model="new_password1"
        />
      </div>
    </div>

    <div class="text-xs mb-3 whitespace-pre self-baseline">
      <p class="text-gray-600">{{ $t('password.note') }}</p>
    </div>
    <div class="step-block">
      <div class="input-block">
        <base-password-input
          :label="$t('message.passwordRecovery.thirdStep.repeatPassword.label')"
          :name="'passwordConfirmation'"
          :validate-rules="`passwordConfirmation:${this.new_password1}`"
          v-model="new_password2"
        />
      </div>
    </div>
    <div class="btns-block">
      <div class="btn-container">
        <BaseButton
          :text="$t('message.passwordRecovery.thirdStep.saveBtn')"
          :disabled="isSaveBtnDisabled"
          @click="setPassword()"
        />
      </div>

      <div class="btn-container">
        <BaseButton
          :text="$t('message.passwordRecovery.thirdStep.cancelBtn')"
          class="cancel-btn"
          @click="$router.push({ name: 'SignIn' })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BasePasswordInput from '../../components/base/BasePasswordInput.vue'
import BaseButton from '../../components/base/BaseButton.vue'

export default {
  name: 'ThirdStep',
  components: {
    BasePasswordInput,
    BaseButton
  },
  data() {
    return {
      new_password1: '',
      new_password2: ''
    }
  },
  methods: {
    setPassword() {
      this.$emit('setPassword', this.new_password1)
    }
  },
  computed: {
    isSaveBtnDisabled() {
      return this.isHasFildsErrors || this.isHasEmptyFields
    },
    isHasFildsErrors() {
      return this.errors.items.length > 0
    },
    isHasEmptyFields() {
      return !this.new_password1 || !this.new_password2
    }
  }
}
</script>

<style lang="scss"
  scoped>
.third-step-container {
  @include base-recovery-password-step-container;
  height: 539px;
}

.title {
  @include recovery-password-title;
  margin-bottom: 25px;
}

.break-line {
  @include recovery-password-break-line;
}

.step-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.input-block {
  width: 418px;
}

.btns-block {
  width: 100%;
  margin-top: 60px;
  display: flex;
}

.btn-container {
  width: 150px;
  height: 54px;
}

.btn-container {
  margin-right: 20px;
}

.cancel-btn {
  border: 1px solid var(--base-cancel-btn-border);
  background-color: var(--base-cancel-btn-bgc);
  color: var(--base-cancel-btn-color);
}

@media screen and (max-width: 750px) {
  .third-step-container {
    padding: 0 5%;
  }

  .input-block {
    max-width: 100%;
  }
}
</style>
