import { MsiResponse } from '@/models'

function getSignUpDisabledFields(data: MsiResponse) {
  return {
    phone: !!data.phone,
    email: !!data.email,
    last_name: !!data.last_name,
    first_name: !!data.first_name,
    middle_name: !!data.middle_name,
    date_of_birth: !!data.date_of_birth,
    sex: !!data.sex,
    birthplace: !!data.birthplace,
    citizenship: !!data.citizenship,
    id_number: !!data.id_number,
    document_type: !!data.document_type,
    series: !!data.series,
    number: !!data.number,
    date_of_issue: !!data.date_of_issue,
    date_of_expiry: !!data.date_of_expiry,
    issued_by: !!data.issued_by,
    address_str: !!data.address_str
  }
}

export {
  getSignUpDisabledFields
}
