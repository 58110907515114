

export default {
  name: 'BasePagination',
  emits: ['changePage'],
  props: {
    pagination: {
      type: Object,
      required: true
    },
    page: {
      type: Number,
      required: true
    }
  },
  methods: {
    changePage(page: number) {
      this.$emit('changePage', page)
    }
  },
  computed: {
    pagingValue() {
      const start = !(this.page - 1) ? this.page : (this.page - 1) * this.pagination?.page_size
      const end = this.page * this.pagination?.page_size > this.pagination?.count ? this.pagination?.count : this.page * this.pagination?.page_size
      return `${start} - ${end} из ${this.pagination?.count}`
    }
  }
}
