

import { ServiceDocument, DocumentTypeEnum } from '@/models/api/service-document'
import { ServiceDocuments } from '@/services/ServiceDocuments'
import { Utils, addZeroTimezone } from '@/helpers/utils'
import { PropType } from 'vue'

export default {
  name: 'DownloadDocument',
  props: {
    document: {
      type: Object,
      required: true
    },
    type: {
      type: String as PropType<DocumentTypeEnum>,
      required: true,
      validator: function(value: DocumentTypeEnum) {
        return [DocumentTypeEnum.ACTUAL, DocumentTypeEnum.PENDING, DocumentTypeEnum.ARCHIVE].includes(value)
      }
    }
  },
  data() {
    return {
      DocumentTypeEnum
    }
  },
  methods: {
    async downloadDocument() {
      try {
        const content = await ServiceDocuments.downloadServiceDocument(this.document.id)
        let documentName = this.document.category_name
        const dateToParse = this.type === DocumentTypeEnum.ARCHIVE ? this.document.relevance_until : this.document.relevance_from
        const [YYYY, MM, DD] = new Date(addZeroTimezone(dateToParse)).toLocaleDateString('ru').split('.')

        switch (this.type) {
          case DocumentTypeEnum.PENDING:
            documentName += ` (ред от ${DD}_${MM}_${YYYY})`
            break
          case DocumentTypeEnum.ARCHIVE:
            documentName += ` (ред до ${DD}_${MM}_${YYYY})`
            break
        }
        Utils.downloadBlob(content, documentName)
      } catch {
      }
    }
  }
}
