export interface IModalProps {
  component: any
  props?: object
  onClose?: (...args) => void
  disableClose?: boolean
}

const state = { component: null, props: {} }

const actions = {
  openModal(context, payload: IModalProps) {
    const { props, component, onClose, disableClose } = payload
    context.commit('setComponent', component)
    context.commit('setProps', props)
    context.commit('setOnClose', onClose)
    context.commit('setDisableClose', disableClose)
  },
  closeModal(context, ...args) {
    if (context.state.onClose) {
      context.state.onClose(...args)
    }
    context.commit('clearState')
  }
}

const mutations = {
  setComponent(state, component) {
    state.component = component
  },
  setProps(state, props) {
    state.props = props
  },
  setOnClose(state, props) {
    state.onClose = props
  },
  setDisableClose(state, disableClose) {
    state.disableClose = disableClose
  },
  clearState(state) {
    state.component = null
    state.props = null
    state.onClose = null
    state.disableClose = null
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
