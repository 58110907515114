import { Trade } from '@/models/trade.class'
import { Utils } from '@/helpers/utils'
import TradeService from '@/services/Trade'
import mapTradesFn = Utils.mapTradesFn
import { PaginationState, TradeStatus } from '@/models'
import { PagedState } from '@/models/paged-state.inteface'
import { lenderTradeRequestParams } from '@/constants/constants'
import { applyFilters, resetFilters } from '@/store/shared/actions'

type LenderTradeFilters = {
  status: TradeStatus
}

type LenderTradeState = PagedState<{
  trades: Trade[],
  filters: LenderTradeFilters,
  pagination: PaginationState
}>

const state: LenderTradeState = {
  trades: null,
  page: 1,
  page_size: 10,
  pagination: {} as PaginationState,
  filters: {
    status: null
  }
}

const actions = {
  async get(context) {
    const {
      page,
      page_size,
      filters
    } = context.state
    const requestParams = filters.status
      ? { status: filters.status === TradeStatus.OVERDUE ? [TradeStatus.OVERDUE, TradeStatus.MAX_OVERDUE] : filters.status }
      : lenderTradeRequestParams
    const {
      results,
      pagination
    } = await TradeService.getTrades({
      page,
      page_size,
      only_my: 1,
      ...requestParams
    })
    context.commit('setTrades', results)
    context.commit('setPagination', {
      ...pagination,
      page,
      page_size
    })
  },
  setPage(context, page) {
    context.commit('setPage', page || context.state.page)
    context.dispatch('get')
  },
  resetPage(context) {
    context.commit('setPage', 1)
    context.commit('resetFilters')
  },
  applyFilters,
  resetFilters
}

const mutations = {
  setTrades(state: LenderTradeState, payload: Trade[]) {
    state.trades = payload
  },
  setPage(state: LenderTradeState, page) {
    state.page = page
  },
  setPagination(state: LenderTradeState, pagination: PaginationState): void {
    state.pagination = pagination
  },
  setFilters(state: LenderTradeState, filters: LenderTradeFilters) {
    state.filters = { ...state.filters, ...filters }
  },
  resetFilters(state: LenderTradeState) {
    state.filters = {
      status: null
    }
  }
}

const getters = {
  tradeList(state: LenderTradeState): Trade[] {
    return state.trades.map(mapTradesFn)
  },
  tradeFilters(state: LenderTradeState): LenderTradeFilters {
    return state.filters
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
