
import { defineComponent } from 'vue'
import BaseSpinner from '@/components/base/BaseSpinner.vue'

const isHighlighting = () => {
  return window.getSelection && window.getSelection().type === 'Range'
}

export default defineComponent({
  name: 'ExtendableTable',
  components: { BaseSpinner },
  emits: ['itemClicked'],
  props: {
    columns: Array,
    items: Array,
    actions: Array,
    initialSorting: Object,
    expandedRows: Array,
    itemClickable: {
      type: Boolean,
      default: false
    },
    isDataLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      sorting: null
    }
  },
  watch: {
    initialSorting(value) {
      if (!this.sorting && value) {
        this.sorting = value
      }
    }
  },
  methods: {
    handleRowClick(item) {
      if (isHighlighting()) {
        return
      }
      this.$emit('itemClicked', item)
    },
    isActive(id) {
      return this.expandedRows.includes(id)
    },
    handleSorting(column: string) {
      if (column) {
        if (!this.sorting) {
          this.sorting = {
            column,
            direction: 'asc'
          }
        } else {
          if (this.sorting.column === column) {
            if (this.sorting.direction === 'asc') {
              this.sorting.direction = 'desc'
            } else {
              this.sorting = null
            }
          } else {
            this.sorting = {
              column,
              direction: 'asc'
            }
          }
        }
        this.$emit('sortingChange', this.sorting)
      }
    }
  }
})
