
import BaseButton from '../../components/base/BaseButton.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Settings',
  components: {
    BaseButton
  },
  methods: {
    requestUpdateMsiUUID(): void {
      this.$store.dispatch('requestMsiUpdate')
    }
  },
  computed: {
    ...mapGetters([
      'profile'
    ])
  }
}
