import { ClaimLoanerStatus, ClaimStatus } from '@/models'

export class LegalAction {
  trade_id: number
  created_at: string
  due_main_depth: number
  due_interest_depth: number
  penalty_amount: number
  status: ClaimStatus
  status_loaner: ClaimLoanerStatus
  status_court: ClaimLoanerStatus
  file: string
  signed_at: string
  id: number
  from_user_first_name: string
  from_user_middle_name: string
  from_user_last_name: string
  to_user_first_name: string
  to_user_middle_name: string
  to_user_last_name: string
  sum: string
  is_claim_repaid: boolean
}
