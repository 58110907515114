<template>
  <div class="questions-page-wrapper">
    <static-pages-header @mobileMenuToggle="mobileMenuToggle"/>
    <div class="questions-container" :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}">
      <div class="questions-title">{{ $t('message.questionsPage.title') }}</div>

      <div class="question-item-wrapper">
        <div class="question-text-block" @click="questionToggle('fourth')">
          <div class="question">
            {{ $t('message.questionsPage.items.fourth.title') }}
          </div>
          <img
            :src="require(`../../../../public/custom/images/question-cross-${questionItemsStatus.fourth ? 'open' : 'close'}.svg`)"
            alt="Question image">
        </div>
        <div :class="{'open': questionItemsStatus.fourth}" class="answer-text-block">
          <ul class="no-bullets">
            <li>
              Пользователи и Клиенты Сервиса онлайн-заимствования «Капуста – онлайн-
              заимствования», размещенного в сети Интернет на сайте www.kapusta.by
              <b>(далее — Сервис)</b> самостоятельно несут <b>ответственность за хранение и
              использование</b> своих логинов, паролей и подтверждающих кодов из СМС при
              совершении юридически значимых действий по доступу в межбанковскую
              систему идентификации (МСИ), а также на Сервисе.
            </li>
            <li>
              <b>Запрещено передавать третьим лицам</b> (даже если вам представляются
              работниками Сервиса/компании) <b>логины, пароли и подтверждающие коды
              из СМС, которые используются при совершении юридически значимых
              действий по доступу в МСИ, а также на Сервисе (kapusta.by).</b>
            </li>
          </ul>
        </div>
      </div>

      <div class="question-item-wrapper">
        <div class="question-text-block" @click="questionToggle('first')">
          <div class="question">
            {{ $t('message.questionsPage.items.first.title') }}
          </div>
          <img
            :src="require(`../../../../public/custom/images/question-cross-${questionItemsStatus.first ? 'open' : 'close'}.svg`)"
            alt="Question image">
        </div>
        <div :class="{'open': questionItemsStatus.first}" class="answer-text-block">
          <ul>
            <li>
              {{ $t('message.questionsPage.items.first.text.first.begin') }}
              <router-link class="answer-block-link" to="msi">{{
                  $t('message.questionsPage.items.first.text.first.link')
                }}
              </router-link>
              {{ $t('message.questionsPage.items.first.text.first.end') }}
            </li>
            <li>{{ $t('message.questionsPage.items.first.text.second') }}</li>
            <li>
              {{ $t('message.questionsPage.items.first.text.third.begin') }}
              <router-link class="answer-block-link" to="/">{{
                  $t('message.questionsPage.items.first.text.third.link')
                }}
              </router-link>
              {{ $t('message.questionsPage.items.first.text.third.end') }}
            </li>
            <li>{{ $t('message.questionsPage.items.first.text.fourth') }}</li>
            <li>
              {{ $t('message.questionsPage.items.first.text.fifth.begin') }}
              <b>{{ $t('message.questionsPage.items.first.text.fifth.bold') }}</b>
              {{ $t('message.questionsPage.items.first.text.fifth.middle') }}
              <a target="_blank" class="answer-block-link"
                 href="https://creditregister.by/">{{ $t('message.questionsPage.items.first.text.fifth.link') }}</a>
              {{ $t('message.questionsPage.items.first.text.fifth.end') }}
            </li>
            <li>{{ $t('message.questionsPage.items.first.text.sisxth') }}</li>
          </ul>
        </div>
      </div>

      <div class="question-item-wrapper">
        <div class="question-text-block" @click="questionToggle('second')">
          <div class="question">
            {{ $t('message.questionsPage.items.second.title') }}
          </div>
          <img
            :src="require(`../../../../public/custom/images/question-cross-${questionItemsStatus.second ? 'open' : 'close'}.svg`)"
            alt="Question image">
        </div>
        <div :class="{'open': questionItemsStatus.second}" class="answer-text-block">
          <ul>
            <li>
              {{ $t('message.questionsPage.items.second.text.first.begin') }}
              <router-link class="answer-block-link" to="msi">
                {{ $t('message.questionsPage.items.second.text.first.link') }}
              </router-link>
              {{ $t('message.questionsPage.items.second.text.first.end') }}
            </li>
            <li>{{ $t('message.questionsPage.items.second.text.second') }}</li>
            <li>
              {{ $t('message.questionsPage.items.second.text.third.begin') }}
              <b>{{ $t('message.questionsPage.items.second.text.third.bold') }}</b>
              {{ $t('message.questionsPage.items.second.text.third.end') }}
            </li>
            <li>{{ $t('message.questionsPage.items.second.text.fourth') }}</li>
            <li>
              {{ $t('message.questionsPage.items.second.text.fifth.begin') }}
              <span class="answer-block-important">{{
                  $t('message.questionsPage.items.second.text.fifth.important')
                }}</span>
              {{ $t('message.questionsPage.items.second.text.fifth.end') }}
            </li>
            <li>
              {{ $t('message.questionsPage.items.second.text.sixth.begin') }}
            </li>
            <li>
              {{ $t('message.questionsPage.items.second.text.new') }}
            </li>
            <li>
              {{ $t('message.questionsPage.items.second.text.seventh.begin') }}
              <b>{{ $t('message.questionsPage.items.second.text.seventh.bold') }}</b>
              {{ $t('message.questionsPage.items.second.text.seventh.end') }}
            </li>
            <li>{{ $t('message.questionsPage.items.second.text.eighth') }}</li>
            <li>
              {{ $t('message.questionsPage.items.second.text.nineth.begin') }}
              <b>{{ $t('message.questionsPage.items.second.text.nineth.bold') }}</b>
              {{ $t('message.questionsPage.items.second.text.nineth.end') }}
            </li>
          </ul>
        </div>
      </div>

      <div class="question-item-wrapper">
        <div class="question-text-block" @click="questionToggle('third')">
          <div class="question">
            {{ $t('message.questionsPage.items.third.title') }}
          </div>
          <img
            :src="require(`../../../../public/custom/images/question-cross-${questionItemsStatus.third ? 'open' : 'close'}.svg`)"
            alt="Question image">
        </div>
        <div :class="{'open': questionItemsStatus.third}" class="answer-text-block">
          <ul class="no-bullets">
            <li>
              {{ $t('message.questionsPage.items.third.text.first.begin') }}
              <router-link class="answer-block-link" to="documents">
                {{ $t('message.questionsPage.items.third.text.first.link') }}
              </router-link>
            </li>
            <li>
              {{ $t('message.questionsPage.items.third.text.second.begin') }}
              <b>{{ $t('message.questionsPage.items.third.text.second.bold') }}</b>
            </li>
            <li>
              {{ $t('message.questionsPage.items.third.text.third.begin') }}
              <b>{{ $t('message.questionsPage.items.third.text.third.bold') }}</b>
              {{ $t('message.questionsPage.items.third.text.third.end') }}
            </li>
            <li>
              {{ $t('message.questionsPage.items.third.text.fourth.begin') }}
              <b>{{ $t('message.questionsPage.items.third.text.fourth.bold') }}</b>
              {{ $t('message.questionsPage.items.third.text.fourth.end') }}
            </li>
            <li>
              {{ $t('message.questionsPage.items.third.text.fifth.begin') }}
              <b>{{ $t('message.questionsPage.items.third.text.fifth.bold') }}</b>
              {{ $t('message.questionsPage.items.third.text.fifth.end') }}
            </li>
            <li>
              {{ $t('message.questionsPage.items.third.text.sixth.begin') }}
              <b>{{ $t('message.questionsPage.items.third.text.sixth.bold') }}</b>
              {{ $t('message.questionsPage.items.third.text.sixth.end') }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StaticPagesHeader from '../../../components/common/StaticPagesHeader.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Questions',
  components: {
    StaticPagesHeader
  },
  data() {
    return {
      isMobileMenuOpen: false,
      questionItemsStatus: {
        first: false,
        second: false,
        third: false,
        fourth: false
      }
    }
  },
  methods: {
    mobileMenuToggle(value) {
      this.isMobileMenuOpen = value
    },
    signUp() {
      this.$router.push({ name: 'msi' })
    },
    questionToggle(item) {
      this.questionItemsStatus[item] = !this.questionItemsStatus[item]
    }
  },
  computed: {
    ...mapGetters(['profile']),
    isAuthorized() {
      return this.profile?.role
    }
  }
}
</script>

<style lang="scss" scoped>
.questions-page-wrapper {
  @include base-page-wrapper;
  @include static-heading-container;
}

.questions-container {
  @include base-page-container;

  padding: 70px 50px 140px 50px;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-direction: column;
}

.questions-title {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 40px;
  line-height: 125%;
  color: #181A1B;
}

.question-item-wrapper {
  border-top: 1px solid #CDCDCD;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.2s ease;
}

.question-text-block {
  padding: 31px 0 30px 0;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  color: #121212;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.answer-text-block {
  width: 100%;
  padding-right: 20%;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  color: #333333;
  transition: all 0.2s ease;
  max-height: 0;
  overflow: hidden;
  cursor: default;
  transition: all 0.25s ease;

  ul {
    margin-top: 0;
    margin-bottom: 0;

    li {
      margin-bottom: 10px;
    }
  }

  b {
    color: #121212;
    font-weight: 700;
  }
}

.open {
  padding-bottom: 30px;
  max-height: 650px;
  box-sizing: border-box;
}

.answer-block-link {
  color: #2F80ED;
}

.answer-block-important {
  color: #EB5757;
}

.no-bullets {
  padding-left: 0;

  li {
    list-style-type: none;
  }
}

.fade-enter-active, .fade-leave-active {
  height: 0;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  height: auto;
}

@media screen and (max-width: 1560px) {

  .answer-text-block {
    font-size: calc(8px + 10 * (100vw / 1920));
  }

  .answer-text-block {
    padding-right: 10%;
  }
}

@media screen and (max-width: 940px) {
  .questions-container {
    padding: 50px 0 140px 0;
  }

  .questions-title {
    font-size: 30px;
  }

  .question-text-block {
    font-size: 18px;
    padding: 24px 0 23px 0;
  }

  .answer-text-block {
    font-size: 13px;
  }
}

</style>
