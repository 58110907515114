
import { mapActions, mapState } from 'vuex'

export default {
  component: 'BaseModalPortal',
  methods: {
    ...mapActions('modal', {
      closeModal: 'closeModal'
    })
  },
  computed: {
    ...mapState(['modal'])
  }
}
