<!-- TODO deprecated replace BaseSelect with BaseSelectNew -->

<template>
  <div class="input-wrapper">
    <div class="input-label">
      <div class="label-text" :class="{ required: required }">{{ label }}</div>
    </div>
    <div
      class="select-block"
      @click.stop="showOptions()"
      v-click-outside="hideOptions"
      :class="{'disabled': disabled, selected: !!inputValue, active: isShowOptions }"
    >
      <input
        type="text"
        class="input"
        :disabled="disabled"
        v-model="inputValue"
        :placeholder="placeholder || null"
        :name="name"
        autocomplete="off"
        :class="{'input-disabled': disabled }"
      />
      <img
        src="../../../public/custom/images/arrow-down.svg"
        alt="Arrow down image"
      />
      <div class="dropdown-menu" :class="{'scrollable': optionsList.length > 3}" v-if="isShowOptions">
        <div
          v-for="option of optionsList"
          :key="option"
          class="option"
          @click.stop="chooseOption(option)"
        >
          {{ $t(`${dictionaryPath || ''}${option}`) }}
        </div>
      </div>
    </div>
    <div class="error-block"></div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import VueI18n from 'vue-i18n'

export default {
  name: 'BaseSelect',
  props: {
    label: [String, VueI18n.TranslateResult],
    placeholder: [String, VueI18n.TranslateResult],
    defaultValue: [String, Number],
    optionsList: Array,
    dictionaryPath: String,
    disabled: Boolean,
    name: String,
    required: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      inputValue: this.defaultValue ? this.$t(`${this.dictionaryPath || ''}${this.defaultValue}`) : '',
      isShowOptions: false
    }
  },
  methods: {
    showOptions() {
      if (!this.disabled) {
        this.isShowOptions = true
      }
    },
    chooseOption(value) {
      this.inputValue = this.$t(`${this.dictionaryPath || ''}${value}`)
      this.hideOptions()
      this.$emit('input', value)
    },
    hideOptions() {
      this.isShowOptions = false
    }
  },
  watch: {
    defaultValue(value) {
      this.inputValue = value ? this.$t(`${this.dictionaryPath || ''}${value}`) : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {
  @include base-input-wrapper;
}

.input-label {
  @include base-input-label;
}

.select-block {
  @include base-input-block;
  height: 54px;
  padding-right: 9px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: var(--base-select-bgc);

  &.selected:not(.disabled) {
    background-color: var(--base-select-selected-bgc);
    border: none;
    color: var(--base-select-selected-color);
  }

  &.active {
    border-color: var(--base-select-active-border-color);
  }
}

.input {
  @include base-input;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  caret-color: transparent;
  pointer-events:none;
}

.error-block {
  @include base-input-error-block;
}

.dropdown-menu {
  width: calc(100% + 2px);
  height: auto;
  max-height: 230px;
  overflow: auto;
  box-sizing: border-box;
  position: absolute;
  top: calc(100% + 8px);
  left: -1px;
  background-color: var(--base-option-bgc);
  border-radius: var(--base-input-border-radius);
  box-shadow: 4px 4px 12px 0 #00000014;
  border: none;
  z-index: 2;
  cursor: pointer;
}

.scrollable {
  border-radius: var(--base-input-border-radius) 0 0 var(--base-input-border-radius);
}

.option {
  height: 52px;
  padding: 12px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: var(--base-option-bgc);
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  cursor: pointer;
  border: 1px solid #0000000A;
  color: black;
}

.option:hover {
    background-color: var(--base-select-option-hover-bgc);
}

.option:first-of-type {
  border-radius: var(--base-option-bgc) var(--base-option-bgc) 0 0;
}

.option:last-of-type {
  border-radius: 0 0 var(--base-option-bgc) var(--base-option-bgc);
}

.dropdown-menu::-webkit-scrollbar {
    width: 3px;
    height: 10px;
    margin-right: 5px;
    scroll-padding-top: 5px;
}

.dropdown-menu {
    scroll-padding-top: 5px;
}

.dropdown-menu::-webkit-scrollbar-track {
    background-color: #ECEEF0;
    border-radius: 20px;
}

.dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #878787;
    border-radius: 20px;
}

.disabled {
  background-color: var(--base-input-disabled-bgc);
  cursor: default;
}

.input-disabled {
  background-color: transparent !important;
  cursor: default;
}
</style>
