import CardsService from '@/services/Cards'
import { CardCheckStatusEnum, PagedResult } from '@/models'

const state = {
  cardsList: new PagedResult()
}

const actions = {
  async getCardsList(context) {
    const list = await CardsService.getCardsList()
    context.commit('setCardsList', list)
  },
  async setDefaultCard(context, card_id) {
    await CardsService.setDefaultCard(card_id)
    await context.dispatch('getCardsList')
  },
  async deleteCard(context, card_id) {
    try {
      await CardsService.deleteCard(card_id)
      await context.dispatch('getCardsList')
    } catch (error) {
    }
  },
  async refillBalance(context, card_id) {
    try {
      await CardsService.refillBalance(card_id)
      await context.dispatch('getCardsList')
    } catch (error) {
    }
  }
}

const mutations = {
  setCardsList(state, data) {
    state.cardsList = data
  }
}

const getters = {
  cardsList(state) {
    return state.cardsList
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
