import { MsiResponse } from '@/models'

export default {
  computed: {
    isIdDataIncorrect() {
      return !(this.userData as MsiResponse).id_number ||
        !(this.userData as MsiResponse).document_type ||
        !(this.userData as MsiResponse).series ||
        !(this.userData as MsiResponse).date_of_issue ||
        !(this.userData as MsiResponse).date_of_expiry ||
        !(this.userData as MsiResponse).issued_by
    }
  }
}
