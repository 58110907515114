import { PaymentProvider, PaymentProviderEnum } from '@/models/paymentProvider'

export enum CardCheckStatusEnum {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed'
}

export type SystemCheck = {
  status: CardCheckStatusEnum
  failed_reason: string
}

export class Card {
  card_holder: string
  card_number: string
  expiry: Date
  id: number
  is_enough_balance: boolean
  is_default: boolean
  system_check: SystemCheck
  provider: Exclude<PaymentProvider, PaymentProviderEnum.BEPAID_AVAILABLE>
}
