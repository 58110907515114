<template>
  <Datepicker
      v-model="date"
      :value="value"
      :valueType="valueType"
      :format="format"
      :disabled="disabled"
      :placeholder="placeholder"
      :lang="{
      days: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
      yearFormat: 'YYYY',
      monthFormat: 'MMMM',
    }"
      :rangeSeparator="rangeSeparator"
      v-bind="$attrs"
      @input="$emit('input', date)"
  >
    <template v-slot:icon-calendar>
      <template v-if="isHasCalendarIcon">
        <img v-if="date?.length > 0 && !date?.every(v => v === null)"
             src="../../../public/custom/images/calendar-active.svg" alt="Calendar" />
        <img v-else
             src="../../../public/custom/images/calendar.svg" alt="Calendar image">
      </template>
      <svg v-else></svg>
    </template>
  </Datepicker>
</template>

<script>
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/index.css'
import VueI18n from 'vue-i18n'

export default {
  name: 'BaseDatepicker',
  components: {
    Datepicker
  },
  props: {
    value: [String, Array, Object],
    defaultValue: String,
    isHasCalendarIcon: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: [String, VueI18n.TranslateResult],
    valueType: {
      type: String,
      default() {
        return 'YYYY-MM-DD'
      }
    },
    format: {
      type: String,
      default() {
        return 'YYYY-MM-DD'
      }
    },
    required: {
      type: Boolean,
      default: true
    },
    rangeSeparator: {
      type: String,
      default: ' - '
    }
  },
  data() {
    return {
      date: '' || this.defaultValue
    }
  },
  watch: {
    defaultValue(value) {
      this.date = value
    },
    value(value) {
      this.date = value
    }
  }
}
</script>

<style lang="scss" scoped></style>
