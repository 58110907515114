
import ProfileSettings from '@/services/ProfileSettings'
import { OtpAction, UserAddress, UserProfile } from '@/models'
import { mapGetters, mapState } from 'vuex'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseSelect from '@/components/base/BaseSelect.vue'
import CONSTANTS from '../../constants/constants'
import BaseModal from '@/components/base/BaseModal.vue'
import { ValidationObserver } from 'vee-validate'
import OtpModal from '@/views/Settings/components/OtpModal.vue'

enum EmailModal {
  NONE,
  SENT,
  LIMIT
}

interface Data {
  living_address: UserAddress,
  living_address_eq_reg: boolean,
  phone: string,
  email: string,
  otp_code: string,
  codeword: string,
  code_requested: boolean,
  loading: boolean,
  emailModal: EmailModal,
  isEmailConfirming: boolean
}

export default {
  components: {
    BaseModal,
    BaseSelect,
    BaseCheckbox,
    BaseButton,
    ValidationObserver
  },
  data(): Data {
    return {
      living_address: new UserAddress(),
      living_address_eq_reg: false,
      phone: null,
      email: null,
      otp_code: null,
      codeword: null,
      code_requested: false,
      loading: false,
      emailModal: EmailModal.NONE,
      isEmailConfirming: false
    }
  },
  async created() {
    const {
      living_address,
      living_addr_eq_reg
    } = await ProfileSettings.getContactInfo()
    this.living_address = living_address
    this.living_address_eq_reg = living_addr_eq_reg
    const {
      phone,
      email,
      codeword
    } = this.profile
    this.email = email
    this.phone = phone
    this.codeword = codeword
  },
  computed: {
    CONSTANTS() {
      return CONSTANTS
    },
    ...mapGetters(['profile']),
    isInvalid() {
      return this.errors.items?.length > 0
    },
    isEmailEqualsInitialValue() {
      return this.email === this.profile.email
    },
    emailModalStatus(): typeof EmailModal {
      return EmailModal
    },
    ...mapState(['modal'])
  },
  watch: {
    profile(newValue: UserProfile) {
      const {
        phone,
        email,
        codeword
      } = newValue
      this.email = email
      this.initial_email = email
      this.phone = phone
      this.codeword = codeword
    }
  },
  methods: {
    validate() {
      return this.living_address_eq_reg ? Promise.resolve() : Promise.allSettled([
        this.$refs.country_code.$validator.validate(),
        this.$refs.locality_name.$validator.validate(),
        this.$refs.street_name.$validator.validate()
      ])
    },
    async confirmEmail() {
      try {
        const isValid = await this.$refs.emailObserver.validate()
        if (isValid && (!this.isEmailEqualsInitialValue || !this.profile.email_verified)) {
          this.isEmailConfirming = true
          await ProfileSettings.confirmEmail(this.email)
          this.emailModal = EmailModal.SENT
        }
      } catch (e) {
        if (e.message === 'too_many_attempts_to_update_email') {
          this.emailModal = EmailModal.LIMIT
        }
        if (e.message === 'new_email_invalid') {
          this.$refs.email.applyValidation('Невалидный email')
        }
      } finally {
        this.isEmailConfirming = false
      }
    },
    async onClose() {
      this.$store.dispatch('getProfile')
      const {
        living_address,
        living_addr_eq_reg
      } = await ProfileSettings.getContactInfo()
      this.living_address = living_address
      this.living_address_eq_reg = living_addr_eq_reg
      this.$store.dispatch('getProfile')
      this.loading = false
    },
    async update(): Promise<void> {
      await this.validate()
      if (this.isInvalid) {
        return
      }
      this.loading = true
      this.$store.dispatch('modal/openModal', {
        component: OtpModal,
        props: {
          sendOtp: async() => {
            await ProfileSettings.requestOtp(this.phone, OtpAction.UPDATE_PERSONAL_INFO)
          },
          onSubmit: this.request.bind(this),
          phone: this.phone
        },
        disableClose: true,
        onClose: this.onClose.bind(this)
      })
    },
    handleIsAddressTheSame() {
      this.living_address = new UserAddress()
    },
    async request(otp_code: string) {
      await ProfileSettings.updateContactInfo({
        codeword: this.codeword,
        living_address_eq_reg: this.living_address_eq_reg,
        living_address: this.living_address,
        otp_code,
        phone: this.phone
      })
    }
  }
}
