import Axios from 'axios'
import { settings, api } from '@/services/Settings'
import qs from 'qs'

const http = Axios.create({
  baseURL: `${api}${settings.apiVersionV2}`,
  headers: {
    Pragma: 'no-cache',
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  paramsSerializer: p => qs.stringify(p, { arrayFormat: 'repeat' })
})

http.interceptors.response.use(function(response) {
  return response.data
})

export default http
