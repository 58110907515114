<template>
  <div class="bid-wrapper">
    <div class="bid-container">
      <div class="title">{{ $t('message.bid.title') }}</div>
      <div class="new-bid-container">
        <div class="currency-container">
          <div class="small-container">
            <BaseSelect
              :options-list="currenciesList"
              :default-value="'BYN'"
              :dictionary-path="'message.bid.currencyList.'"
              :disabled="false"
              :label="$t('message.bid.currency.title')"
              class="bit-item"
            />
          </div>
        </div>

        <div class="amount-container">
          <div class="small-container">
            <BaseTextInput
              :name="'totalAmount'"
              :label="$t('message.bid.totalAmount.title')"
              :placeholder="$t('message.bid.totalAmount.placeholder')"
              class="bit-item"
            />
          </div>
          <div class="small-container">
            <BaseTextInput
              :name="'minAmount'"
              :label="$t('message.bid.minAmount.title')"
              :placeholder="$t('message.bid.minAmount.placeholder')"
              class="bit-item"
            />
          </div>
          <div class="small-container aliquote-container">
            <div class="aliquote-title">
              {{ $t('message.bid.aliquote.title') }}
            </div>
            <div class="radio-btns-container">
              <div class="btn-item">
                <BaseRadioButton
                  class="radio-btn"
                  :checked="newBid.multiplicity === 50"
                  @click="changeMultiplicity(50)"
                />
                <span @click="changeMultiplicity(50)" class="radio-btn-label">50</span>
              </div>
              <div class="btn-item">
                <BaseRadioButton
                  class="radio-btn"
                  :checked="newBid.multiplicity === 100"
                  @click="changeMultiplicity(100)"
                />
                <span @click="changeMultiplicity(100)" class="radio-btn-label">100</span>
              </div>
            </div>
          </div>
          <div class="small-container">
            <BaseTextInput
              :name="'percent'"
              :label="$t('message.bid.percent.title')"
              :placeholder="$t('message.bid.percent.placeholder')"
              class="bit-item"
            />
          </div>
        </div>

        <div class="terms-container">
          <div class="terms-block">
            <div class="aliquote-title terms-title">
              {{ $t('message.bid.terms.title') }}
            </div>
            <div class="datepicker-block">
              <div class="datepicker-container">
                <BaseDatepicker
                  :is-has-calendar-icon="true"
                  :format="`${this.$t('message.bid.terms.from')}: DD.MM.YYYY`"
                  :placeholder="$t('message.bid.terms.fromPlaceholder')"
                />
              </div>
              <div class="datepicker-container">
                <BaseDatepicker
                  :is-has-calendar-icon="true"
                  :format="`${this.$t('message.bid.terms.to')}: DD.MM.YYYY`"
                  :placeholder="$t('message.bid.terms.toPlaceholder')"
                />
              </div>
            </div>
          </div>
          <div class="relevance-block">
            <div class="aliquote-title terms-title">
              {{ $t('message.bid.relevance.title') }}
            </div>
            <div class="datepicker-container">
                <BaseDatepicker
                  :is-has-calendar-icon="true"
                  :format="`${this.$t('message.bid.relevance.to')}: DD.MM.YYYY`"
                  :placeholder="$t('message.bid.relevance.toPlaceholder')"
                />
              </div>
          </div>
          <div class="middle-container payment-procedure-container">
            <BaseSelect
              :options-list="paymentProceduresList"
              :default-value="'monthly'"
              :dictionary-path="'message.bid.paymentProcedure.list.'"
              :disabled="false"
              :label="$t('message.bid.paymentProcedure.title')"
              class="bit-item"
            />
          </div>
          <div class="provision-container">
            <div class="aliquote-title">
              {{ $t('message.bid.provision.title') }}
            </div>
            <div class="radio-btns-container">
              <div class="btn-item">
                <BaseRadioButton
                  class="radio-btn"
                  :checked="!newBid.provision"
                  @click="changeProvision(undefined)"
                />
                <span @click="changeProvision(undefined)" class="radio-btn-label">
                  {{ $t('message.bid.provision.no') }}
                </span>
              </div>
              <div class="btn-item">
                <BaseRadioButton
                  class="radio-btn"
                  :checked="!!newBid.provision"
                  @click="changeProvision('bank_guarantee')"
                />
                <span @click="changeProvision('bank_guarantee')" class="radio-btn-label">
                  {{ $t('message.bid.provision.yes') }}
                </span>
              </div>
            </div>
          </div>
          <div class="middle-container">
            <BaseSelect
              :options-list="provisionList"
              :default-value="'bank_guarantee'"
              :dictionary-path="'message.bid.provision.list.'"
              :disabled="false"
              :label="''"
              class="bit-item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from '../components/base/BaseSelect.vue'
import BaseTextInput from '../components/base/BaseTextInput.vue'
import BaseRadioButton from '../components/base/BaseRadioButton.vue'
import BaseDatepicker from '../components/base/BaseDatepicker.vue'
import { settings } from '@/services/Settings'

export default {
  name: 'Bid',
  components: {
    BaseSelect,
    BaseTextInput,
    BaseRadioButton,
    BaseDatepicker
  },
  data() {
    return {
      currenciesList: settings.bid.availableCurrencies,
      paymentProceduresList: settings.bid.availablePaymentProcedures,
      provisionList: settings.bid.availableProvisionList,
      newBid: {
        multiplicity: 50,
        provision: 'bank_guarantee'
      }
    }
  },
  methods: {
    changeMultiplicity(value) {
      this.newBid.multiplicity = value
    },
    changeProvision(value) {
      this.newBid.provision = value
    }
  }
}
</script>

<style lang="scss" scoped>

.bid-wrapper {
  @include page-wrapper-with-header-and-footer;

  padding-top: 40px;
  box-sizing: border-box;
  background-color: var(--bid-page-bgc);
}

.bid-container {
  @include base-page-container;

  flex-direction: column;
  color: var(--bid-text-color);
}

.title {
  @include base-page-title;

  margin-bottom: 40px;
}

.new-bid-container {
  width: 100%;
  padding: 20px;
  margin-bottom: 80px;
  box-sizing: border-box;
  background-color: var(--new-bid-container-bgc);
  display: flex;
  flex-direction: column;
}

.currency-container,
.amount-container,
.terms-container {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}

.small-container {
  width: 160px;
  margin-right: 58px;
}

.middle-container {
  width: 270px;
  display: flex;
  align-items: flex-end;
}

.aliquote-container,
.relevance-block {
  width: 184px;
  display: flex;
  flex-direction: column;
  margin-left: 22px;
  margin-right: 94px;
}

.aliquote-title {
  @include base-input-label;
  margin-bottom: 19px;
}

.terms-title {
  margin-bottom: 10px;
}

.radio-btns-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.btn-item {
  display: flex;
  align-items: center;
}

.radio-btn {
  margin-right: 10px;
}

.radio-btn-label {
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  cursor: pointer;
}

.bit-item::v-deep .select-block,
.bit-item::v-deep .option,
.bit-item::v-deep .input-block {
  height: 46px;
}

.bit-item::v-deep .input-label,
.aliquote-title {
  font-size: 16px;
  line-height: 130%;
}

.terms-block {
  width: 378px;
  margin-right: 58px;
  display: flex;
  flex-direction: column;
}

.datepicker-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.datepicker-container {
  width: 184px;
  height: 46px;
}

.payment-procedure-container {
  margin-right: 80px;
}

.provision-container {
  width: 155px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}
</style>
