import { Utils } from '@/helpers/utils'
import LoanService from '@/services/Loan'
import { LoanRequest, LoanRequestStatus } from '@/models'
import mapOfferFn = Utils.mapOfferFn
import { PagedState } from '@/models/paged-state.inteface'
import { Roles } from '@/models/roles.enum'

type OffersFilters = {
  ordering: number,
  processed_at_after: string,
  processed_at_before: string
}

type ArchiveOfferState = PagedState<{ offers: LoanRequest[], filters: OffersFilters }>

const state: ArchiveOfferState = {
  offers: null,
  filters: {
    ordering: -1,
    processed_at_after: undefined,
    processed_at_before: undefined
  },
  page: 1,
  page_size: 10,
  has_next: false,
  count: null
}

const actions = {
  async get(context) {
    const {
      page,
      page_size,
      filters
    } = context.state
    const { auth } = context.rootState
    const offset = new Date().getTimezoneOffset() / 60
    const request = auth.profile.role === Roles.LENDER ? LoanService.getLenderRequest : LoanService.getBorrowRequest
    const requestFilters = { ...filters }
    if (filters.processed_at_after) {
      requestFilters.processed_at_after = new Date((filters.processed_at_after as Date).getTime());
      (requestFilters.processed_at_after as Date).setHours(0 - offset)
    }
    if (filters.processed_at_before) {
      requestFilters.processed_at_before = new Date((filters.processed_at_before as Date).getTime());
      (requestFilters.processed_at_before as Date).setHours(23 - offset)
    }
    requestFilters.ordering = filters.ordering > 0 ? 'processed_at' : '-processed_at'
    const data = await request({
      page,
      page_size,
      only_my: 1,
      status: [LoanRequestStatus.PROCESSED, LoanRequestStatus.CANCELED],
      ...requestFilters
    })
    context.commit('setTrades', data.results)
    context.commit('setHasNext', Boolean(data.pagination.next))
    context.commit('setCount', data.pagination.count)
  },
  async setPage(context, page) {
    context.commit('setPage', page)
    await context.dispatch('get')
  },
  async resetPage(context) {
    context.commit('setPage', 1)
    await context.dispatch('get')
  },
  async changeOrder(context) {
    context.commit('changeOrder')
    await context.dispatch('get')
  },
  async applyFilters(context, payload) {
    context.commit('setPage', 1)
    context.commit('setFilters', payload)
    await context.dispatch('get')
  },
  async resetFilters(context) {
    context.commit('resetFilters')
    await context.dispatch('get')
  }
}

const mutations = {
  setTrades(state: ArchiveOfferState, payload: LoanRequest[]) {
    state.offers = payload
  },
  setHasNext(state: ArchiveOfferState, payload: boolean) {
    state.has_next = payload
  },
  setPage(state: ArchiveOfferState, page) {
    state.page = page
  },
  setCount(state: ArchiveOfferState, count) {
    state.count = count
  },
  changeOrder(state: ArchiveOfferState, order: number) {
    state.filters.ordering = state.filters.ordering > 0 ? -1 : 1
  },
  setFilters(state: ArchiveOfferState, filters: Omit<OffersFilters, 'ordering'>) {
    state.filters = { ...filters, ordering: state.filters.ordering }
  },
  resetFilters(state: ArchiveOfferState) {
    state.filters = { ordering: -1, processed_at_after: undefined, processed_at_before: undefined }
  }
}

const getters = {
  offerList(state: ArchiveOfferState): LoanRequest[] {
    return (state.offers || []).map(mapOfferFn)
  },
  filters(state: ArchiveOfferState): OffersFilters {
    return state.filters
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
