import http from '@/services/axios'

export interface LoanRequestLimits {
  MAX_LOAN_REQUEST_AMOUNT: number;
  MIN_LOAN_REQUEST_AMOUNT: number;
  MAX_LOAN_REQUEST_INTEREST_RATE: number;
  MIN_LOAN_REQUEST_INTEREST_RATE: number;
  MAX_LOAN_REQUEST_PERIOD_DAYS: number;
  MIN_LOAN_REQUEST_PERIOD_DAYS: number;
}

export interface CalculationResult {
  percent_amount: number
  total_amount: number
}

export class CalculatorService {
  static getValidations(): Promise<LoanRequestLimits> {
    return http.get('/loans/calculator/validation/')
  }

  static calculate(payload: {
    amount: string
    period_days: number
    interest_rate: number
  }): Promise<CalculationResult> {
    return http.post('/loans/calculator/calculate/', payload)
  }
}
