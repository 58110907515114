<template>
    <div class="second-step-container">
        <div class="title">{{ $t('message.passwordRecovery.secondStep.title') }}</div>
        <div class="break-line"></div>
        <div class="step-block">
            <div class="input-block">
                <BaseTextInput
                    :name="'otp'"
                    :validate-rules="'otp'"
                    :label="$t('message.passwordRecovery.secondStep.otp.label')"
                    v-model="otp"
                />
            </div>
            <div class="btn-block">
                <BaseButton
                    :text="$t('message.passwordRecovery.secondStep.otp.btn')"
                    :disabled="isBtnDisabled"
                    @click="nextStep()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BaseTextInput from '../../components/base/BaseTextInput.vue'
import BaseButton from '../../components/base/BaseButton.vue'
export default {
  name: 'SecondStep',
  components: {
    BaseTextInput,
    BaseButton
  },
  data() {
    return {
      otp: ''
    }
  },
  computed: {
    isHasFildsErrors() {
      return this.errors.items.length > 0
    },
    isHasEmptyFields() {
      return !this.otp
    },
    isBtnDisabled() {
      return this.isHasFildsErrors || this.isHasEmptyFields
    }
  },
  methods: {
    nextStep() {
      this.$emit('thirdStep', this.otp)
    }
  }
}
</script>

<style lang="scss" scoped>
.second-step-container {
    @include base-recovery-password-step-container;
}

.title {
    @include recovery-password-title;
    margin-bottom: 25px;
}

.break-line {
    @include recovery-password-break-line;
}

.step-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.input-block {
    width: 175px;
}

.btn-block {
    width: 150px;
    height: 54px;
}

@media screen and (max-width: 750px) {
    .second-step-container {
        padding: 0 5%;
    }

    .step-block {
        flex-direction: column;
        align-items: flex-start;
    }

    .input-block {
        max-width: 100%;
    }
}
</style>
