<template>
  <div class="arbitration-page-wrapper">
    <static-pages-header @mobileMenuToggle="mobileMenuToggle"/>

    <div class="arbitration-title-container" :class="{'heading-container-mobile-menu-open': isMobileMenuOpen}">
      <div class="title">
        {{ $t('message.arbitrationPage.title') }}
        <div class="desc">
          {{ $t('message.arbitrationPage.desc') }}
        </div>
      </div>
      <img src="../../../public/custom/images/justice-midle-image.png" alt="Justice image">
    </div>

    <div class="arbitration-container">
      <div class="license-container">
        <div class="license-container-data">
          {{ $t('message.arbitrationPage.license.begin') }}
          <a class="license-container-data-link" target="_blank"
             href="https://minjust.gov.by/directions/compare_coverage/registry_arbitrators/">
            {{ $t('message.arbitrationPage.license.link') }}
          </a>
          {{ $t('message.arbitrationPage.license.end') }}
        </div>
        <img class="license-image" src="../../../public/custom/images/arbitration-license.png"
             alt="Arbitration license">
      </div>

      <div class="contacts-container" id="requisites">
        <div class="data-block-title">
          {{ $t('message.arbitrationPage.contact.title') }}
        </div>
        <div class="data-block-data">
          <div class="contact-block-item">
            <div class="contact-block-item-title">
              {{ $t('message.arbitrationPage.contact.items.first.title') }}
            </div>
            <div class="contact-block-item-desc">
              <span v-for="item of $t('message.arbitrationPage.contact.items.first.data')" :key="item">
                {{ item }}
              </span>
            </div>
            <div class="contact-block-item-documents">
              <a href="/static/arbitrationTax.pdf" target="_blank" class="document-item">
                <img src="../../../public/custom/images/document-img.svg" alt="Document image">
                {{ $t('message.arbitrationPage.contact.items.first.documentFirst') }}
              </a>
              <a href="/static/arbitration.pdf" target="_blank" class="document-item">
                <img src="../../../public/custom/images/document-img.svg" alt="Document image">
                {{ $t('message.arbitrationPage.contact.items.first.documentSecond') }}
              </a>
              <a href="/static/changeLocationAlert.pdf" target="_blank" class="document-item">
                <img src="../../../public/custom/images/document-img.svg" alt="Document image">
                {{ $t('message.arbitrationPage.contact.items.first.documentThird') }}
              </a>
            </div>
          </div>

          <div class="contact-block-item">
            <div class="contact-block-item-title">
              {{ $t('message.arbitrationPage.contact.items.second.title') }}
            </div>
            <div class="contact-block-item-desc">
              {{ $t('message.arbitrationPage.contact.items.second.data') }}
            </div>
          </div>

          <div class="contact-block-item">
            <div class="contact-block-item-title">
              {{ $t('message.arbitrationPage.contact.items.third.title') }}
            </div>
            <div class="contact-block-item-desc">
              {{ $t('message.arbitrationPage.contact.items.third.data') }}
            </div>
          </div>

          <div class="contact-block-item">
            <div class="contact-block-item-title">
              {{ $t('message.arbitrationPage.contact.items.fourth.title') }}
            </div>
            <div class="contact-block-item-desc">
              <a :href="`mailto:${$t('message.arbitrationPage.contact.items.fourth.data')}`">{{
                  $t('message.arbitrationPage.contact.items.fourth.data')
                }}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="purpose-container">
        <div class="data-block-title">
          {{ $t('message.arbitrationPage.purpose.title') }}
        </div>
        <div class="data-block-data">
          <div class="contact-block-item-desc">
            <span v-for="item of $t('message.arbitrationPage.purpose.items')" :key="item">
              {{ item }}
            </span>
          </div>
        </div>
      </div>

      <div class="advantages-container">
        <div class="data-block-title">
          {{ $t('message.arbitrationPage.advantages.title') }}
        </div>
        <div class="data-block-data">
          <ul class="contact-block-item-desc">
            <li v-for="item of $t('message.arbitrationPage.advantages.items')" :key="item">{{ item }}</li>
          </ul>
        </div>
      </div>

      <div class="advices-container">
        <div class="data-block-title">
          {{ $t('message.arbitrationPage.advices.title') }}
        </div>
        <div class="data-block-data">
          <ol class="contact-block-item-desc">
            <li>{{ $t('message.arbitrationPage.advices.items.first') }}</li>
            <li>
              {{ $t('message.arbitrationPage.advices.items.second.begin') }}
              <b>{{ $t('message.arbitrationPage.advices.items.second.bold') }}</b>
              {{ $t('message.arbitrationPage.advices.items.second.end') }}
            </li>
            <li>{{ $t('message.arbitrationPage.advices.items.third') }}</li>
            <li>
              <b>{{ $t('message.arbitrationPage.advices.items.fourth.beginBold') }}</b>
              <router-link class="license-container-data-link" :to="{ path: '/arbitration', hash: '#requisites' }">
                {{ $t('message.arbitrationPage.advices.items.fourth.firstLink') }}
              </router-link>
              <b>{{ $t('message.arbitrationPage.advices.items.fourth.middleBold') }}</b>
              <a class="license-container-data-link" href="/static/arbitrationTax.pdf"
                 target="_blank">{{ $t('message.arbitrationPage.advices.items.fourth.secondLink') }}</a>
              <b>{{ $t('message.arbitrationPage.advices.items.fourth.endBold') }}</b>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StaticPagesHeader from '../../components/common/StaticPagesHeader.vue'

export default {
  name: 'Arbitration',
  components: {
    StaticPagesHeader
  },
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  methods: {
    mobileMenuToggle(value) {
      this.isMobileMenuOpen = value
    },
    signUp() {
      this.$router.push({ name: 'msi' })
    }
  }
}
</script>

<style lang="scss" scoped>
.arbitration-page-wrapper {
  @include base-page-wrapper;
  @include static-heading-container;
  scroll-behavior: smooth;
}

.arbitration-title-container {
  width: 100%;
  height: 500px;
  background: #F6FBFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 140px;
  padding-right: 160px;
  box-sizing: border-box;

  img {
    max-width: 60%;
    max-height: 428px;
    object-fit: contain;
  }
}

.title {
  font-weight: 700;
  @include adaptive-font(50, 38);
  color: #121212;
  display: flex;
  flex-direction: column;

  .desc {
    width: 430px;
    margin-top: 15px;
    font-weight: 400;
    font-size: 18px;
    line-height: 135%;
    color: #121212;
  }
}

.arbitration-container {
  @include base-page-container;

  padding: 70px 50px 0 50px;
  box-sizing: border-box;
  justify-content: flex-start;
  flex-direction: column;
}

.license-container {
  margin-bottom: 140px;
  width: 100%;
  padding: 70px 10%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 2px 2px 30px rgba(177, 179, 182, 0.2);
  border-radius: 20px;
}

.license-container-data {
  max-width: 60%;
  font-weight: 600;
  margin-right: 33px;
  @include adaptive-font(26, 16);
  @include adaptive-value("line-height", 34, 21);
  color: #121212;
}

.license-container-data-link {
  color: #2F80ED !important;
}

.license-image {
  max-width: 40%;
}

.contacts-container,
.purpose-container,
.advantages-container,
.advices-container {
  margin-bottom: 140px;
  width: calc(100% - 165px);
  display: flex;
  justify-content: space-between;
}

.data-block-title {
  width: 35%;
  font-weight: 700;
  @include adaptive-font(40, 26);
  line-height: 125%;
  color: #121212;
}

.data-block-data {
  width: 60%;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  color: #474747;
}

.data-block-item {
  margin-bottom: 10px;
}

.contact-block-item {
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contact-block-item-title {
  margin-bottom: 10px;
  font-weight: 600;
  @include adaptive-font(26, 20);

  line-height: 35px;
  color: #181A1B;
}

.contact-block-item-desc {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  @include adaptive-font(18, 16);
  line-height: 135%;
  color: #333333;

  span {
    margin-bottom: 8px;
  }

  li {
    margin-bottom: 15px;
  }

  a {
    color: #333333;
    text-decoration: none;
  }
}

.contact-block-item-documents {
  margin-top: 20px;
  display: flex;
  column-gap: 30px;
  row-gap: 10px;
}

.document-item {
  display: flex;
  align-items: center;
  color: #41BF7B;
  text-decoration: none;

  img {
    height: 34px;
    width: 34px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 1700px) {
  .arbitration-container {

    .data-block-data {

      .contact-block-item-documents {
        flex-direction: column;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .arbitration-title-container {
    padding: 0 2.5% 0 2.5%;

    img {
      max-height: 450px;
      margin-right: 0;
      max-width: 40%;
    }
  }

  .license-container {
    padding: 50px 2.5% 50px 2.5%;
  }
}

@media screen and (max-width: 890px) {
  .arbitration-title-container,
  .license-container,
  .contacts-container,
  .purpose-container,
  .advantages-container,
  .advices-container {
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    img {
      max-height: 450px;
      margin-right: 0;
      max-width: 98%;
    }
  }

  .arbitration-container {
    padding: 40px 10px 100px;

    .data-block-data {
      width: 100%;
    }

    .data-block-title {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .license-container-data {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .title {
    align-items: center;
    margin-bottom: 7px;

    .desc {
      display: flex;
      align-items: center;
      width: 339px;
      font-size: 16px;
    }
  }

  .license-container,
  .contacts-container,
  .advantages-container,
  .advices-container {
    margin-bottom: 100px;
  }

  .license-container {
    padding: 20px;
  }

  .purpose-container {
    margin-bottom: 74px;
  }
}

@media screen and (max-width: 670px) {
  .title {
    width: 100%;
    align-items: start;
  }
}
</style>
