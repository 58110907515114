
import { Card, CardCheckStatusEnum } from '@/models'
import BaseButton from '@/components/base/BaseButton.vue'

export default {
  name: 'Card',
  components: {
    BaseButton
  },
  props: {
    data: Card,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      CardCheckStatusEnum
    }
  },
  methods: {
    setDefault() {
      this.$emit('setDefault', this.data)
    },
    deleteCard() {
      this.$emit('deleteCard', this.data)
    },
    verifyBalance() {
      this.$emit('verifyBalance', this.data)
    }
  }
}
