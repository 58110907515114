<template>
  <input class="input"
    ref="inputRef"
    type="text"
  />
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'

const options = {
  currency: 'BYN',
  currencyDisplay: 'code',
  hideCurrencySymbolOnFocus: true,
  hideGroupingSeparatorOnFocus: true,
  hideNegligibleDecimalDigitsOnFocus: true,
  autoDecimalDigits: false,
  useGrouping: true,
  accountingSign: false
}

export default {
  name: 'BaseCurrency',
  props: {
    modelValue: Number
  },

  setup() {
    const { inputRef } = useCurrencyInput(options)

    return { inputRef }
  }
}
</script>

<style scoped lang="scss">
  .input {
    width: 100%;
    height: 54px;
    border-radius: var(--base-input-border-radius);
    border: 1px solid var(--base-input-valid-border-bgc);
    box-sizing: border-box;
    padding: 0 20px;
    outline: none;
    font-size: 16px;
    line-height: 135%;
    margin: 10px 0;
    &::placeholder {
      color: var(--base-input-placeholder-color);
    }
  }
</style>
