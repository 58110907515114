<template>
  <div class="notifications-wrapper"></div>
</template>

<script>
export default {
  name: 'Notifications'
}
</script>

<style lang="scss" scoped>
  .notifications-wrapper {
    @include page-wrapper-with-header-and-footer;

    padding-top: 40px;
    box-sizing: border-box;
  }
</style>
