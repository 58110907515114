<template>
  <button class="button"
          :disabled="disabled"
          :class="[ color, { loading: isLoading } ]"
          @click.stop="onClick"
          @mouseover="onMouseOver"
          @mouseleave="onMouseLeave"
  >
    <slot v-if="!isLoading" name="icon" :item="{disabled, isHover}"></slot>
    <span v-if="!isLoading">{{ text }}</span>
    <base-spinner
      v-else
      :is-local="true"
      :is-loading="isLoading"
      :spinner-color="spinColor"
      height="6px"
      width="2px"
      radius="9px"
    />
  </button>
</template>

<script>
import BaseSpinner from './BaseSpinner.vue'
import VueI18n from 'vue-i18n'

const mapColors = {
  base: 'var(--base-button-spinner-color)',
  'base-danger': 'var(--base-danger-btn-spinner-color)',
  secondary: 'var(--secondary-btn-spinner-color)',
  'secondary-danger': 'var(--secondary-danger-btn-spinner-color)'
}

export default {
  components: { BaseSpinner },
  name: 'BaseButton',
  props: {
    text: [String, VueI18n.TranslateResult],
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default() {
        return 'base'
      },
      validator(value) {
        return ['base', 'base-danger', 'secondary', 'secondary-danger'].includes(value)
      }
    },
    spinnerColor: {
      type: String
    }
  },
  data() {
    return {
      isHover: false
    }
  },
  methods: {
    onClick() {
      if (!this.isLoading) {
        this.$emit('click')
      }
    },
    onMouseOver() {
      this.isHover = true
      this.$emit('mouseover')
    },
    onMouseLeave() {
      this.isHover = false
      this.$emit('mouseleave')
    }
  },
  computed: {
    spinColor() {
      if (this.spinnerColor) return this.spinnerColor
      return mapColors[this.color]
    }
  }
}
</script>

<style lang="scss"
       scoped>
.button {
  width: 100%;
  height: 100%;
  min-width: max-content;
  max-height: 40px;
  min-height: 40px;
  padding: .5rem 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  border: none;
  outline: none;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;

  &.base {
    background-color: var(--base-button-bgc);
    color: var(--base-button-color);

    svg {
      path {
        stroke: var(--base-button-color-disabled);
        fill: var(--base-button-color-disabled);
      }
    }

    &:not(.loading) {
      &:hover {
        background-color: var(--base-button-bgc-hover);
        color: var(--base-button-color-hover);

        svg {
          path {
            stroke: var(--base-button-color-hover);
            fill: var(--base-button-color-hover);
          }
        }
      }

      &:active:focus {
        background-color: var(--base-button-bgc-active);
        color: var(--base-button-color-active);
        box-shadow: 0 4px 8px rgba(0, 62, 29, 0.12);

        svg {
          path {
            stroke: var(--base-button-color-active);
            fill: var(--base-button-color-active);
          }
        }
      }

      &:disabled {
        background-color: var(--base-button-bgc-disabled);
        color: var(--base-button-color-disabled);

        svg {
          path {
            stroke: var(--base-button-color-disabled);
            fill: var(--base-button-color-disabled);
          }
        }
      }
    }

    &.loading {
      padding-top: 0;
    }
  }

  &.base-danger {
    background-color: var(--base-danger-btn-bgc);
    color: var(--base-danger-btn-color);

    svg {
      path {
        stroke: var(--base-danger-btn-color-disabled);
        fill: var(--base-danger-btn-color-disabled);
      }
    }

    &:not(.loading) {
      &:hover {
        background-color: var(--base-danger-btn-bgc-hover);
        color: var(--base-danger-btn-color-hover);

        svg {
          path {
            stroke: var(--base-danger-btn-color-hover);
            fill: var(--base-danger-btn-color-hover);
          }
        }
      }

      &:active:focus {
        background-color: var(--base-danger-btn-bgc-hover);
        box-shadow: 0 4px 8px rgba(0, 62, 29, 0.12);
        color: var(--base-danger-btn-color-hover);

        svg {
          path {
            stroke: var(--base-danger-btn-color-active);
            fill: var(--base-danger-btn-color-active);
          }
        }
      }

      &:disabled {
        background-color: var(--base-danger-btn-bgc-disabled);
        color: var(--base-danger-btn-color-disabled);

        svg {
          path {
            stroke: var(--base-danger-btn-color-disabled);
            fill: var(--base-danger-btn-color-disabled);
          }
        }
      }
    }

    &.loading {
      padding-top: 0;
    }
  }

  &.secondary {
    padding: 6px 14px;
    background-color: var(--secondary-btn-bgc);
    color: var(--secondary-btn-color);
    border: 2px solid var(--secondary-btn-border);

    svg {
      path {
        stroke: var(--secondary-btn-color-disabled);
        fill: var(--secondary-btn-color-disabled);
      }
    }

    &:not(.loading) {
      &:hover {
        background-color: var(--secondary-btn-bgc-hover);
        color: var(--secondary-btn-color-hover);
        border-color: var(--secondary-btn-border-hover);

        svg {
          path {
            stroke: var(--secondary-btn-color-hover);
            fill: var(--secondary-btn-color-hover);
          }
        }
      }

      &:active:focus {
        background-color: var(--secondary-btn-bgc-active);
        color: var(--secondary-btn-color-active);
        border-color: var(--secondary-btn-bgc-active);
        box-shadow: 0 4px 8px rgba(0, 62, 29, 0.12);

        svg {
          path {
            stroke: var(--secondary-btn-color-active);
            fill: var(--secondary-btn-color-active);
          }
        }
      }

      &:disabled {
        background-color: var(--secondary-btn-bgc-disabled);
        color: var(--secondary-btn-color-disabled);
        border-color: var(--secondary-btn-border-disabled);

        svg {
          path {
            stroke: var(--secondary-btn-color-disabled);
            fill: var(--secondary-btn-color-disabled);
          }
        }
      }
    }

    &.loading {
      padding-top: 0;
    }
  }

  &.secondary-danger {
    padding: 6px 14px;
    background-color: var(--secondary-danger-btn-bgc);
    color: var(--secondary-danger-btn-color);
    border: 2px solid var(--secondary-danger-btn-border);

    svg {
      path {
        stroke: var(--secondary-danger-btn-color);
        fill: var(--secondary-danger-btn-color);
      }
    }

    &:not(.loading) {
      &:hover {
        background-color: var(--secondary-danger-btn-bgc-hover);
        color: var(--secondary-danger-btn-color-hover);
        border-color: var(--secondary-danger-btn-border-hover);

        svg {
          path {
            stroke: var(--secondary-danger-btn-color-hover);
            fill: var(--secondary-danger-btn-color-hover);
          }
        }
      }

      &:active:focus {
        background-color: var(--secondary-danger-btn-bgc-active);
        color: var(--secondary-danger-btn-color-active);
        border-color: var(--secondary-danger-btn-bgc-active);
        box-shadow: 0 4px 8px rgba(0, 62, 29, 0.12);

        svg {
          path {
            stroke: var(--secondary-danger-btn-color-active);
            fill: var(--secondary-danger-btn-color-active);
          }
        }
      }

      &:disabled {
        background-color: var(--secondary-danger-btn-bgc-disabled);
        color: var(--secondary-danger-btn-color-disabled);
        border-color: var(--secondary-danger-btn-border-disabled);

        svg {
          path {
            stroke: var(--secondary-danger-btn-color-disabled);
            fill: var(--secondary-danger-btn-color-disabled);
          }
        }
      }
    }

    &.loading {
      padding-top: 0;
    }
  }

}
</style>
