import { Utils } from '@/helpers/utils'
import LoanService from '@/services/Loan'
import { LoanRequest, LoanRequestStatus, TradeStatus } from '@/models'
import { PagedState } from '@/models/paged-state.inteface'
import mapOfferFn = Utils.mapOfferFn;
import { borrowTradeRequestParams } from '@/constants/constants'
import { applyFilters, resetFilters } from '@/store/shared/actions'

type BorrowOfferFilters = {
  status: LoanRequestStatus
}

type BorrowOfferState = PagedState<{ offers: LoanRequest[], filters: BorrowOfferFilters }>

const state: BorrowOfferState = {
  offers: null,
  page: 1,
  page_size: 10,
  has_next: false,
  filters: {
    status: null
  }
}

const actions = {
  async get(context) {
    const { page, page_size, filters } = context.state
    const requestParams = filters.status ? { status: filters.status } : { status: [LoanRequestStatus.ACTIVE, LoanRequestStatus.DRAFT] }
    const data = await LoanService.getBorrowRequest({ page, page_size, only_my: 1, ...requestParams })
    context.commit('setTrades', page > 1 ? [...context.state.offers, ...data.results] : data.results)
    context.commit('setHasNext', Boolean(data.pagination.next))
  },
  setPage(context, page) {
    context.commit('setPage', page)
    context.dispatch('get')
  },
  resetPage(context) {
    context.commit('setPage', 1)
    context.dispatch('get')
  },
  applyFilters,
  resetFilters
}

const mutations = {
  setTrades(state: BorrowOfferState, payload: LoanRequest[]) {
    state.offers = payload
  },
  setHasNext(state: BorrowOfferState, payload: boolean) {
    state.has_next = payload
  },
  setPage(state: BorrowOfferState, page) {
    state.page = page
  },
  setFilters(state: BorrowOfferState, filters: BorrowOfferFilters) {
    state.filters = { ...state.filters, ...filters }
  },
  resetFilters(state: BorrowOfferState) {
    state.filters = {
      status: null
    }
  }
}

const getters = {
  offerList(state: BorrowOfferState): LoanRequest[] {
    return state.offers.map(mapOfferFn)
  },
  offerFilters(state: BorrowOfferState): BorrowOfferFilters {
    return state.filters
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
