<template>
    <div class="loader-wrapper" :class="isLocal ? 'local-wrapper': ''" v-if="isLoading">
      <Spinner
        :loading="isLoading"
        :color="spinnerColor"
        :width="isLocal ? '2px' : width"
        :height="isLocal ? '7px' : height"
        :radius="isLocal ? '12px' : radius"
        :margin="margin"
      />
    </div>
  </template>

<script>
import Spinner from 'vue-spinner/src/FadeLoader.vue'

export default {
  name: 'BaseSpinner',
  components: {
    Spinner
  },
  props: {
    spinnerColor: {
      type: String,
      default: function() {
        return 'var(--main-spinner-color)'
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isLocal: {
      type: Boolean,
      default: false
    },
    width: {
      type: String
    },
    height: {
      type: String
    },
    radius: {
      type: String
    },
    margin: {
      type: String
    }
  }
}
</script>

  <style scoped>
    .loader-wrapper {
      position: fixed;
      display: flex;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: var(--main-spinner-wrapper-bgc);
      z-index: 99999;
      align-items: center;
      justify-content: center;
      }
    .local-wrapper {
      position: inherit;
      width: inherit;
      background-color: var(--main-local-spinner-wrapper-bgc);
      display: flex;
      align-items: center;
      justify-content: center;
      height: inherit;
      z-index: 1;
    }
  </style>
