
import BaseSpinner from '../../components/base/BaseSpinner.vue'
import TradeService from '@/services/Trade'
import { Trade } from '@/models/trade.class'
import { Pagination, TradeStatus } from '@/models'
import BaseModal from '@/components/base/BaseModal.vue'
import { mapGetters } from 'vuex'
import { Roles } from '@/models/roles.enum'
import { TransactionService } from '@/services/Transaction'
import { TransactionType } from '@/models/transaction-type.enum'
import BaseTable from '@/components/base/BaseTable.vue'
import BaseBadge from '@/components/base/BaseBadge.vue'
import archiveTrade from '../../store/modules/archive-trade'

const requestColumns = [
  { name: 'created_at', localization: 'message.loanPage.items.date' },
  { name: 'amount', localization: 'message.loanPage.items.amount' },
  { name: 'status', localization: 'message.loanPage.items.status' }
]

export default {
  name: 'Loan',
  components: {
    BaseBadge,
    BaseTable,
    BaseModal,
    BaseSpinner
  },
  data() {
    return {
      isLoading: true,
      item: new Trade(),
      uuid: null,
      code: '',
      pagination: new Pagination(),
      transactions: [],
      lending: [],
      columns: [
        { name: 'transaction_date', localization: 'message.loanPage.items.date' },
        { name: 'amount', localization: 'message.loanPage.items.amount' },
        { name: 'due_main_depth', localization: 'message.loanPage.items.due_main_depth' },
        { name: 'due_interest_depth', localization: 'message.loanPage.items.due_interest_depth' },
        { name: 'penalty_amount', localization: 'message.loanPage.items.penalty_amount' },
        { name: 'status', localization: 'message.loanPage.items.status', class: 'left' }
      ]
    }
  },
  async created() {
    const id = this.$route.params.id

    try {
      this.item = await TradeService.getTrade(id)
      this.getTransactions()
      this.getLending()
    } catch (error) {
      this.back()
    }

    this.isLoading = false
  },
  methods: {
    back() {
      this.$router.push({ name: 'profile', query: { 'save-page': true } })
    },
    async sign(): Promise<void> {
      this.$router.push({
        name: 'contract',
        query: { trade_id: this.item.id }
      })
    },
    async confirm(): Promise<void> {
      try {
        await TradeService.signRequestCheckCode(this.item.id, this.code, this.uuid)
        this.uuid = null
        this.code = null
        this.item = await TradeService.getTrade(this.item.id)
      } catch (e) {

      }
    },
    async getTransactions(page = 1): Promise<void> {
      this.pagination.page = page
      try {
        let results = await TransactionService.getTransactions({ ...this.pagination, trade_id: this.item.id, type: [TransactionType.REPAYMENT] })
        results = results.map(item => ({
          ...item,
          transaction_date: new Date(item.transaction_date + 'Z').toLocaleString()
        }))
        this.transactions = page > 1 ? [...this.transactions, ...results] : results
      } catch (e) {

      }
    },
    async getLending(page = 1): Promise<void> {
      this.pagination.page = page
      try {
        let { results } = await TransactionService.getLending({ ...this.pagination, trade_id: this.item.id, type: [TransactionType.LENDING] })
        results = results.map(item => ({
          ...item,
          created_at: new Date(item.created_at + 'Z').toLocaleString()
        }))
        this.lending = page > 1 ? [...this.transactions, ...results] : results
      } catch (e) {

      }
    }
  },
  computed: {
    archiveTrade() {
      return archiveTrade
    },
    Roles() {
      return Roles
    },
    ...mapGetters(['profile']),
    TradeStatus() {
      return TradeStatus
    },
    requestColumns() {
      return requestColumns
    },
    getAddress() {
      const res = []
      const postfix = (key: string) => {
        switch (key) {
          case 'region': return ' ОБЛ.'
          default: return ''
        }
      }
      const prefix = (key: string) => {
        switch (key) {
          case 'house_number': return 'Д. '
          case 'apartment_number': return 'КВ. '
          case 'house_unit': return 'КОР. '
          default: return ''
        }
      }
      Object.entries(this.item?.borrower?.address || {}).forEach(([key, value]) => {
        if (value && key !== 'soato') {
          res.push(prefix(key) + value + postfix(key))
        }
      })
      return res.join(' ')
    }
  }
}
