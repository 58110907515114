import { ServiceDocuments } from '@/services/ServiceDocuments'
import { DocumentCategory, DocumentTypeEnum, ServiceDocument } from '@/models/api/service-document'

interface ServiceDocumentsState {
  documents: ServiceDocuments,
  currentDocType: DocumentTypeEnum,
  categories: DocumentCategory[],
  isDocsLoading: boolean,
  isCategoriesLoading: boolean
}

const state: ServiceDocumentsState = {
  documents: [],
  currentDocType: null,
  categories: null,
  isDocsLoading: false,
  isCategoriesLoading: false
}

const actions = {
  async get(context) {
    context.commit('setDocsIsLoading')
    try {
      const { currentDocType } = context.state
      const { results } = await ServiceDocuments.getServiceDocuments({ doc_type: currentDocType })
      context.commit('setDocuments', results)
    } catch (e) {
    } finally {
      context.commit('setDocsIsCompleteLoading')
    }
  },
  async getCategories(context, options: { isLocal: boolean }) {
    if (!options?.isLocal) {
      context.commit('setCategoriesIsLoading')
    }
    try {
      const categories = await ServiceDocuments.getCategories()
      context.commit('setCategories', categories)
    } catch (e) {
    } finally {
      context.commit('setCategoriesIsCompleteLoading')
    }
  }
}

const mutations = {
  setDocuments(state: ServiceDocumentsState, documents: ServiceDocument[]) {
    state.documents = documents
  },
  setCategories(state: ServiceDocumentsState, categories: DocumentCategory[]) {
    state.categories = categories
  },
  setDocType(state: ServiceDocumentsState, docType: DocumentTypeEnum) {
    state.currentDocType = docType
  },
  setDocsIsLoading(state: ServiceDocumentsState) {
    state.isDocsLoading = true
  },
  setDocsIsCompleteLoading(state: ServiceDocumentsState) {
    state.isDocsLoading = false
  },
  setCategoriesIsLoading(state: ServiceDocumentsState) {
    state.isCategoriesLoading = true
  },
  setCategoriesIsCompleteLoading(state: ServiceDocumentsState) {
    state.isCategoriesLoading = false
  }
}

const getters = {
  documents(state: ServiceDocumentsState) {
    return state.documents
  },
  categories(state: ServiceDocumentsState) {
    return state.categories && state.categories.map(category => ({
      ...category,
      id: category.code_name
    }))
  },
  isDocsLoading(state: ServiceDocumentsState): boolean {
    return state.isDocsLoading
  },
  isCategoriesLoading(state: ServiceDocumentsState): boolean {
    return state.isCategoriesLoading
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
