
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import MsiService from '@/services/Msi'
import { MsiInfoResponse } from '@/models'
import BaseSelect from '@/components/base/BaseSelect.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import AuthService from '@/services/Auth'
import BaseModal from '@/components/base/BaseModal.vue'
interface DataProps {
  verification_data: MsiInfoResponse
  verification_id: string
  phone: string
  otp: string
  showSuccess: boolean
}

export default defineComponent({
  name: 'MsiUpdateInfo',
  components: { BaseModal, BaseSelect, BaseButton },
  data(): DataProps {
    return {
      verification_data: null,
      verification_id: null,
      phone: null,
      otp: null,
      showSuccess: false
    }
  },
  created(): void {
    const { code, verification_id } = this.$route.query
    this.verification_id = verification_id
    this.getVerificationData(code)
  },
  methods: {
    async getVerificationData(code: string) {
      try {
        this.verification_data = await MsiService.getMsiProfileV2(code)
        if (this.verification_data.phones?.length === 1) {
          this.phone = this.verification_data.phones[0]
        }
      } catch (e) {

      }
    },
    async getOtpCode() {
      try {
        await AuthService.getOtpCode(this.phone)
        this.$toast.success(this.$t('message.signIn.otpToastMsg'))
      } catch (error) {
      }
    },
    async confirm() {
      try {
        await MsiService.verifyMsiInfo({ otp_code: this.otp, verification_id: this.verification_id, params: { phone: this.phone } })
        this.showSuccess = true
      } catch (e) {

      }
    },
    closeSuccess() {
      this.showSuccess = false
      this.$router.push({ name: 'profile' })
    }
  },
  computed: {
    ...mapGetters(['msiData'])
  }
})

