import { MsiResponse } from '@/models'

export default {
  computed: {
    isContactDataIncorrect() {
      return !(this.userData as MsiResponse).email ||
        !(this.userData as MsiResponse).codeword ||
        !(this.userData as MsiResponse).password ||
        !(this.userData as MsiResponse).phone ||
        !(this.userData as MsiResponse).otp
    }
  }
}
