import { ClaimStatus, LoanRequestStatus, TradeStatus, TransactionStatus, UserStatus } from '@/models'

export function BadgeMap(status: string, scope = '') {
  switch (scope + status) {
    case ClaimStatus.PROCESSED:
    case TradeStatus.PROCESSED:
    case 'user' + UserStatus.ACTIVE:
    case 'offer' + LoanRequestStatus.PROCESSED:
      return 'success'
    case ClaimStatus.CREATED:
    case TradeStatus.TRANSACTION_PENDING:
    case 'offer' + LoanRequestStatus.ACTIVE:
    case 'user' + UserStatus.NEW:
    case TradeStatus.NEED_SIGN:
      return 'info'
    case TransactionStatus.PENDING:
    case 'user' + UserStatus.DECLINED:
    case 'user' + UserStatus.PENDING:
    case 'offer' + LoanRequestStatus.DRAFT:
    case TradeStatus.ACTIVE:
      return 'warn'
    case TradeStatus.TRANSACTION_FAILED:
    case TradeStatus.MAX_OVERDUE:
    case TradeStatus.OVERDUE:
    case TransactionStatus.FAILED:
    case 'user' + UserStatus.BLOCKED:
    case 'offer' + LoanRequestStatus.CANCELED:
      return 'error'
  }
}
