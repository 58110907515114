
import { defineComponent } from 'vue'
import TradeService from '@/services/Trade'
import BaseCheckbox from '@/components/base/BaseCheckbox.vue'
import { mapGetters } from 'vuex'
import BaseModal from '@/components/base/BaseModal.vue'

export default defineComponent({
  name: 'Claim',
  computed: {
    ...mapGetters(['profile'])
  },
  components: { BaseModal, BaseCheckbox },
  data() {
    return {
      confirmClaimRequest: false,
      code: null,
      uuid: null,
      claim_id: null,
      legal_action_id: null,
      pdfSource: null,
      loading: false,
      showSuccess: false
    }
  },
  async created() {
    const { id } = this.$route.params
    if (id) {
      try {
        const blob = await TradeService.getClaimDocument(id)
        this.pdfSource = URL.createObjectURL(blob)
        this.isLoading = false
      } catch (e) {

      }
    }
  },
  methods: {
    async request() {
      try {
        const { id } = this.$route.params
        const { uuid } = await TradeService.claimSignRequest(id)
        this.$toast.success(this.$t('claim.request.code_was_sent'))
        this.uuid = uuid
        this.claim_id = id
      } catch (e) {

      }
    },
    async confirm() {
      this.loading = true
      try {
        await TradeService.claimSignCheckCode(this.claim_id, this.uuid, this.code)
        this.confirmClaimRequest = false
        this.showSuccess = true
      } catch (e) {
        this.loading = false
      }
    },
    handleSuccessClose() {
      this.clear()
      this.$router.push({ name: 'profile' })
    },
    clear() {
      this.showSuccess = false
      this.claim_id = null
      this.uuid = null
      this.code = null
    },
    loadClaim() {
      const { id } = this.$route.params
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = this.pdfSource
      a.target = '_blank'
      a.download = id + '.pdf'
      document.body.appendChild(a)
      a.click()
    }
  }
})
