<template>
    <div class="not-found-wrapper">
        <div class="not-found-container">
            <div class="not-found-block">
                <img class="not-found-img" src="../../public/custom/images/not-found-image.png" alt="Not Found image">
                <div class="notice">
                    <div class="notice-title">
                        {{ $t('message.notFound.title') }}
                    </div>
                    <div class="notice-text">
                        {{ $t('message.notFound.notice') }}
                    </div>
                    <div class="btn-block">
                        <base-button
                            :text="$t('message.notFound.btn')"
                            @click="$router.push({name: 'main'})"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseButton from '../components/base/BaseButton.vue'

export default {
  name: 'NotFound',
  components: {
    BaseButton
  }
}
</script>

<style lang="scss" scoped>
    .not-found-wrapper {
        @include base-page-wrapper;
        justify-content: center;
    }

    .not-found-container {
        @include base-sign-in-container;
        box-sizing: border-box;
        padding: 79px 10px;
        display: flex;
        justify-content: center;
    }

    .not-found-block {
        width: 100%;
        height: 800px;
        max-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: linear-gradient(180deg, #F5F5F5 0%, #E7E7E7 100%);
    }

    .title {
        width: 300px;
        height: 60px;
        margin-bottom: 30px;
        font-size: 22px;
        font-weight: 800;
        line-height: 24px;
        text-align: center;
    }

    .notice {
        max-width: 30%;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 26px;
        line-height: 35px;
        color: #000000;
    }

    .notice-title {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 50px;
        line-height: 61px;
    }

    .not-found-img {
        max-width: 30%;
        margin-right: 10%;
    }

    .btn-block {
        margin-top: 60px;
        width: 250px;
        height: 60px;
    }

    @media screen and (max-width: 850px) {
        .not-found-block {
            flex-direction: column;
        }

        .not-found-img {
            margin-right: 0;
            margin-bottom: 10px;
        }

        .notice {
            max-width: 95%;
            width: 95%;
            align-items: center;
        }

        .notice-text {
            text-align: center;
        }
    }

    @media screen and (max-width: 525px) {
        .not-found-img {
            max-width: 80%;
        }
    }
</style>
