import axios from './axios'
import { CategoryResponse } from '@/models'

export class MessagesService {
  static sendMessage(payload) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    return axios.post('/user/support-message/', payload, config)
  }

  static getCategory(): Promise<CategoryResponse[]> {
    return axios.get('/user/support-message/category/')
  }
}
