import { render, staticRenderFns } from "./ActiveDocuments.vue?vue&type=template&id=51e01320&scoped=true&"
import script from "./ActiveDocuments.vue?vue&type=script&lang=ts&"
export * from "./ActiveDocuments.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e01320",
  null
  
)

export default component.exports